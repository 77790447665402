import ApiService from "../api.service";
import { showToast } from "../../helper/toast.helper";

export const GET_DESIGN = "getMasterDesign";
export const GET_DESIGN_BY_DOCTYPE = "getMasterDesignByDocType";
export const DELETE_DESIGN = "deleteMasterDesign";
export const SAVE_DESIGN = "saveMasterDesign";
export const UPDATE_DESIGN = "updateMasterDesign";

export const SET_ERROR = "setError";
export const SET_DESIGN = "setMasterDesign";
export const SET_DELETE_DESIGN = "setDeleteMasterDesign";
export const SET_SAVE_DESIGN = "setSaveMasterDesign";
export const SET_UPDATE_DESIGN = "setUpdateMasterDesign";
export const SET_LOADING_DESIGN = "setLoadingMasterDesign";

const state = {
  designs: {},
  errors: {},
  loading: false
};

const actions = {
  [GET_DESIGN](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_DESIGN, true);

      ApiService.query("design", params)
        .then(({ data }) => {
          context.commit(SET_DESIGN, data);

          resolve(data);

          context.commit(SET_LOADING_DESIGN, false);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_DESIGN, false);
        });
    });
  },

  [GET_DESIGN_BY_DOCTYPE](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_DESIGN, true);

      // console.log(JSON.stringify(params));

      // console.log(JSON.stringify(params.params.docType))

      ApiService.query("design/"+params.params.docType+"/type", params)
        .then(({ data }) => {
          context.commit(SET_DESIGN, data);

          resolve(data);

          context.commit(SET_LOADING_DESIGN, false);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_DESIGN, false);
        });
    });
  },

  [DELETE_DESIGN](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_DESIGN, true);

      ApiService.setHeader();

      ApiService.delete(`design/${params.code}/delete`)
        .then(({ data }) => {
          context.commit(SET_DELETE_DESIGN, params.code);

          resolve(data);

          context.commit(SET_LOADING_DESIGN, false);

          showToast("Success", "Data Deleted Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_DESIGN, false);

          showToast("Failed", "Failed Delete Data", "danger");
        });
    });
  },

  [SAVE_DESIGN](context, body) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_DESIGN, true);

      ApiService.setHeader();

      ApiService.post("design", body)
        .then(({ data }) => {
          context.commit(SET_SAVE_DESIGN, data?.data);

          resolve(data);

          context.commit(SET_LOADING_DESIGN, false);

          showToast("Success", "Data Saved Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_DESIGN, false);

          showToast("Failed", "Failed Save Data", "danger");
        });
    });
  },

  [UPDATE_DESIGN](context, body) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_DESIGN, true);

      ApiService.setHeader();

      ApiService.put("design", body)
        .then(({ data }) => {
          context.commit(SET_UPDATE_DESIGN, data?.data);

          resolve(data);

          context.commit(SET_LOADING_DESIGN, false);

          showToast("Success", "Data Update Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_DESIGN, false);

          showToast("Failed", "Failed Update Data", "danger");
        });
    });
  }
};

const mutations = {
  [SET_DESIGN](state, designs) {
    state.designs = designs;
  },

  [SET_ERROR](state, error) {
    state.errors = error;
  },

  [SET_SAVE_DESIGN](state, design) {
    state.designs.data = [design, ...state.designs.data];
  },

  [SET_LOADING_DESIGN](state, loading) {
    state.loading = loading;
  },

  [SET_UPDATE_DESIGN](state, updateStation) {
    const index = state.designs?.data?.findIndex(
      design => design.code === updateStation.code
    );

    if (index >= 0) {
      Object.assign(state.designs?.data[index], updateStation);
    }
  },

  [SET_DELETE_DESIGN](state, code) {
    const index = state.designs?.data?.findIndex(
      design => design.code === code
    );

    if (index >= 0) {
      state.designs?.data?.splice(index, 1);
    }
  }
};

export default {
  state,
  actions,
  mutations
};
