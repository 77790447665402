import ApiService from "../api.service";
import { showToast } from "../../helper/toast.helper";

export const GET_OUTSTANDING = "getOutstanding";
export const DELETE_OUTSTANDING = "deleteOutstanding";
export const SAVE_OUTSTANDING = "saveOutstanding";
export const UPDATE_OUTSTANDING = "updateOutstanding";

export const SET_ERROR = "setError";
export const SET_OUTSTANDING = "setOutstanding";
export const SET_DELETE_OUTSTANDING = "setDeleteOutstanding";
export const SET_SAVE_OUTSTANDING = "setSaveOutstanding";
export const SET_UPDATE_OUTSTANDING = "setUpdateOutstanding";
export const SET_LOADING_OUTSTANDING = "setLoadingOutstanding";

const state = {
  outstanding: {},
  errors: {},
  loading: false
};

const actions = {
  [GET_OUTSTANDING](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_OUTSTANDING, true);

      ApiService.query("outstanding", params)
        .then(({ data }) => {
          context.commit(SET_OUTSTANDING, data);

          resolve(data);

          context.commit(SET_LOADING_OUTSTANDING, false);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_OUTSTANDING, false);
        });
    });
  },

  [DELETE_OUTSTANDING](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_OUTSTANDING, true);

      ApiService.setHeader();

      ApiService.delete(`outstanding/${params.code}/delete`)
        .then(({ data }) => {
          context.commit(SET_DELETE_OUTSTANDING, params.code);

          resolve(data);

          context.commit(SET_LOADING_OUTSTANDING, false);

          showToast("Success", "Data Deleted Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_OUTSTANDING, false);

          showToast("Failed", "Failed Delete Data", "danger");
        });
    });
  },

  [SAVE_OUTSTANDING](context, body) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_OUTSTANDING, true);

      ApiService.setHeader();

      ApiService.post("outstanding", body)
        .then(({ data }) => {
          context.commit(SET_SAVE_OUTSTANDING, data?.data);

          resolve(data);

          context.commit(SET_LOADING_OUTSTANDING, false);

          showToast("Success", "Data Saved Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_OUTSTANDING, false);

          showToast("Failed", "Failed Save Data", "danger");
        });
    });
  },

  [UPDATE_OUTSTANDING](context, body) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_OUTSTANDING, true);

      ApiService.setHeader();

      ApiService.put("outstanding", body)
        .then(({ data }) => {
          context.commit(SET_UPDATE_OUTSTANDING, data?.data);

          resolve(data);

          context.commit(SET_LOADING_OUTSTANDING, false);

          showToast("Success", "Data Updated Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_OUTSTANDING, false);

          showToast("Failed", "Failed Update Data", "danger");
        });
    });
  }
};

const mutations = {
  [SET_OUTSTANDING](state, outstandings) {
    state.outstanding = outstandings;
  },

  [SET_ERROR](state, error) {
    state.errors = error;
  },

  [SET_SAVE_OUTSTANDING](state, outstanding) {
    state.outstanding.data = [outstanding, ...state.outstanding.data];
  },

  [SET_LOADING_OUTSTANDING](state, loading) {
    state.loading = loading;
  },

  [SET_UPDATE_OUTSTANDING](state, updateStation) {
    const index = state.outstanding?.data?.findIndex(
      outstanding => outstanding.code === updateStation.code
    );

    if (index >= 0) {
      Object.assign(state.outstanding?.data[index], updateStation);
    }
  },

  [SET_DELETE_OUTSTANDING](state, code) {
    const index = state.outstanding?.data?.findIndex(
      outstanding => outstanding.code === code
    );

    if (index >= 0) {
      state.outstanding?.data?.splice(index, 1);
    }
  }
};

export default {
  state,
  actions,
  mutations
};
