import ApiService from "../api.service";

export const GET_SITE_DOCUMENT = "getSiteDocument";

export const SET_ERROR = "setError";
export const SET_SITE_DOCUMENT = "setSiteDocument";

const state = {
  sitedocument: [],
  errors: {}
};

const getters = {
  currentSiteDocument(state) {
    return state.sitedocument.data;
  }
};

const actions = {
  [GET_SITE_DOCUMENT](context, params) {
    return new Promise(resolve => {
      ApiService.query("site/document/type", params)
        .then(({ data }) => {
          context.commit(SET_SITE_DOCUMENT, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  }
};

const mutations = {
  [SET_SITE_DOCUMENT](state, scopes) {
    state.sitedocument = scopes;
  },

  [SET_ERROR](state, error) {
    state.errors = error;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
