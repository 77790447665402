import ApiService from "../api.service";
import { showToast } from "../../helper/toast.helper";

// action types
export const GET_STATUS = "getStatus";
export const GET_STATUS_OPTION = "getStatusOption";
export const DELETE_STATUS = "deleteStatus";
export const SAVE_STATUS = "saveStatus";
export const UPDATE_STATUS = "updateStatus";

// mutation types
export const SET_STATUS = "setStatus";
export const SET_STATUS_OPTION = "setStatusOption";
export const SET_LOADING_STATUS_OPTION = "setLoadingStatus";
export const SET_ERROR_STATUS_OPTION = "setErrorStatus";
export const SET_DELETE_STATUS = "setDeleteStatus";
export const SET_SAVE_STATUS = "setSaveStatus";
export const SET_UPDATE_STATUS = "setUpdateStatus";
export const SET_LOADING_STATUS = "setLoadingStatus";

export default {
  state: {
    loading: false,
    errors: {},
    status: {}
  },
  getters: {
    //
  },
  actions: {
    /**
     * Add breadcrumb
     * @param context
     * @param params
     */
    [GET_STATUS_OPTION](context, params) {
      return new Promise((resolve, reject) => {
        context.commit(SET_LOADING_STATUS_OPTION, true);

        ApiService.setHeader();

        ApiService.query(`status`, params)
          .then(({ data }) => {
            resolve(data);

            context.commit(SET_STATUS_OPTION, data);

            context.commit(SET_LOADING_STATUS_OPTION, false);
          })
          .catch(({ response }) => {
            reject(response);

            context.commit(SET_ERROR_STATUS_OPTION, response.data.errors);

            context.commit(SET_LOADING_STATUS_OPTION, false);
          });
      });
    },

    [GET_STATUS](context, params) {
      return new Promise((resolve, reject) => {
        context.commit(SET_LOADING_STATUS_OPTION, true);

        ApiService.setHeader();

        ApiService.query(`status`, params)
          .then(({ data }) => {
            resolve(data);

            context.commit(SET_STATUS_OPTION, data);

            context.commit(SET_LOADING_STATUS_OPTION, false);
          })
          .catch(({ response }) => {
            reject(response);

            context.commit(SET_ERROR_STATUS_OPTION, response.data.errors);

            context.commit(SET_LOADING_STATUS_OPTION, false);
          });
      });
    },

    [DELETE_STATUS](context, params) {
      return new Promise(resolve => {
        context.commit(SET_LOADING_STATUS, true);

        ApiService.setHeader();

        ApiService.delete(`status/${params.code}/delete`)
          .then(({ data }) => {
            context.commit(SET_DELETE_STATUS, params.code);

            resolve(data);

            context.commit(SET_LOADING_STATUS, false);

            showToast("Success", "Data Deleted Successfully");
          })
          .catch(({ response }) => {
            context.commit(SET_ERROR_STATUS_OPTION, response.data.errors);

            context.commit(SET_LOADING_STATUS, false);

            showToast("Failed", "Failed Delete Data", "danger");
          });
      });
    },

    [SAVE_STATUS](context, body) {
      return new Promise(resolve => {
        context.commit(SET_LOADING_STATUS, true);

        ApiService.setHeader();

        ApiService.post("status", body)
          .then(({ data }) => {
            context.commit(SET_SAVE_STATUS, data?.data);

            resolve(data);

            context.commit(SET_LOADING_STATUS, false);

            showToast("Success", "Data Saved Successfully");
          })
          .catch(({ response }) => {
            context.commit(SET_ERROR_STATUS_OPTION, response.data.errors);

            context.commit(SET_LOADING_STATUS, false);

            showToast("Failed", "Failed Save Data", "danger");
          });
      });
    },

    [UPDATE_STATUS](context, body) {
      return new Promise(resolve => {
        context.commit(SET_LOADING_STATUS, true);

        ApiService.setHeader();

        ApiService.put("status", body)
          .then(({ data }) => {
            context.commit(SET_UPDATE_STATUS, data?.data);

            resolve(data);

            context.commit(SET_LOADING_STATUS, false);

            showToast("Success", "Data Updated Successfully");
          })
          .catch(({ response }) => {
            context.commit(SET_ERROR_STATUS_OPTION, response.data.errors);

            context.commit(SET_LOADING_STATUS, false);

            showToast("Failed", "Failed Update Data", "danger");
          });
      });
    }
  },
  mutations: {
    [SET_STATUS_OPTION](state, data) {
      state.status = data;
    },

    [SET_STATUS](state, data) {
      state.status = data;
    },

    [SET_LOADING_STATUS_OPTION](state, loading) {
      state.loading = loading;
    },

    [SET_SAVE_STATUS](state, status) {
      state.status.data = [status, ...state.status.data];
    },

    [SET_LOADING_STATUS](state, loading) {
      state.loading = loading;
    },

    [SET_UPDATE_STATUS](state, updateStatus) {
      const index = state.status?.data?.findIndex(
        station => station.code === updateStatus.code
      );

      if (index >= 0) {
        Object.assign(state.status?.data[index], updateStatus);
      }
    },

    [SET_DELETE_STATUS](state, code) {
      const index = state.status?.data?.findIndex(
        station => station.code === code
      );

      if (index >= 0) {
        state.status?.data?.splice(index, 1);
      }
    }
  }
};
