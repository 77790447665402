import ApiService from "../api.service";
import { showToast } from "../../helper/toast.helper";

export const GET_SCOPES = "getMasterScope";
export const DELETE_SCOPE = "deleteScope";
export const SAVE_SCOPE = "saveScope";
export const UPDATE_SCOPE = "updateScope";

export const SET_ERROR = "setError";
export const SET_SCOPES = "setScope";
export const SET_DELETE_SCOPE = "setDeleteScope";
export const SET_SAVE_SCOPE = "setSaveScope";
export const SET_UPDATE_SCOPE = "setUpdateScope";
export const SET_LOADING_SCOPE = "setLoadingScope";

const state = {
  scopes: {},
  errors: {},
  loading: false
};

const getters = {
  currentScopes(state) {
    return state.scopes.data;
  }
};

const actions = {
  [GET_SCOPES](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_SCOPE, true);

      ApiService.query("scope", params)
        .then(({ data }) => {
          context.commit(SET_SCOPES, data);

          resolve(data);

          context.commit(SET_LOADING_SCOPE, false);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_SCOPE, false);
        });
    });
  },

  [DELETE_SCOPE](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_SCOPE, true);

      ApiService.setHeader();

      ApiService.delete(`scope/${params.code}/delete`)
        .then(({ data }) => {
          context.commit(SET_DELETE_SCOPE, params.code);

          resolve(data);

          context.commit(SET_LOADING_SCOPE, false);

          showToast("Success", "Data Deleted Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_SCOPE, false);

          showToast("Failed", "Failed Delete Data", "danger");
        });
    });
  },

  [SAVE_SCOPE](context, body) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_SCOPE, true);

      ApiService.setHeader();

      ApiService.post("scope", body)
        .then(({ data }) => {
          context.commit(SET_SAVE_SCOPE, data?.data);

          resolve(data);

          context.commit(SET_LOADING_SCOPE, false);

          showToast("Success", "Data Saved Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_SCOPE, false);

          showToast("Failed", "Failed Save Data", "danger");
        });
    });
  },

  [UPDATE_SCOPE](context, body) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_SCOPE, true);

      ApiService.setHeader();

      ApiService.put("scope", body)
        .then(({ data }) => {
          context.commit(SET_UPDATE_SCOPE, data?.data);

          resolve(data);

          context.commit(SET_LOADING_SCOPE, false);

          showToast("Success", "Data Updated Data");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_SCOPE, false);

          showToast("Failed", "Failed Update Data", "danger");
        });
    });
  }
};

const mutations = {
  [SET_SCOPES](state, scopes) {
    state.scopes = scopes;
  },

  [SET_ERROR](state, error) {
    state.errors = error;
  },

  [SET_SAVE_SCOPE](state, scope) {
    state.scopes.data = [scope, ...state.scopes.data];
  },

  [SET_LOADING_SCOPE](state, loading) {
    state.loading = loading;
  },

  [SET_UPDATE_SCOPE](state, updateStation) {
    const index = state.scopes?.data?.findIndex(
      scope => scope.code === updateStation.code
    );

    if (index >= 0) {
      Object.assign(state.scopes?.data[index], updateStation);
    }
  },

  [SET_DELETE_SCOPE](state, code) {
    const index = state.scopes?.data?.findIndex(scope => scope.code === code);

    if (index >= 0) {
      state.scopes?.data?.splice(index, 1);
    }
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
