import ApiService from "../api.service";
import { showToast } from "../../helper/toast.helper";

export const GET_MEETING = "getMeeting";
export const GET_MEETING_BY_ID = "getMeetingById";
export const DELETE_MEETING = "deleteMeeting";
export const SAVE_MEETING = "saveMeeting";
export const SAVE_MEETING_PARTICIPANT = "saveMeetingParticipant";
export const UPDATE_MEETING = "updateMeeting";
export const INVITE_PARTICIPANT = "inviteParticipant";

export const SET_ERROR = "setError";
export const SET_MEETING = "setMeeting";
export const SET_MEETING_BY_ID = "setMeetingById";
export const SET_DELETE_MEETING = "setDeleteMeeting";
export const SET_SAVE_MEETING = "setSaveMeeting";
export const SET_UPDATE_MEETING = "setUpdateMeeting";
export const SET_LOADING_MEETING = "setLoadingMeeting";

const state = {
  meetings: {},
  errors: {},
  meeting: null,
  loading: false
};

const actions = {
  [GET_MEETING](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_MEETING, true);

      ApiService.query("meeting", params)
        .then(({ data }) => {
          context.commit(SET_MEETING, data);

          resolve(data);

          context.commit(SET_LOADING_MEETING, false);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_MEETING, false);
        });
    });
  },

  [GET_MEETING_BY_ID](context, params) {
    context.commit(SET_MEETING_BY_ID, null);

    return new Promise(resolve => {
      context.commit(SET_LOADING_MEETING, true);

      ApiService.query(`meeting/${params.id}/detail`)
        .then(({ data }) => {
          context.commit(SET_MEETING_BY_ID, data);

          resolve(data);

          context.commit(SET_LOADING_MEETING, false);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_MEETING, false);
        });
    });
  },

  [DELETE_MEETING](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_MEETING, true);

      ApiService.setHeader();

      ApiService.delete(`meeting/${params.code}/delete`)
        .then(({ data }) => {
          context.commit(SET_DELETE_MEETING, params.code);

          resolve(data);

          context.commit(SET_LOADING_MEETING, false);

          showToast("Success", "Data Deleted Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_MEETING, false);

          showToast("Failed", "Failed Delete Data", "danger");
        });
    });
  },

  [SAVE_MEETING](context, body) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_MEETING, true);

      ApiService.setHeader();

      ApiService.post("meeting", body)
        .then(({ data }) => {
          context.commit(SET_SAVE_MEETING, data);

          resolve(data);

          context.commit(SET_LOADING_MEETING, false);

          showToast("Success", "Data Saved Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_MEETING, false);

          showToast("Failed", "Failed Saved Data", "danger");
        });
    });
  },

  [INVITE_PARTICIPANT](context, body) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_MEETING, true);

      ApiService.setHeader();

      ApiService.post("meeting/attendant/invite", body)
        .then(({ data }) => {
          resolve(data);

          context.commit(SET_LOADING_MEETING, false);

          showToast("Success", "Participant Invited Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_MEETING, false);

          showToast("Failed", "failed Invite Participant", "danger");
        });
    });
  },

  [SAVE_MEETING_PARTICIPANT](context, body) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_MEETING, true);

      ApiService.setHeader();

      ApiService.post("meeting/attendant", body)
        .then(({ data }) => {
          resolve(data);

          context.commit(SET_LOADING_MEETING, false);

          showToast("Success", "Participant Data Saved Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_MEETING, false);

          showToast("Failed", "Failed Save Data", "danger");
        });
    });
  },

  [UPDATE_MEETING](context, body) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_MEETING, true);

      ApiService.setHeader();

      ApiService.post("meeting", body)
        .then(({ data }) => {
          context.commit(SET_UPDATE_MEETING, data);

          resolve(data);

          context.commit(SET_LOADING_MEETING, false);

          showToast("Success", "Data Updated Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_MEETING, false);

          showToast("Failed", "Failed Update Data", "danger");
        });
    });
  }
};

const mutations = {
  [SET_MEETING](state, meetings) {
    state.meetings = meetings;
  },

  [SET_MEETING_BY_ID](state, meeting) {
    state.meeting = meeting;
  },

  [SET_ERROR](state, error) {
    state.errors = error;
  },

  [SET_SAVE_MEETING](state, meeting) {
    state.meetings.data = [meeting?.data, ...state.meetings.data];
  },

  [SET_LOADING_MEETING](state, loading) {
    state.loading = loading;
  },

  [SET_UPDATE_MEETING](state, updateStation) {
    const index = state.meetings?.data?.findIndex(
      meeting => meeting.code === updateStation?.data?.code
    );

    if (index >= 0) {
      Object.assign(state.meetings?.data[index], updateStation?.data);
    }
  },

  [SET_DELETE_MEETING](state, code) {
    const index = state.meetings?.data?.findIndex(
      meeting => meeting.code === code
    );

    if (index >= 0) {
      state.meetings?.data?.splice(index, 1);
    }
  }
};

export default {
  state,
  actions,
  mutations
};
