import ApiService from "../api.service";
import { showToast } from "../../helper/toast.helper";


export const CLEAN_STATE = "cleanState";
export const GET_LETTERING = "getLettering";
export const POST_LETTERING = "postLettering";
export const PUT_LETTERING = "putLettering";
export const POST_UPLOAD_ATTACHMENT_LETTER = "postUploadAttachLettering";
export const POST_DOWNLOAD_ATTACHMENT_LETTER = "postDownloadAttachLettering";
export const DELETE_LETTERING = "deleteLettering";

export const SET_ERROR = "setError";
export const SET_LOADING = "setLoading";
export const SET_LETTERING = "setLettering";
export const SET_RESULT_POST_LETTERING = "setResultPostLettering";
export const SET_RESULT_PUT_LETTERING = "setResultPutLettering";

const state = {
  lettering: {
    data: [],
    code: null,
    message: null
  },
  resultPostLettering: {
    data: [],
    code: null,
    message: null
  },
  resultPutLettering: {
    data: [],
    code: null,
    message: null
  },
  loading: false,
  errors: {}
};

const getters = {
  currentLettering(state) {
    return state.lettering?.data;
  }
};

const actions = {
  [GET_LETTERING](context, params) {
    return new Promise((resolve, reject) => {
      context.commit(SET_LOADING, true);
      ApiService.setHeader();
      ApiService.query("letter", params)
        .then(({ data }) => {
          resolve(data);
          context.commit(SET_LETTERING, data);
          context.commit(SET_LOADING, false);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_LOADING, false);
        });
    });
  },
  [POST_LETTERING](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post("letter", param)
        .then(({ data }) => {
          context.commit(SET_RESULT_POST_LETTERING, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  [POST_DOWNLOAD_ATTACHMENT_LETTER](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.postDownload("ftp/download", param)
        .then(({ data, headers }) => {
          context.commit(SET_RESULT_POST_LETTERING, data);
          resolve(data);
          console.log("blob", data);
          let blob = new Blob([data], { type: headers["content-type"] });
          let link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = param.file_url.split("/").pop();
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  [PUT_LETTERING](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.put("letter", param)
        .then(({ data }) => {
          context.commit(SET_RESULT_PUT_LETTERING, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  [DELETE_LETTERING](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING, true);
      ApiService.setHeader();
      ApiService.delete(`letter/delete`,params)
        .then(({ data }) => {
          context.commit(SET_LETTERING, params);
          resolve(data);
          context.commit(SET_LOADING, false);
          showToast("Success", "Data Deleted Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_LOADING, false);
          showToast("Failed", "Failed Delete Data", "danger");
        });
    });
  },

  [POST_UPLOAD_ATTACHMENT_LETTER](context, files) {
    return new Promise((resolve, reject) => {
      ApiService.setHeaderMultiPlatform();
      ApiService.post("letter/upload", files)
        .then(({ data }) => {
          context.commit(SET_RESULT_POST_LETTERING, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },

  [SET_LOADING](state, loading) {
    state.loading = loading;
  },

  [SET_LETTERING](state, data) {
    state.lettering = data;
  },

  [SET_RESULT_POST_LETTERING](state, data) {
    state.resultPostLettering = data;
  },

  [SET_RESULT_PUT_LETTERING](state, data) {
    state.resultPutLettering = data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
