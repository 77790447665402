import ApiService from "../api.service";
import { showToast } from "../../helper/toast.helper";

export const GET_OUTSTANDING_CATEGORY = "getOutstandingCategory";
export const DELETE_OUTSTANDING_CATEGORY = "deleteOutstandingCategory";
export const SAVE_OUTSTANDING_CATEGORY = "saveOutstandingCategory";
export const UPDATE_OUTSTANDING_CATEGORY = "updateOutstandingCategory";

export const SET_ERROR = "setError";
export const SET_OUTSTANDING_CATEGORY = "setOutstandingCategory";
export const SET_DELETE_OUTSTANDING_CATEGORY = "setDeleteOutstandingCategory";
export const SET_SAVE_OUTSTANDING_CATEGORY = "setSaveOutstandingCategory";
export const SET_UPDATE_OUTSTANDING_CATEGORY = "setUpdateOutstandingCategory";
export const SET_LOADING_OUTSTANDING_CATEGORY = "setLoadingOutstandingCategory";

const state = {
  outstandingCategories: {},
  errors: {},
  loading: false
};

const actions = {
  [GET_OUTSTANDING_CATEGORY](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_OUTSTANDING_CATEGORY, true);

      ApiService.query("outstanding/category", params)
        .then(({ data }) => {
          context.commit(SET_OUTSTANDING_CATEGORY, data);

          resolve(data);

          context.commit(SET_LOADING_OUTSTANDING_CATEGORY, false);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_OUTSTANDING_CATEGORY, false);
        });
    });
  },

  [DELETE_OUTSTANDING_CATEGORY](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_OUTSTANDING_CATEGORY, true);

      ApiService.setHeader();

      ApiService.delete(`outstanding/category/${params.code}/delete`)
        .then(({ data }) => {
          context.commit(SET_DELETE_OUTSTANDING_CATEGORY, params.code);

          resolve(data);

          context.commit(SET_LOADING_OUTSTANDING_CATEGORY, false);

          showToast("Success", "Data Deleted Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_OUTSTANDING_CATEGORY, false);

          showToast("Failed", "Failed Delete Data", "danger");
        });
    });
  },

  [SAVE_OUTSTANDING_CATEGORY](context, body) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_OUTSTANDING_CATEGORY, true);

      ApiService.setHeader();

      ApiService.post("outstanding/category", body)
        .then(({ data }) => {
          context.commit(SET_SAVE_OUTSTANDING_CATEGORY, data?.data);

          resolve(data);

          context.commit(SET_LOADING_OUTSTANDING_CATEGORY, false);

          showToast("Success", "Data Saved Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_OUTSTANDING_CATEGORY, false);

          showToast("Failed", "Failed Save Data", "danger");
        });
    });
  },

  [UPDATE_OUTSTANDING_CATEGORY](context, body) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_OUTSTANDING_CATEGORY, true);

      ApiService.setHeader();

      ApiService.put("outstanding/category", body)
        .then(({ data }) => {
          context.commit(SET_UPDATE_OUTSTANDING_CATEGORY, data?.data);

          resolve(data);

          context.commit(SET_LOADING_OUTSTANDING_CATEGORY, false);

          showToast("Success", "Data Updated Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_OUTSTANDING_CATEGORY, false);

          showToast("Failed", "Failed Update Data", "danger");
        });
    });
  }
};

const mutations = {
  [SET_OUTSTANDING_CATEGORY](state, outstandingCategories) {
    state.outstandingCategories = outstandingCategories;
  },

  [SET_ERROR](state, error) {
    state.errors = error;
  },

  [SET_SAVE_OUTSTANDING_CATEGORY](state, outstandingCategory) {
    state.outstandingCategories.data = [
      outstandingCategory,
      ...state.outstandingCategories.data
    ];
  },

  [SET_LOADING_OUTSTANDING_CATEGORY](state, loading) {
    state.loading = loading;
  },

  [SET_UPDATE_OUTSTANDING_CATEGORY](state, updateOutstandingCategory) {
    const index = state.outstandingCategories?.data?.findIndex(
      outstandingCategory =>
        outstandingCategory.code === updateOutstandingCategory.code
    );

    if (index >= 0) {
      Object.assign(
        state.outstandingCategories?.data[index],
        updateOutstandingCategory
      );
    }
  },

  [SET_DELETE_OUTSTANDING_CATEGORY](state, code) {
    const index = state.outstandingCategories?.data?.findIndex(
      outstandingCategory => outstandingCategory.code === code
    );

    if (index >= 0) {
      state.outstandingCategories?.data?.splice(index, 1);
    }
  }
};

export default {
  state,
  actions,
  mutations
};
