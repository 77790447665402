import ApiService from "../api.service";
import { showToast } from "../../helper/toast.helper";

export const GET_COMPANY = "getCompany";
export const GET_COMPANY_PROJECT = "getCompanyByProjectID";
export const DELETE_COMPANY = "deleteCompany";
export const SAVE_COMPANY = "saveCompany";
export const UPDATE_COMPANY = "updateCompany";

export const SET_ERROR = "setError";
export const SET_COMPANY = "setCompany";
export const SET_COMPANY_PROJECT = "setCompanyProject";
export const SET_DELETE_COMPANY = "setDeleteCompany";
export const SET_SAVE_COMPANY = "setSaveCompany";
export const SET_UPDATE_COMPANY = "setUpdateCompany";
export const SET_LOADING_COMPANY = "setLoadingCompany";

const state = {
  companies: {},
  companyProject: {},
  errors: {},
  loading: false
};

const getters = {
  currentCompanies(state) {
    return state.companies.data;
  },

  currentCompanyProject(state) {
    return state.companyProject?.data?.company;
  }
};

const actions = {
  [GET_COMPANY](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_COMPANY, true);

      ApiService.query("company", params)
        .then(({ data }) => {
          context.commit(SET_COMPANY, data);

          resolve(data);

          context.commit(SET_LOADING_COMPANY, false);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_COMPANY, false);
        });
    });
  },

  [DELETE_COMPANY](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_COMPANY, true);

      ApiService.setHeader();

      ApiService.delete(`company/${params.code}/delete`)
        .then(({ data }) => {
          context.commit(SET_DELETE_COMPANY, params.code);

          resolve(data);

          context.commit(SET_LOADING_COMPANY, false);

          showToast("Success", "Data Deleted Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_COMPANY, false);

          showToast("Failed", "Failed Delete Data", "danger");
        });
    });
  },

  [SAVE_COMPANY](context, body) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_COMPANY, true);

      ApiService.setHeader();

      ApiService.post("company", body)
        .then(({ data }) => {
          context.commit(SET_SAVE_COMPANY, data?.data);

          resolve(data);

          context.commit(SET_LOADING_COMPANY, false);

          showToast("Success", "Data Saved Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_COMPANY, false);

          showToast("Failed", "Failed Save Data", "danger");
        });
    });
  },

  [UPDATE_COMPANY](context, body) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_COMPANY, true);

      ApiService.setHeader();

      ApiService.put("company", body)
        .then(({ data }) => {
          context.commit(SET_UPDATE_COMPANY, data?.data);

          resolve(data);

          context.commit(SET_LOADING_COMPANY, false);

          showToast("Success", "Data Updated Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_COMPANY, false);

          showToast("Failed", "Failed Update Data", "danger");
        });
    });
  },

  [GET_COMPANY_PROJECT](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_COMPANY, true);
      const id = typeof params === "object" ? params?.project_id : params;
      ApiService.get("company", `${id}/project`)
        .then(({ data }) => {
          context.commit(SET_COMPANY_PROJECT, data);
          resolve(data);
          context.commit(SET_LOADING_COMPANY, false);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_LOADING_COMPANY, false);
        });
    });
  }
};

const mutations = {
  [SET_COMPANY](state, companies) {
    state.companies = companies;
  },

  [SET_ERROR](state, error) {
    state.errors = error;
  },

  [SET_SAVE_COMPANY](state, company) {
    state.companies.data = [company, ...state.companies.data];
  },

  [SET_LOADING_COMPANY](state, loading) {
    state.loading = loading;
  },

  [SET_UPDATE_COMPANY](state, updateStation) {
    const index = state.companies?.data?.findIndex(
      company => company.code === updateStation.code
    );

    if (index >= 0) {
      Object.assign(state.companies?.data[index], updateStation);
    }
  },

  [SET_DELETE_COMPANY](state, code) {
    const index = state.companies?.data?.findIndex(
      company => company.code === code
    );

    if (index >= 0) {
      state.companies?.data?.splice(index, 1);
    }
  },

  [SET_COMPANY_PROJECT](state, data) {
    state.companyProject = data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
