import ApiService from "../api.service";
import { showToast } from "../../helper/toast.helper";

export const GET_DISTRIBUTION_LOGISTIC = "getDistributionLogistic";

export const SET_ERROR = "setError";
export const SET_DISTRIBUTION_LOGISTIC = "setDistributionLogistic";
export const SET_LOADING_DISTRIBUTION_LOGISTIC =
  "setLoadingDistributionLogistic";

const state = {
  distributionLogistics: {},
  errors: {},
  loading: false
};

const actions = {
  [GET_DISTRIBUTION_LOGISTIC](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_DISTRIBUTION_LOGISTIC, true);

      ApiService.query(
        `report/distribution/logistic/${params?.code}/project`,
        params
      )
        .then(response => {
          context.commit(SET_DISTRIBUTION_LOGISTIC, response?.data);

          resolve(response?.data);

          context.commit(SET_LOADING_DISTRIBUTION_LOGISTIC, false);

          showToast("Success", "Data Loaded Successfully");
        })
        .catch(response => {
          context.commit(SET_ERROR, response?.data?.errors);

          context.commit(SET_LOADING_DISTRIBUTION_LOGISTIC, false);

          showToast("Failed", "Failed Load Data", "danger");
        });
    });
  }
};

const mutations = {
  [SET_DISTRIBUTION_LOGISTIC](state, distributionLogistics) {
    state.distributionLogistics = distributionLogistics;
  },

  [SET_LOADING_DISTRIBUTION_LOGISTIC](state, loading) {
    state.loading = loading;
  }
};

export default {
  state,
  actions,
  mutations
};
