import ApiService from "../api.service";
import { showToast } from "../../helper/toast.helper";

export const GET_TRANSMITTAL = "getTransmittal";
export const SET_TRANSMITTAL = "setTransmittal";

export const GET_TRANSMITTAL_DOC = "getTransmittalDoc";
export const SET_TRANSMITTAL_DOC = "setTransmittalDoc";

export const GET_TRANSMITTAL_DRW = "getTransmittalDrw";
export const SET_TRANSMITTAL_DRW = "setTransmittalDrw";

export const GET_TRANSMITTAL_FOR = "getTransmittalFor";
export const SET_TRANSMITTAL_FOR = "setTransmittalFor";

export const GET_TRANSMITTAL_DETAIL = "getTransmittalDetail";
export const SET_TRANSMITTAL_DETAIL = "setTransmittalDetail";

export const GET_TRANSMITTAL_MONITORING_SHOP_DRAWING =
  "getTransmittalMonitoringShopDrawing";
export const SET_TRANSMITTAL_MONITORING_SHOP_DRAWING =
  "setTransmittalMonitoringShopDrawing";

export const POST_TRANSMITTAL_DRAFT = "postTransmittalDraft";
export const POST_TRANSMITTAL = "postTransmittal";
export const SET_TRANSMITTAL_RESULT = "setTransmittalResult";
export const POST_DOWNLOAD_ATTACHMENT_TRANSMITTAL =
  "postDownloadAttachTransmittal";

export const SET_LOADING_TRANSMITTAL = "setLoadingTransmittal";
export const SET_ERROR = "setError";

const state = {
  transmittalList: [],
  transmittalDoc: {
    data: [],
    code: null,
    message: null,
  },
  transmittalDrw: {
    data: [],
    code: null,
    message: null,
  },
  transmittalFor: {
    data: [],
    code: null,
    message: null,
  },
  transmittalDetail: {
    data: {},
    code: null,
    message: null,
  },
  transmittalMonitoringShopDrawing: {
    data: [],
    code: null,
    message: null,
  },
  loading: false,
  errors: {},
  resultPost: {},
};

const getters = {
  getterTransmittalMonitoringShopDrawing(state) {
    return state.transmittalMonitoringShopDrawing?.data;
  },

  getterTransmittal(state) {
    return state.transmittalList;
  },

  getterTransmittalDetail(state) {
    return state.transmittalDetail.data;
  },

  getterTransmittalDoc(state) {
    return state.transmittalDoc?.data?.dld;
  },

  getterTransmittalDrw(state) {
    return state.transmittalDrw?.data?.dld;
  },

  getterTransmittalFor(state) {
    return state.transmittalFor?.data?.dld;
  },

  loadingTransmittal(state) {
    return state.loading;
  },
};

const actions = {
  async [GET_TRANSMITTAL](context, param) {
    await ApiService.query("project/transmittal", param).then((res) => {
      context.commit(SET_TRANSMITTAL, res.data.data);
    });
  },
  [GET_TRANSMITTAL_MONITORING_SHOP_DRAWING](context, params) {
    return new Promise((resolve, reject) => {
      context.commit(SET_LOADING_TRANSMITTAL, true);
      ApiService.setHeader();
      ApiService.get("project", `${params}/monitoring/shop/drawing/eksternal`)
        .then(({ data }) => {
          resolve(data);
          context.commit(SET_TRANSMITTAL_MONITORING_SHOP_DRAWING, data);
          context.commit(SET_LOADING_TRANSMITTAL, false);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_LOADING_TRANSMITTAL, false);
        });
    });
  },
  [GET_TRANSMITTAL_DOC](context, params) {
    return new Promise((resolve, reject) => {
      context.commit(SET_LOADING_TRANSMITTAL, true);
      ApiService.setHeader();
      ApiService.query(`project/${params.project_id}/DOC/dld`, params)
        .then(({ data }) => {
          resolve(data);
          context.commit(SET_TRANSMITTAL_DOC, data);
          context.commit(SET_LOADING_TRANSMITTAL, false);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_LOADING_TRANSMITTAL, false);
        });
    });
  },

  [GET_TRANSMITTAL_FOR](context, params) {
    return new Promise((resolve, reject) => {
      context.commit(SET_LOADING_TRANSMITTAL, true);
      ApiService.setHeader();
      ApiService.query(`project/${params.project_id}/FOR/dld`, params)
        .then(({ data }) => {
          resolve(data);
          context.commit(SET_TRANSMITTAL_FOR, data);
          context.commit(SET_LOADING_TRANSMITTAL, false);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_LOADING_TRANSMITTAL, false);
        });
    });
  },

  [GET_TRANSMITTAL_DRW](context, params) {
    return new Promise((resolve, reject) => {
      context.commit(SET_LOADING_TRANSMITTAL, true);
      ApiService.setHeader();
      ApiService.query(`project/${params.project_id}/DRW/dld`, params)
        .then(({ data }) => {
          resolve(data);
          context.commit(SET_TRANSMITTAL_DRW, data);
          context.commit(SET_LOADING_TRANSMITTAL, false);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_LOADING_TRANSMITTAL, false);
        });
    });
  },

  [POST_TRANSMITTAL_DRAFT](context, param) {
    return new Promise((resolve) => {
      ApiService.setHeader();
      context.commit(SET_LOADING_TRANSMITTAL, true);
      ApiService.post("project/transmittal/draft", param)
        .then(({ data }) => {
          context.commit(SET_TRANSMITTAL_RESULT, data);
          resolve(data);
          context.commit(SET_LOADING_TRANSMITTAL, false);
          showToast("Success", "Data Saved As Draft Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_LOADING_TRANSMITTAL, false);
          showToast("Failed", "Failed Save Data", "danger");
        });
    });
  },

  [POST_TRANSMITTAL](context, param) {
    return new Promise((resolve) => {
      ApiService.setHeader();
      context.commit(SET_LOADING_TRANSMITTAL, true);
      ApiService.post("project/transmittal/save", param)
        .then(({ data }) => {
          context.commit(SET_TRANSMITTAL_RESULT, data);
          resolve(data);
          context.commit(SET_LOADING_TRANSMITTAL, false);
          showToast("Success", "Data Saved Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_LOADING_TRANSMITTAL, false);
          showToast("Failed", "Failed Save Data", "danger");
        });
    });
  },

  [GET_TRANSMITTAL_DETAIL](context, params) {
    return new Promise((resolve, reject) => {
      context.commit(SET_LOADING_TRANSMITTAL, true);
      ApiService.setHeader();
      ApiService.get(`project/transmittal`, `${params}/detail`)
        .then(({ data }) => {
          resolve(data);
          context.commit(SET_TRANSMITTAL_DETAIL, data);
          context.commit(SET_LOADING_TRANSMITTAL, false);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_LOADING_TRANSMITTAL, false);
        });
    });
  },

  [POST_DOWNLOAD_ATTACHMENT_TRANSMITTAL](context, param) {
    return new Promise((resolve, reject) => {
      context.commit(SET_LOADING_TRANSMITTAL, true);
      ApiService.setHeader();
      ApiService.postDownload("ftp/download", param)
        .then(({ data, headers }) => {
          context.commit(SET_TRANSMITTAL_RESULT, data);
          resolve(data);
          let blob = new Blob([data], { type: headers["content-type"] });
          let link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = param.file_url.split("/").pop();
          link.click();
          URL.revokeObjectURL(link.href);
          context.commit(SET_LOADING_TRANSMITTAL, false);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_LOADING_TRANSMITTAL, false);
        });
    });
  },
};

const mutations = {
  [SET_TRANSMITTAL_MONITORING_SHOP_DRAWING](state, data) {
    state.transmittalMonitoringShopDrawing = data;
  },

  [SET_TRANSMITTAL](state, data) {
    state.transmittalList = data;
  },

  [SET_ERROR](state, error) {
    state.errors = error;
  },

  [SET_TRANSMITTAL_DOC](state, data) {
    state.transmittalDoc = data;
  },

  [SET_TRANSMITTAL_FOR](state, data) {
    state.transmittalFor = data;
  },

  [SET_TRANSMITTAL_DRW](state, data) {
    state.transmittalDrw = data;
  },

  [SET_TRANSMITTAL_DETAIL](state, data) {
    state.transmittalDetail = data;
  },

  [SET_LOADING_TRANSMITTAL](state, data) {
    state.loading = data;
  },

  [SET_TRANSMITTAL_RESULT](state, data) {
    state.resultPost = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
