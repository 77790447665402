import ApiService from "../api.service";
import { showToast } from "../../helper/toast.helper";

export const GET_SCOPE_CATEGORY = "getScopeCategory";
export const DELETE_SCOPE_CATEGORY = "deleteScopeCategory";
export const SAVE_SCOPE_CATEGORY = "saveScopeCategory";
export const UPDATE_SCOPE_CATEGORY = "updateScopeCategory";

export const SET_ERROR = "setError";
export const SET_SCOPE_CATEGORY = "setScopeCategory";
export const SET_DELETE_SCOPE_CATEGORY = "setDeleteScopeCategory";
export const SET_SAVE_SCOPE_CATEGORY = "setSaveScopeCategory";
export const SET_UPDATE_SCOPE_CATEGORY = "setUpdateScopeCategory";
export const SET_LOADING_SCOPE_CATEGORY = "setLoadingScopeCategory";

const state = {
  scopeCategories: {},
  errors: {},
  loading: false
};

const actions = {
  [GET_SCOPE_CATEGORY](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_SCOPE_CATEGORY, true);

      ApiService.query("scope/category", params)
        .then(({ data }) => {
          context.commit(SET_SCOPE_CATEGORY, data);

          resolve(data);

          context.commit(SET_LOADING_SCOPE_CATEGORY, false);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_SCOPE_CATEGORY, false);
        });
    });
  },

  [DELETE_SCOPE_CATEGORY](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_SCOPE_CATEGORY, true);

      ApiService.setHeader();

      ApiService.delete(`scope/category/${params.code}/delete`)
        .then(({ data }) => {
          context.commit(SET_DELETE_SCOPE_CATEGORY, params.code);

          resolve(data);

          context.commit(SET_LOADING_SCOPE_CATEGORY, false);

          showToast("Success", "Data Deleted Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_SCOPE_CATEGORY, false);

          showToast("Failed", "Failed Delete Data", "danger");
        });
    });
  },

  [SAVE_SCOPE_CATEGORY](context, body) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_SCOPE_CATEGORY, true);

      ApiService.setHeader();

      ApiService.post("scope/category", body)
        .then(({ data }) => {
          context.commit(SET_SAVE_SCOPE_CATEGORY, data?.data);

          resolve(data);

          context.commit(SET_LOADING_SCOPE_CATEGORY, false);

          showToast("Success", "Data Saved Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_SCOPE_CATEGORY, false);

          showToast("Failed", "Failed Save Data", "danger");
        });
    });
  },

  [UPDATE_SCOPE_CATEGORY](context, body) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_SCOPE_CATEGORY, true);

      ApiService.setHeader();

      ApiService.put("scope/category", body)
        .then(({ data }) => {
          context.commit(SET_UPDATE_SCOPE_CATEGORY, data?.data);

          resolve(data);

          context.commit(SET_LOADING_SCOPE_CATEGORY, false);

          showToast("Success", "Data Updated Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_SCOPE_CATEGORY, false);

          showToast("Failed", "Failed Update Data", "danger");
        });
    });
  }
};

const mutations = {
  [SET_SCOPE_CATEGORY](state, scopeCategories) {
    state.scopeCategories = scopeCategories;
  },

  [SET_ERROR](state, error) {
    state.errors = error;
  },

  [SET_SAVE_SCOPE_CATEGORY](state, scopeCategory) {
    state.scopeCategories.data = [scopeCategory, ...state.scopeCategories.data];
  },

  [SET_LOADING_SCOPE_CATEGORY](state, loading) {
    state.loading = loading;
  },

  [SET_UPDATE_SCOPE_CATEGORY](state, updateStation) {
    const index = state.scopeCategories?.data?.findIndex(
      scopeCategory => scopeCategory.code === updateStation.code
    );

    if (index >= 0) {
      Object.assign(state.scopeCategories?.data[index], updateStation);
    }
  },

  [SET_DELETE_SCOPE_CATEGORY](state, code) {
    const index = state.scopeCategories?.data?.findIndex(
      scopeCategory => scopeCategory.code === code
    );

    if (index >= 0) {
      state.scopeCategories?.data?.splice(index, 1);
    }
  }
};

export default {
  state,
  actions,
  mutations
};
