import ApiService from "../api.service";
import { showToast } from "../../helper/toast.helper";

export const GET_CATEGORY = "getCategory";
export const DELETE_CATEGORY = "deleteCategory";
export const SAVE_CATEGORY = "saveCategory";
export const UPDATE_CATEGORY = "updateCategory";

export const SET_ERROR = "setError";
export const SET_CATEGORY = "setCategory";
export const SET_DELETE_CATEGORY = "setDeleteCategory";
export const SET_SAVE_CATEGORY = "setSaveCategory";
export const SET_UPDATE_CATEGORY = "setUpdateCategory";
export const SET_LOADING_CATEGORY = "setLoadingCategory";

const state = {
    categories: {},
    errors: {},
    loading: false
};

const getters = {
    currentCategories(state) {
      return state.categories.data;
    },

};
  

const actions = {
    [GET_CATEGORY](context, params) {
        return new Promise(resolve => {
            context.commit(SET_LOADING_CATEGORY, true);

            ApiService.query("category", params)
                .then(({ data }) => {
                    context.commit(SET_CATEGORY, data);

                    resolve(data);

                    context.commit(SET_LOADING_CATEGORY, false);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.errors);

                    context.commit(SET_LOADING_CATEGORY, false);
                });
        });
    },

    [DELETE_CATEGORY](context, params) {
        return new Promise(resolve => {
            context.commit(SET_LOADING_CATEGORY, true);

            ApiService.setHeader();

            ApiService.delete(`category/${params.code}/delete`)
                .then(({ data }) => {
                    context.commit(SET_DELETE_CATEGORY, params.code);

                    resolve(data);

                    context.commit(SET_LOADING_CATEGORY, false);

                    showToast("Success", "Data Deleted Successfully");
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.errors);

                    context.commit(SET_LOADING_CATEGORY, false);

                    showToast("Failed", "Failed Delete Data", "danger");
                });
        });
    },

    [SAVE_CATEGORY](context, body) {
        return new Promise(resolve => {
            context.commit(SET_LOADING_CATEGORY, true);

            ApiService.setHeader();

            ApiService.post("category", body)
                .then(({ data }) => {
                    context.commit(SET_SAVE_CATEGORY, data?.data);

                    resolve(data);

                    context.commit(SET_LOADING_CATEGORY, false);

                    showToast("Success", "Data Saved Successfully");
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.errors);

                    context.commit(SET_LOADING_CATEGORY, false);

                    showToast("Failed", "Failed Saved Data", "danger");
                });
        });
    },

    [UPDATE_CATEGORY](context, body) {
        return new Promise(resolve => {
            context.commit(SET_LOADING_CATEGORY, true);

            ApiService.setHeader();

            ApiService.put("category", body)
                .then(({ data }) => {
                    context.commit(SET_UPDATE_CATEGORY, data?.data);

                    resolve(data);

                    context.commit(SET_LOADING_CATEGORY, false);

                    showToast("Success", "Data Updated Successfully");
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.errors);

                    context.commit(SET_LOADING_CATEGORY, false);

                    showToast("Failed", "Failed Update Data", "danger");
                });
        });
    },
};

const mutations = {
    [SET_CATEGORY](state, categories) {
        state.categories = categories;
    },

    [SET_ERROR](state, error) {
        state.errors = error;
    },

    [SET_SAVE_CATEGORY](state, category) {
        state.categories.data = [category, ...state.categories.data];
    },

    [SET_LOADING_CATEGORY](state, loading) {
        state.loading = loading;
    },

    [SET_UPDATE_CATEGORY](state, updateStation) {
        const index = state.categories?.data?.findIndex(
            category => category.code === updateStation.code
        );

        if (index >= 0) {
            Object.assign(state.categories?.data[index], updateStation);
        }
    },

    [SET_DELETE_CATEGORY](state, code) {
        const index = state.categories?.data?.findIndex(
            category => category.code === code
        );

        if (index >= 0) {
            state.categories?.data?.splice(index, 1);
        }
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
