import ApiService from "../api.service";
import { showToast } from "../../helper/toast.helper";

export const GET_REPORT_TRANSMITTAL = "getReportTransmittal";
export const GET_REPORT_TRANSMITTAL_BY_ID = "getReportTransmittalDetail";

export const SET_ERROR = "setError";
export const SET_REPORT_TRANSMITTAL = "setReportTransmittal";
export const SET_LOADING_REPORT_TRANSMITTAL = "setLoadingReportTransmittal";
export const SET_REPORT_TRANSMITTAL_DETAIL = "setReportTransmittalDetail";

const state = {
  reportTransmittal: {},
  reportTransmittalDetail: {
    code: null,
    message: null,
    data: null
  },
  errors: {},
  loading: false
};

const getters = {
  currentReportTransmittal(state) {
    return state.reportTransmittal?.data;
  },
  currentLoadingTransmittalReport(state) {
    return state.loading;
  },
  currentReportTransmittalDetail(state) {
    return state.reportTransmittalDetail?.data;
  }
};

const actions = {
  [GET_REPORT_TRANSMITTAL](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_REPORT_TRANSMITTAL, true);
      ApiService.query(`project/transmittal`, params)
        .then(({ data }) => {
          context.commit(SET_REPORT_TRANSMITTAL, data);
          resolve(data);
          context.commit(SET_LOADING_REPORT_TRANSMITTAL, false);
          showToast("Success", "Data Loaded Successfully");
        })
        .catch(response => {
          context.commit(SET_ERROR, response?.data?.errors);
          context.commit(SET_LOADING_REPORT_TRANSMITTAL, false);
          showToast("Failed", "Failed Load Data", "danger");
        });
    });
  },
  [GET_REPORT_TRANSMITTAL_BY_ID](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_REPORT_TRANSMITTAL, true);
      ApiService.setHeader();
      ApiService.get("report/transmittal", `${params}/detail`)
        .then(({ data }) => {
          context.commit(SET_REPORT_TRANSMITTAL_DETAIL, data);
          context.commit(SET_LOADING_REPORT_TRANSMITTAL, false);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_LOADING_REPORT_TRANSMITTAL, false);
        });
    });
  }
};

const mutations = {
  [SET_REPORT_TRANSMITTAL](state, transmittal) {
    state.reportTransmittal = transmittal;
  },

  [SET_LOADING_REPORT_TRANSMITTAL](state, loading) {
    state.loading = loading;
  },
  [SET_REPORT_TRANSMITTAL_DETAIL](state, transmittal) {
    state.reportTransmittalDetail = transmittal;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
