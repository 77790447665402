import ApiService from "../api.service";
import { showToast } from "../../helper/toast.helper";

export const CLEAN_STATE = "cleanState";
export const GET_ROLES = "getRoles";
export const GET_ROLE_DETAIL = "getRoleDetail";
export const POST_ROLE = "postRole";
export const PUT_ROLE = "putRole";
export const DELETE_ROLE = "deleteRole";

export const SET_ERROR = "setError";
export const SET_LOADING = "setLoading";
export const SET_ROLES = "setRoles";
export const SET_POST_ROLE = "setPostRole";
export const SET_PUT_ROLE = "setPutRole";
export const SET_DELETE_ROLE = "setDeleteRole";
export const SET_ROLE_DETAIL = "setRoleDetail";

const state = {
  roles: {
    data: [],
    code: null,
    message: null
  },
  rolesDetail: {
    data: {},
    code: null,
    message: null
  },
  loading: false,
  errors: {}
};

const getters = {
  currentRoles(state) {
    return state.roles?.data;
  },

  currentRoleLoading(state) {
    return state.loading;
  },

  currentRoleDetail(state) {
    return state.rolesDetail?.data?.menu;
  }
};

const actions = {
  [GET_ROLE_DETAIL](context, params) {
    return new Promise((resolve, reject) => {
      context.commit(SET_LOADING, true);
      ApiService.setHeader();
      ApiService.get("role", `${params}/detail`)
        .then(({ data }) => {
          resolve(data);
          context.commit(SET_ROLE_DETAIL, data);
          context.commit(SET_LOADING, false);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_LOADING, false);
        });
    });
  },

  [GET_ROLES](context, params) {
    return new Promise((resolve, reject) => {
      context.commit(SET_LOADING, true);
      ApiService.setHeader();
      ApiService.query("role", params)
        .then(({ data }) => {
          resolve(data);
          context.commit(SET_ROLES, data);
          context.commit(SET_LOADING, false);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_LOADING, false);
        });
    });
  },

  [POST_ROLE](context, body) {
    return new Promise(resolve => {
      context.commit(SET_LOADING, true);
      ApiService.setHeader();
      ApiService.post("role", body)
        .then(({ data }) => {
          context.commit(SET_POST_ROLE, data?.data);
          resolve(data);
          context.commit(SET_LOADING, false);
          showToast("Success", "Data Saved Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_LOADING, false);
          showToast("Failed", "Failed Save Data", "danger");
        });
    });
  },

  [PUT_ROLE](context, body) {
    return new Promise(resolve => {
      context.commit(SET_LOADING, true);
      ApiService.setHeader();
      ApiService.put("role", body)
        .then(({ data }) => {
          context.commit(SET_PUT_ROLE, data?.data);
          resolve(data);
          context.commit(SET_LOADING, false);
          showToast("Success", "Data Updated Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_LOADING, false);
          showToast("Failed", "Failed Update Data", "danger");
        });
    });
  },

  [DELETE_ROLE](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING, true);
      ApiService.setHeader();
      ApiService.delete(`role/${params.id}/delete`)
        .then(({ data }) => {
          context.commit(SET_DELETE_ROLE, params.id);
          resolve(data);
          context.commit(SET_LOADING, false);
          showToast("Success", "Data Deleted Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_LOADING, false);
          showToast("Failed", "Failed Delete Data", "danger");
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },

  [SET_LOADING](state, loading) {
    state.loading = loading;
  },

  [SET_ROLES](state, data) {
    state.roles = data;
  },

  [SET_POST_ROLE](state, data) {
    state.roles.data = [data, ...state.roles.data];
  },

  [SET_PUT_ROLE](state, updateData) {
    const index = state.roles?.data?.findIndex(
      role => role.id === updateData.id
    );

    if (index >= 0) {
      Object.assign(state.roles?.data[index], updateData);
    }
  },

  [SET_DELETE_ROLE](state, id) {
    const index = state.roles?.data?.findIndex(role => role.id === id);

    if (index >= 0) {
      state.roles?.data?.splice(index, 1);
    }
  },

  [SET_ROLE_DETAIL](state, data) {
    state.rolesDetail = data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
