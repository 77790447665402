import ApiService from "../api.service";
import { showToast } from "../../helper/toast.helper";

export const GET_STATION = "getStation";
export const DELETE_STATION = "deleteStation";
export const SAVE_STATION = "saveStation";
export const UPDATE_STATION = "updateStation";
export const GET_STATION_BY_PROJECT = "getStationByProject";

export const SET_ERROR = "setError";
export const SET_STATION = "setStation";
export const SET_DELETE_STATION = "setDeleteStation";
export const SET_SAVE_STATION = "setSaveStation";
export const SET_UPDATE_STATION = "setUpdateStation";
export const SET_LOADING_STATION = "setLoadingStation";
export const SET_STATION_PROJECT = "setStationProject";

const state = {
  stations: {},
  stationsProject: [],
  errors: {},
  loading: false
};

const getters = {
  currentStations(state) {
    return state.stations;
  },

  currentStationProject(state) {
    return state.stationsProject.data;
  }
};

const actions = {
  [GET_STATION_BY_PROJECT](context, params) {
    return new Promise(resolve => {
      ApiService.get("station", `${params}/project`)
        .then(({ data }) => {
          context.commit(SET_STATION_PROJECT, data);

          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  [GET_STATION](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_STATION, true);

      ApiService.setHeader();

      ApiService.query("station", params)
        .then(({ data }) => {
          context.commit(SET_STATION, data);

          resolve(data);

          context.commit(SET_LOADING_STATION, false);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_STATION, false);
        });
    });
  },

  [DELETE_STATION](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_STATION, true);

      ApiService.setHeader();

      ApiService.delete(`station/${params.code}/delete`)
        .then(({ data }) => {
          context.commit(SET_DELETE_STATION, params.code);

          resolve(data);

          context.commit(SET_LOADING_STATION, false);

          showToast("Success", "Data Deleted Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_STATION, false);

          showToast("Failed", "Failed Delete Data", "danger");
        });
    });
  },

  [SAVE_STATION](context, body) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_STATION, true);

      ApiService.setHeader();

      ApiService.post("station", body)
        .then(({ data }) => {
          context.commit(SET_SAVE_STATION, data?.data);

          resolve(data);

          context.commit(SET_LOADING_STATION, false);

          showToast("Success", "Data Saved Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_STATION, false);

          showToast("Failed", "Failed Save Data", "danger");
        });
    });
  },

  [UPDATE_STATION](context, body) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_STATION, true);

      ApiService.setHeader();

      ApiService.put("station", body)
        .then(({ data }) => {
          context.commit(SET_UPDATE_STATION, data?.data);

          resolve(data);

          context.commit(SET_LOADING_STATION, false);

          showToast("Success", "Data Updated Sucessfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_STATION, false);

          showToast("Failed", "Failed Update Data", "danger");
        });
    });
  }
};

const mutations = {
  [SET_STATION](state, stations) {
    state.stations = stations;
  },

  [SET_SAVE_STATION](state, station) {
    state.stations.data = [station, ...state.stations.data];
  },

  [SET_LOADING_STATION](state, loading) {
    state.loading = loading;
  },

  [SET_STATION_PROJECT](state, stationsProject) {
    state.stationsProject = stationsProject;
  },

  [SET_UPDATE_STATION](state, updateStation) {
    const index = state.stations?.data?.findIndex(
      station => station.code === updateStation.code
    );

    if (index >= 0) {
      Object.assign(state.stations?.data[index], updateStation);
    }
  },

  [SET_DELETE_STATION](state, code) {
    const index = state.stations?.data?.findIndex(
      station => station.code === code
    );

    if (index >= 0) {
      state.stations?.data?.splice(index, 1);
    }
  },

  [SET_ERROR](state, error) {
    state.errors = error;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
