import ApiService from "../api.service";
import { showToast } from "../../helper/toast.helper";

export const GET_SITE_DOCUMENT_TYPE = "getSiteDocumentType";
export const DELETE_SITE_DOCUMENT_TYPE = "deleteSiteDocumentType";
export const SAVE_SITE_DOCUMENT_TYPE = "saveSiteDocumentType";
export const UPDATE_SITE_DOCUMENT_TYPE = "updateSiteDocumentType";

export const SET_ERROR = "setError";
export const SET_SITE_DOCUMENT_TYPE = "setSiteDocumentType";
export const SET_DELETE_SITE_DOCUMENT_TYPE = "setDeleteSiteDocumentType";
export const SET_SAVE_SITE_DOCUMENT_TYPE = "setSaveSiteDocumentType";
export const SET_UPDATE_SITE_DOCUMENT_TYPE = "setUpdateSiteDocumentType";
export const SET_LOADING_SITE_DOCUMENT_TYPE = "setLoadingSiteDocumentType";

const state = {
  siteDocumentTypes: {},
  errors: {},
  loading: false
};

const actions = {
  [GET_SITE_DOCUMENT_TYPE](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_SITE_DOCUMENT_TYPE, true);

      ApiService.query("site/document/type", params)
        .then(({ data }) => {
          context.commit(SET_SITE_DOCUMENT_TYPE, data);

          resolve(data);

          context.commit(SET_LOADING_SITE_DOCUMENT_TYPE, false);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_SITE_DOCUMENT_TYPE, false);
        });
    });
  },

  [DELETE_SITE_DOCUMENT_TYPE](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_SITE_DOCUMENT_TYPE, true);

      ApiService.setHeader();

      ApiService.delete(`site/document/type/${params.code}/delete`)
        .then(({ data }) => {
          context.commit(SET_DELETE_SITE_DOCUMENT_TYPE, params.code);

          resolve(data);

          context.commit(SET_LOADING_SITE_DOCUMENT_TYPE, false);

          showToast("Success", "Data Deleted Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_SITE_DOCUMENT_TYPE, false);

          showToast("Failed", "Failed Delete Data", "danger");
        });
    });
  },

  [SAVE_SITE_DOCUMENT_TYPE](context, body) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_SITE_DOCUMENT_TYPE, true);

      ApiService.setHeader();

      ApiService.post("site/document/type", body)
        .then(({ data }) => {
          context.commit(SET_SAVE_SITE_DOCUMENT_TYPE, data?.data);

          resolve(data);

          context.commit(SET_LOADING_SITE_DOCUMENT_TYPE, false);

          showToast("Success", "Data Saved Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_SITE_DOCUMENT_TYPE, false);

          showToast("Failed", "Failed Save Data", "danger");
        });
    });
  },

  [UPDATE_SITE_DOCUMENT_TYPE](context, body) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_SITE_DOCUMENT_TYPE, true);

      ApiService.setHeader();

      ApiService.put("site/document/type", body)
        .then(({ data }) => {
          context.commit(SET_UPDATE_SITE_DOCUMENT_TYPE, data?.data);

          resolve(data);

          context.commit(SET_LOADING_SITE_DOCUMENT_TYPE, false);

          showToast("Success", "Data Updated Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_SITE_DOCUMENT_TYPE, false);

          showToast("Failed", "Failed Update Data", "danger");
        });
    });
  }
};

const mutations = {
  [SET_SITE_DOCUMENT_TYPE](state, siteDocumentTypes) {
    state.siteDocumentTypes = siteDocumentTypes;
  },

  [SET_ERROR](state, error) {
    state.errors = error;
  },

  [SET_SAVE_SITE_DOCUMENT_TYPE](state, siteDocumentType) {
    state.siteDocumentTypes.data = [
      siteDocumentType,
      ...state.siteDocumentTypes.data
    ];
  },

  [SET_LOADING_SITE_DOCUMENT_TYPE](state, loading) {
    state.loading = loading;
  },

  [SET_UPDATE_SITE_DOCUMENT_TYPE](state, updateData) {
    const index = state.siteDocumentTypes?.data?.findIndex(
      siteDocumentType => siteDocumentType.code === updateData.code
    );

    if (index >= 0) {
      Object.assign(state.siteDocumentTypes?.data[index], updateData);
    }
  },

  [SET_DELETE_SITE_DOCUMENT_TYPE](state, code) {
    const index = state.siteDocumentTypes?.data?.findIndex(
      siteDocumentType => siteDocumentType.code === code
    );

    if (index >= 0) {
      state.siteDocumentTypes?.data?.splice(index, 1);
    }
  }
};

export default {
  state,
  actions,
  mutations
};
