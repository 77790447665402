const ID_TOKEN_KEY = "id_token";
const CURRENT_USER = "current_user";
const CURRENT_CREDENTIAL = "current_credential";

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export const saveUserLocal = user => {
  window.localStorage.setItem(CURRENT_USER, user);
};

export const saveUserPassword = user => {
  window.localStorage.setItem(CURRENT_CREDENTIAL, user);
};

export const getUserLocal = () => {
  return window.localStorage.getItem(CURRENT_USER);
};

export default {
  getToken,
  saveToken,
  destroyToken,
  saveUserLocal,
  getUserLocal
};
