import ApiService from "../api.service";
import { showToast } from "../../helper/toast.helper";

export const GET_SCOPE_OF_DESIGN = "getScopeOfDesign";
export const DELETE_SCOPE_OF_DESIGN = "deleteScopeOfDesign";
export const SAVE_SCOPE_OF_DESIGN = "saveScopeOfDesign";
export const UPDATE_SCOPE_OF_DESIGN = "updateScopeOfDesign";

export const SET_ERROR = "setError";
export const SET_SCOPE_OF_DESIGN = "setScopeOfDesign";
export const SET_DELETE_SCOPE_OF_DESIGN = "setDeleteScopeOfDesign";
export const SET_SAVE_SCOPE_OF_DESIGN = "setSaveScopeOfDesign";
export const SET_UPDATE_SCOPE_OF_DESIGN = "setUpdateScopeOfDesign";
export const SET_LOADING_SCOPE_OF_DESIGN = "setLoadingScopeOfDesign";

const state = {
  scopeOfDesigns: {},
  errors: {},
  loading: false
};

const actions = {
  [GET_SCOPE_OF_DESIGN](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_SCOPE_OF_DESIGN, true);

      ApiService.query("scope/design", params)
        .then(({ data }) => {
          context.commit(SET_SCOPE_OF_DESIGN, data);

          resolve(data);

          context.commit(SET_LOADING_SCOPE_OF_DESIGN, false);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_SCOPE_OF_DESIGN, false);
        });
    });
  },

  [DELETE_SCOPE_OF_DESIGN](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_SCOPE_OF_DESIGN, true);

      ApiService.setHeader();

      ApiService.delete(`scope/design/${params.code}/delete`)
        .then(({ data }) => {
          context.commit(SET_DELETE_SCOPE_OF_DESIGN, params.code);

          resolve(data);

          context.commit(SET_LOADING_SCOPE_OF_DESIGN, false);

          showToast("Success", "Data Deleted Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_SCOPE_OF_DESIGN, false);

          showToast("Failed", "Failed Delete Data", "danger");
        });
    });
  },

  [SAVE_SCOPE_OF_DESIGN](context, body) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_SCOPE_OF_DESIGN, true);

      ApiService.setHeader();

      ApiService.post("scope/design", body)
        .then(({ data }) => {
          context.commit(SET_SAVE_SCOPE_OF_DESIGN, data?.data);

          resolve(data);

          context.commit(SET_LOADING_SCOPE_OF_DESIGN, false);

          showToast("Success", "Data Saved Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_SCOPE_OF_DESIGN, false);

          showToast("Failed", "Failed Save Data", "danger");
        });
    });
  },

  [UPDATE_SCOPE_OF_DESIGN](context, body) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_SCOPE_OF_DESIGN, true);

      ApiService.setHeader();

      ApiService.put("scope/design", body)
        .then(({ data }) => {
          context.commit(SET_UPDATE_SCOPE_OF_DESIGN, data?.data);

          resolve(data);

          context.commit(SET_LOADING_SCOPE_OF_DESIGN, false);

          showToast("Success", "Data Updated Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_SCOPE_OF_DESIGN, false);

          showToast("Failed", "Failed Update Data", "danger");
        });
    });
  }
};

const mutations = {
  [SET_SCOPE_OF_DESIGN](state, scopeOfDesigns) {
    state.scopeOfDesigns = scopeOfDesigns;
  },

  [SET_ERROR](state, error) {
    state.errors = error;
  },

  [SET_SAVE_SCOPE_OF_DESIGN](state, scopeOfDesign) {
    state.scopeOfDesigns.data = [scopeOfDesign, ...state.scopeOfDesigns.data];
  },

  [SET_LOADING_SCOPE_OF_DESIGN](state, loading) {
    state.loading = loading;
  },

  [SET_UPDATE_SCOPE_OF_DESIGN](state, updateOfDesign) {
    const index = state.scopeOfDesigns?.data?.findIndex(
      scopeOfDesign => scopeOfDesign.code === updateOfDesign.code
    );

    if (index >= 0) {
      Object.assign(state.scopeOfDesigns?.data[index], updateOfDesign);
    }
  },

  [SET_DELETE_SCOPE_OF_DESIGN](state, code) {
    const index = state.scopeOfDesigns?.data?.findIndex(
      scopeOfDesign => scopeOfDesign.code === code
    );

    if (index >= 0) {
      state.scopeOfDesigns?.data?.splice(index, 1);
    }
  }
};

export default {
  state,
  actions,
  mutations
};
