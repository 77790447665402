import ApiService from "../api.service";
import { showToast } from "../../helper/toast.helper";

export const GET_REPORT_AS_BUILT_DRAWING = "getReportAsBuiltDrawing";

export const SET_ERROR = "setError";
export const SET_REPORT_AS_BUILT_DRAWING = "setReportAsBuiltDrawing";
export const SET_LOADING_REPORT_AS_BUILT_DRAWING =
  "setLoadingReportAsBuiltDrawing";

const state = {
  asBuiltDrawings: {},
  errors: {},
  loading: false
};

const actions = {
  [GET_REPORT_AS_BUILT_DRAWING](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_REPORT_AS_BUILT_DRAWING, true);

      ApiService.query(
        `report/distribution/asd/${params?.code}/project`,
        params
      )
        .then(response => {
          context.commit(SET_REPORT_AS_BUILT_DRAWING, response?.data);

          resolve(response?.data);

          context.commit(SET_LOADING_REPORT_AS_BUILT_DRAWING, false);

          showToast("Success", "Data Loaded Successfully");
        })
        .catch(response => {
          context.commit(SET_ERROR, response?.data?.errors);

          context.commit(SET_LOADING_REPORT_AS_BUILT_DRAWING, false);

          showToast("Failed", "Failed Load Data", "danger");
        });
    });
  }
};

const mutations = {
  [SET_REPORT_AS_BUILT_DRAWING](state, asBuiltDrawings) {
    state.asBuiltDrawings = asBuiltDrawings;
  },

  [SET_LOADING_REPORT_AS_BUILT_DRAWING](state, loading) {
    state.loading = loading;
  }
};

export default {
  state,
  actions,
  mutations
};
