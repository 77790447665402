import ApiService from "../api.service";
import { showToast } from "../../helper/toast.helper";

export const GET_REPORT_LETTERING = "getReportLettering";

export const SET_ERROR = "setError";
export const SET_REPORT_LETTERING = "setReportLettering";
export const SET_LOADING_REPORT_LETTERING = "setLoadingReportLettering";

const state = {
  reportLettering: {},
  errors: {},
  loading: false
};

const getters = {
  currentReportLettering(state) {
    return state.reportLettering?.data;
  }
};

const actions = {
  [GET_REPORT_LETTERING](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_REPORT_LETTERING, true);
      ApiService.query(`report/lettering`, params)
        .then(({ data }) => {
          context.commit(SET_REPORT_LETTERING, data);
          resolve(data);
          context.commit(SET_LOADING_REPORT_LETTERING, false);
          showToast("Success", "Data Loaded Successfully");
        })
        .catch(response => {
          context.commit(SET_ERROR, response?.data?.errors);
          context.commit(SET_LOADING_REPORT_LETTERING, false);
          showToast("Failed", "Failed Load Data", "danger");
        });
    });
  }
};

const mutations = {
  [SET_REPORT_LETTERING](state, Lettering) {
    state.reportLettering = Lettering;
  },

  [SET_LOADING_REPORT_LETTERING](state, loading) {
    state.loading = loading;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
