// action types
import ApiService from "../api.service";
import { showToast } from "../../helper/toast.helper";

export const GET_MONITORING_SHOP_DRAWING = "getMonitoringShopDrawing";
export const UPDATE_MONITORING_SHOP_DRAWING = "updateMonitoringShopDrawing";

// mutation types
export const SET_MONITORING_SHOP_DRAWING = "setMonitoringShopDrawing";
export const SET_UPDATE_MONITORING_SHOP_DRAWING =
  "setUpdateMonitoringShopDrawing";
export const SET_LOADING_MONITORING_SHOP_DRAWING =
  "setLoadingMonitoringShopDrawing";
export const SET_ERROR_MONITORING_SHOP_DRAWING =
  "setErrorMonitoringShopDrawing";

export default {
  state: {
    loading: false,
    errors: {},
    data: {}
  },
  getters: {
    //
  },
  actions: {
    /**
     * Get
     * @param context
     * @param params
     */
    [GET_MONITORING_SHOP_DRAWING](context, params) {
      return new Promise((resolve, reject) => {
        context.commit(SET_LOADING_MONITORING_SHOP_DRAWING, true);

        ApiService.setHeader();

        ApiService.query(
          `project/${params.params.project_code}/monitoring/shop/drawing/eksternal`,
          params
        )
          .then(({ data }) => {
            resolve(data);

            context.commit(SET_MONITORING_SHOP_DRAWING, data);

            context.commit(SET_LOADING_MONITORING_SHOP_DRAWING, false);
          })
          .catch(({ response }) => {
            reject(response);

            context.commit(
              SET_ERROR_MONITORING_SHOP_DRAWING,
              response.data.errors
            );

            context.commit(SET_LOADING_MONITORING_SHOP_DRAWING, false);
          });
      });
    },

    /**
     * Update
     * @param context
     * @param params
     */
    [UPDATE_MONITORING_SHOP_DRAWING](context, body) {
      return new Promise((resolve, reject) => {
        context.commit(SET_LOADING_MONITORING_SHOP_DRAWING, true);

        ApiService.setHeader();

        ApiService.post(`project/monitoring/shop/drawing/eksternal`, body)
          .then(({ data }) => {
            resolve(data);

            context.commit(SET_UPDATE_MONITORING_SHOP_DRAWING, body);

            context.commit(SET_LOADING_MONITORING_SHOP_DRAWING, false);

            showToast("Success", "Data Updated Successfully");
          })
          .catch(({ response }) => {
            reject(response);

            context.commit(
              SET_ERROR_MONITORING_SHOP_DRAWING,
              response.data.errors
            );

            context.commit(SET_LOADING_MONITORING_SHOP_DRAWING, false);

            showToast("Failed", "Failed Update Data", "danger");
          });
      });
    }
  },
  mutations: {
    [SET_MONITORING_SHOP_DRAWING](state, data) {
      state.data = data;
    },

    [SET_LOADING_MONITORING_SHOP_DRAWING](state, loading) {
      state.loading = loading;
    },

    [SET_UPDATE_MONITORING_SHOP_DRAWING](state, body) {
      body.dld.map(currentBody => {
        const index = state.data?.data.findIndex(
          monitoringShopDrawing => monitoringShopDrawing?.id === currentBody.id
        );

        if (index >= 0) {
          Object.assign(state.data?.data[index], {
            ...currentBody
          });
        }
      });
    }
  }
};
