// action types
import ApiService from "../api.service";
import { showToast } from "../../helper/toast.helper";

export const GET_MONITORING_LOGISTIC = "getMonitoringLogistic";
export const UPDATE_MONITORING_LOGISTIC = "updateMonitoringLogistic";

// mutation types
export const SET_MONITORING_LOGISTIC = "setMonitoringLogistic";
export const SET_UPDATE_MONITORING_LOGISTIC = "setUpdateMonitoringLogistic";
export const SET_LOADING_MONITORING_LOGISTIC = "setLoadingMonitoringLogistic";
export const SET_ERROR_MONITORING_LOGISTIC = "setErrorMonitoringLogistic";

export default {
  state: {
    loading: false,
    errors: {},
    data: {}
  },
  getters: {
    //
  },
  actions: {
    /**
     * Get
     * @param context
     * @param params
     */
    [GET_MONITORING_LOGISTIC](context, params) {
      return new Promise((resolve, reject) => {
        context.commit(SET_LOADING_MONITORING_LOGISTIC, true);

        ApiService.setHeader();

        ApiService.query(
          `project/${params.params.project_code}/monitoring/logistic`,
          params
        )
          .then(({ data }) => {
            resolve(data);

            context.commit(SET_MONITORING_LOGISTIC, data);

            context.commit(SET_LOADING_MONITORING_LOGISTIC, false);
          })
          .catch(({ response }) => {
            reject(response);

            context.commit(SET_ERROR_MONITORING_LOGISTIC, response.data.errors);

            context.commit(SET_LOADING_MONITORING_LOGISTIC, false);
          });
      });
    },

    /**
     * Update
     * @param context
     * @param params
     */
    [UPDATE_MONITORING_LOGISTIC](context, body) {
      return new Promise((resolve, reject) => {
        context.commit(SET_LOADING_MONITORING_LOGISTIC, true);

        ApiService.setHeader();

        ApiService.post(`project/monitoring/logistic`, body)
          .then(({ data }) => {
            resolve(data);

            context.commit(SET_UPDATE_MONITORING_LOGISTIC, body);

            context.commit(SET_LOADING_MONITORING_LOGISTIC, false);

            showToast("Success", "Data Updated Successfully");
          })
          .catch(({ response }) => {
            reject(response);

            context.commit(SET_ERROR_MONITORING_LOGISTIC, response.data.errors);

            context.commit(SET_LOADING_MONITORING_LOGISTIC, false);

            showToast("Failed", "Failed Update Data", "danger");
          });
      });
    }
  },
  mutations: {
    [SET_MONITORING_LOGISTIC](state, data) {
      state.data = data;
    },

    [SET_LOADING_MONITORING_LOGISTIC](state, loading) {
      state.loading = loading;
    },

    [SET_UPDATE_MONITORING_LOGISTIC](state, body) {
      body.dld.map(currentBody => {
        const index = state.data?.data.findIndex(
          monitoringLogistic => monitoringLogistic?.id === currentBody.id
        );

        if (index >= 0) {
          Object.assign(state.data?.data[index], {
            ...currentBody
          });
        }
      });
    }
  }
};
