import ApiService from "../api.service";
import { showToast } from "../../helper/toast.helper";

export const GET_CHANGE_MANAGEMENT = "getChangeManagement";
export const SET_CHANGE_MANAGEMENT = "setChangeManagement";

export const GET_PROJECT_CHANGE = "getProjectChange";
export const SET_PROJECT_CHANGE = "setProjectChange";

export const GET_MANAGEMENT_TYPE = "getManagementType";
export const SET_MANAGEMENT_TYPE = "setManagementType";

export const GET_MANAGEMENT_SCOPE = "getManagementScope";
export const SET_MANAGEMENT_SCOPE = "setManagementScope";

export const GET_PIC_CHANGE = "getPicChange";
export const SET_PIC_CHANGE = "setPicChange";

export const GET_STATUS_CHANGE = "getStatusChange";
export const SET_STATUS_CHANGE = "setStatusChange";

export const GET_MANAGEMENT_RESPONSE = "getManagementResponse";
export const SET_MANAGEMENT_RESPONSE = "setManagementResponse";

export const GET_DETAIL_CHANGE = "getDetailChangeManagement";
export const SET_DETAIL_CHANGE = "setDetailChangeManagement";

export const POST_INFO_CHANGE_MANAGEMENT = "postInfoChangeManagement";
export const SET_RES_INFO_CHANGE = "setResponInfoChangeManagement";

export const POST_CHANGE_MANAGEMENT = "postChangeManagement";
export const RES_SAVE_CHANGE = "resultSaveChange";

export const ADD_CHANGE_MANAGEMENT_ITEM = "addChangeManagementItem";
export const SET_ADD_CHANGE_MANAGEMENT_ITEM = "setAddChangeManagementItem";
export const POST_CHANGE_MANAGEMENT_ITEM = "postChangeManagementItem";

export const RESET_CHANGE_MANAGEMENT = "resetChangeManagement";
export const SET_RESET_CHANGE_MANAGEMENT = "setResetChangeManagement";

export const DELETE_MANAGEMENT = "deleteManagement";
export const SET_DELETE_MANAGEMENT = "setDeleteManagement";

// management item
export const GET_MANAGEMENT_ITEM = "getManagementItem";
export const SET_MANAGEMENT_ITEM = "setManagementItem";

export const DELETE_MANAGEMENT_ITEM = "deleteManagementItem";
export const SET_DELETE_MANAGEMENT_ITEM = "setDeleteManagementItem";

// design terdampak
export const GET_DESIGN_TERDAMPAK = "getDesignTerdampak";
export const SET_DESIGN_TERDAMPAK = "setDesignTerdampak";

export const UPDATE_DESIGN_TERDAMPAK = "updateDesignTerdampak";
export const RES_UPDATE_DESIGN_TERDAMPAK = "resUpdateDesignTerdampak";

export const POST_DESIGN_TERDAMPAK = "postDesignTerdampak";
export const RES_POST_DESIGN_TERDAMPAK = "resPostDesignTerdampak";

export const GET_DLD_CHANGE = "getDldChange";
export const SET_DLD_CHANGE = "setDldChange";

export const DELETE_DESIGN_TERDAMPAK = "deleteDesignTerdampak";
export const RES_DELETE_DESIGN_TERDAMPAK = "resDeleteDesignTerdampak";

export const RESET_DESIGN_TERDAMPAK = "resetDesignTerdampak";
export const SET_RESET_DESIGN_TERDAMPAK = "setResetDesignTerdampak";

// pic
export const GET_PIC_CHANGE_MANAGEMENT = "getPicChangeManagement";
export const SET_PIC_CHANGE_MANAGEMENT = "setPicChangeManagement";

export const GET_LIST_MANAGEMENT_ITEM = "getListManagementItem";
export const SET_LIST_MANAGEMENT_ITEM = "setListManagementItem";

export const SAVE_PIC_CHANGE_MANAGEMENT = "savePicChangeManagement";
export const RES_SAVE_PIC_CHANGE_MANAGEMENT = "resSaveChangeManagement";

export const DELETE_PIC_CHANGE_MANAGEMENT = "deletePicChangeManagement";

// file
export const GET_FILE_CHANGE_MANAGEMENT = "getFileChangeManagement";
export const SET_FILE_CHANGE_MANAGEMENT = "setFileChangeManagement";

export const GET_FILE_DIRECTORY = "getFileDirectory";
export const SET_FILE_DIRECTORY = "setFileDirectory";

export const SAVE_FILE_CHANGE_MANAGEMENT = "saveFileChangeManagement";
export const RES_SAVE_FILE_CHANGE_MANAGEMENT = "resSaveFileChangeManagement";

export const DELETE_FILE_CHANGE_MANAGEMENT = "deleteFileChangeManagement";
export const RES_DELETE_FILE_CHANGE_MANAGEMENT =
  "resDeleteFileChangeManagement";

// response
export const GET_RESPONSE_CHANGE_MANAGEMENT = "getResponseChangeManagement";
export const SET_RESPONSE_CHANGE_MANAGEMENT = "setResponseChangeManagement";

export const ADD_RESPONSE_CHANGE_MANAGEMENT = "addResponseChangeManagement";
export const RES_ADD_RESPONSE_CHANGE_MANAGEMENT =
  "resAddResponseChangeManagement";
export const UPDATE_RESPONSE_CHANGE_MANAGEMENT =
  "updateResponseChangeManagement";
export const RES_UPDATE_RESPONSE_CHANGE_MANAGEMENT =
  "resUpdateResponseChangeManagement";
export const DELETE_RESPONSE = "deleteResponse";
export const RES_DELETE_RESPONSE = "resDeleteResponse";

// response chief
export const GET_RES_CHIEF_CHANGE_MANAGEMENT = "getResChiefChangeManagement";
export const SET_RES_CHIEF_CHANGE_MANAGEMENT = "setResChiefChangeManagement";

export const UPDATE_RES_CHIEF_CHANGE_MANAGEMENT =
  "updateResChiefChangeManagement";
export const RES_UPDATE_RES_CHIEF_CHANGE_MANAGEMENT =
  "setUpdateResChiefChangeManagement";
export const DELETE_RESPONSE_CHIEF = "deleteResponseChief";
export const RES_DELETE_RESPONSE_CHIEF = "resDeleteResponseChief";

export const ADD_RES_CHIEF_CHANGE_MANAGEMENT = "addResChiefChangeManagement";
export const RES_ADD_RES_CHIEF_CHANGE_MANAGEMENT =
  "setaddResChiefChangeManagement";
const state = {
  list_change: [],
  master_change: [],
  list_project_change: [],
  result_change: [],
  list_type_management: [],
  list_scope_management: [],
  list_response_management: [],
  list_pic_change: [],
  list_status_change_management: [],
  current_id_change_management: [],
  res_info_change: [],
  list_management_item: [],
  list_design_terdampak: [],
  res_update_design_terdampak: [],
  list_dld_change: [],
  res_post_design_terdampak: [],
  list_pic_change_management: [],
  res_save_pic_change_management: [],
  list_file_change_management: [],
  res_save_file_change_management: [],
  res_delete_file_change_management: [],
  list_response_change_management: [],
  res_update_response_change_management: [],
  res_add_response_change_management: [],
  list_res_chief_change_management: [],
  res_update_res_chief_change_management: [],
  res_add_res_chief_change_management: [],
  file_directory: ""
};
const getters = {
  getterChangeManagement(state) {
    return state.list_change;
  },

  getterMasterChangeManagement(state) {
    return state.master_change;
  },

  getterProjectChange(state) {
    return state.list_project_change;
  },

  getterResultSaveChange(state) {
    return state.result_change;
  },

  getterTypeManagement(state) {
    return state.list_type_management;
  },

  getterScopeManagement(state) {
    return state.list_scope_management;
  },

  getterResponseManagement(state) {
    return state.list_response_management;
  },

  getterPicChangeMangement(state) {
    return state.list_pic_change;
  },

  getterStatusChangeManagement(state) {
    return state.list_status_change_management;
  },

  getterDetailChangeManagement(state) {
    return state.current_id_change_management;
  },

  getterManagementItem(state) {
    return state.list_management_item;
  },

  getterDesignTerdampak(state) {
    return state.list_design_terdampak;
  },

  getterUpdateDesignTerdampak(state) {
    return state.res_update_design_terdampak;
  },

  getterDldChange(state) {
    return state.list_dld_change;
  },

  getterResPostDesignTerdampak(state) {
    return state.res_post_design_terdampak;
  },

  getterPicManagement(state) {
    return state.list_pic_change_management;
  },

  getterResSavePicChange(state) {
    return state.res_save_pic_change_management;
  },

  getterFileChangeManagement(state) {
    return state.list_file_change_management;
  },

  getterFileDirectory(state) {
    return state.file_directory;
  },

  getterResFileChangeManagement(state) {
    return state.res_save_file_change_management;
  },

  getterResDeleteFileChnage(state) {
    return state.res_delete_file_change_management;
  },

  getterResponseChangeManagement(state) {
    return state.list_response_change_management;
  },

  getterResUpdatResponseChangeManagement(state) {
    return state.res_update_response_change_management;
  },

  getterResChiefChangeManagement(state) {
    return state.list_res_chief_change_management;
  },

  getterResUpdateResChiefChangeManagement(state) {
    return state.res_update_res_chief_change_management;
  }
};

const actions = {
  [GET_CHANGE_MANAGEMENT](context, param) {
    ApiService.query("project/design/change/management", {
      params: param
    }).then(res => {
      context.commit(SET_CHANGE_MANAGEMENT, res.data.data);
    });
  },

  [GET_PROJECT_CHANGE](context) {
    ApiService.query("project/design/jobdesk").then(res => {
      context.commit(SET_PROJECT_CHANGE, res.data.data);
    });
  },

  async [POST_CHANGE_MANAGEMENT](context, param) {
    const file = param.file;

    delete param.file;

    await ApiService.post("project/change/management/project", param)
      .then(async res => {
        context.commit(RES_SAVE_CHANGE, res.data.data);

        if (file) {
          const formData = new FormData();

          formData.append("file", file);

          await ApiService.post(
            `project/change/management/${res.data.data.id}/upload`,
            formData
          ).then(() => {});
        }
      })
      .catch(error => {
        context.commit(RES_SAVE_CHANGE, {
          code: error.response.data.code,
          msg: error.response.data.message
        });
      });
  },

  async [GET_MANAGEMENT_TYPE](context) {
    await ApiService.query("change/management/type").then(res => {
      context.commit(SET_MANAGEMENT_TYPE, res.data.data);
    });
  },

  async [GET_MANAGEMENT_SCOPE](context) {
    await ApiService.query("scope/category").then(res => {
      context.commit(SET_MANAGEMENT_SCOPE, res.data.data);
    });
  },

  async [GET_MANAGEMENT_RESPONSE](context) {
    await ApiService.query("pic").then(res => {
      context.commit(SET_MANAGEMENT_RESPONSE, res.data.data);
    });
  },

  async [GET_PIC_CHANGE](context) {
    await ApiService.query("pic").then(res => {
      context.commit(SET_PIC_CHANGE, res.data.data);
    });
  },

  async [GET_STATUS_CHANGE](context) {
    await ApiService.query("change/management/response/status").then(res => {
      console.log(res.data.data);
      context.commit(SET_STATUS_CHANGE, res.data.data);
    });
  },

  async [GET_DETAIL_CHANGE](context, param) {
    await ApiService.query(
      "project/change/management/" + param.id + "/detail"
    ).then(res => {
      context.commit(SET_DETAIL_CHANGE, res.data.data);
    });
  },

  async [POST_INFO_CHANGE_MANAGEMENT](context, payload) {
    const file = payload.payload?.file;

    delete payload.payload?.file;

    await ApiService.post(
      "project/change/management/" + payload.payload.id + "/detail",
      payload.payload.send
    ).then(async res => {
      context.commit(SET_RES_INFO_CHANGE, res.data.data);

      if (file) {
        const formData = new FormData();

        formData.append("file", file);

        await ApiService.post(
          `project/change/management/${payload.payload.id}/upload`,
          formData
        ).then(response => {
          context.commit(SET_RES_INFO_CHANGE, response.data.data);
        });
      }
    });
  },

  async [GET_MANAGEMENT_ITEM](context, param) {
    await ApiService.query("project/change/management/" + param.id + "/item")
      .then(res => {
        context.commit(SET_MANAGEMENT_ITEM, res.data.data);
      })
      .catch(() => {
        showToast("Failed", "Data Not Found", "danger");
      });
  },

  [DELETE_MANAGEMENT_ITEM](context, params) {
    if (params.code) {
      return new Promise(resolve => {
        ApiService.setHeader();

        ApiService.delete(`project/change/management/item/${params.id}/delete`)
          .then(({ data }) => {
            context.commit(SET_DELETE_MANAGEMENT_ITEM, {
              code: params.code,
              index: params.index
            });

            resolve(data);

            showToast("Success", "Data Deleted Successfully");
          })
          .catch(() => {
            showToast("Failed", "Failed Delete Data", "danger");
          });
      });
    } else {
      context.commit(SET_DELETE_MANAGEMENT_ITEM, {
        code: params.code,
        index: params.index
      });
    }
  },

  async [POST_CHANGE_MANAGEMENT_ITEM](context, param) {
    await ApiService.post("project/change/management/" + param.id + "/item", {
      item: [param.item]
    }).then(() => {
      context.dispatch(GET_MANAGEMENT_ITEM, {
        id: param.id
      });

      showToast("Success", "Item Saved Successfully");
    });
  },

  [ADD_CHANGE_MANAGEMENT_ITEM](context, param) {
    context.commit(SET_ADD_CHANGE_MANAGEMENT_ITEM, param);
  },

  async [GET_DESIGN_TERDAMPAK](context, param) {
    await ApiService.query("project/change/management/" + param.id + "/design")
      .then(res => {
        console.log(res.data.data);
        context.commit(SET_DESIGN_TERDAMPAK, res.data.data);
      })
      .catch(() => {
        showToast("Failed", "Data Not Found", "danger");
      });
  },

  async [UPDATE_DESIGN_TERDAMPAK](context, data) {
    await ApiService.post(
      "project/change/management/" + data.id + "/design",
      data.send
    ).then(res => {
      context.commit(RES_UPDATE_DESIGN_TERDAMPAK, res.data.data);
    });
  },

  async [GET_DLD_CHANGE](context, param) {
    await ApiService.query(
      "project/" + param.code + "/" + param.type + "/dld"
    ).then(res => {
      context.commit(SET_DLD_CHANGE, res.data.data);
    });
  },

  async [POST_DESIGN_TERDAMPAK](context, param) {
    await ApiService.post("project/change/management/" + param.id + "/design", {
      dld: param.send,
      project: param.project
    })
      .then(res => {
        context.commit(RES_POST_DESIGN_TERDAMPAK, res.data.data);
      })
      .catch(error => {
        context.commit(RES_POST_DESIGN_TERDAMPAK, {
          code: error.response.data.code,
          msg: error.response.data.message,
          id: null
        });
      });
  },

  async [DELETE_DESIGN_TERDAMPAK](context, param) {
    await ApiService.delete(
      "project/change/management/design/" + param.id + "/delete"
    ).then(res => {
      context.commit(RES_DELETE_DESIGN_TERDAMPAK, res.data.data);
    });
  },
  async [DELETE_RESPONSE](context, param) {
    await ApiService.delete(
      "project/change/management/" + param.id + "/response/delete"
    ).then(res => {
      context.commit(RES_DELETE_RESPONSE, res.data.data);
    });
  },
  async [DELETE_RESPONSE_CHIEF](context, param) {
    await ApiService.delete(
      "project/change/management/" + param.id + "/response/chief/delete"
    ).then(res => {
      context.commit(RES_DELETE_RESPONSE_CHIEF, res.data.data);
    });
  },

  async [GET_PIC_CHANGE_MANAGEMENT](context, param) {
    await ApiService.query("project/change/management/" + param.id + "/pic")
      .then(res => {
        context.commit(SET_PIC_CHANGE_MANAGEMENT, res.data.data);
      })
      .catch(() => {
        showToast("Failed", "Data Not Found", "danger");
      });
  },

  async [GET_LIST_MANAGEMENT_ITEM](context, param) {
    await ApiService.query(
      "project/change/management/" + param.id + "/item"
    ).then(res => {
      context.commit(SET_LIST_MANAGEMENT_ITEM, res.data.data);
    });
  },

  async [SAVE_PIC_CHANGE_MANAGEMENT](context, param) {
    await ApiService.post("project/change/management/" + param.id + "/pic", {
      pic: param.send
    }).then(res => {
      context.commit(RES_SAVE_PIC_CHANGE_MANAGEMENT, res.data.data);
    });
  },

  async [DELETE_PIC_CHANGE_MANAGEMENT](context, param) {
    await ApiService.delete(
      "project/change/management/pic/" + param.id + "/delete"
    ).then(res => {
      console.log(res.data.data);
      ApiService.query(
        "project/change/management/" + param.id_change_management + "/pic"
      )
        .then(res => {
          context.commit(SET_PIC_CHANGE_MANAGEMENT, res.data.data);
        })
        .catch(error => {
          context.commit(SET_PIC_CHANGE_MANAGEMENT, error.response.data.code);
        });
    });
  },

  async [GET_FILE_CHANGE_MANAGEMENT](context, param) {
    await ApiService.query("project/change/management/" + param.id + "/file")
      .then(res => {
        console.log(res.data.data);
        context.commit(SET_FILE_CHANGE_MANAGEMENT, res.data.data);
      })
      .catch(() => {
        showToast("Failed", "Data Not Found", "danger");
      });
  },

  async [GET_FILE_DIRECTORY](context, param) {
    await ApiService.query("ftp/folder/upload", { params: param.send }).then(
      res => {
        context.commit(SET_FILE_DIRECTORY, res.data.data);
      }
    );
  },

  async [SAVE_FILE_CHANGE_MANAGEMENT](context, param) {
    await ApiService.post("project/change/management/" + param.id + "/file", {
      file: param.send
    }).then(res => {
      context.commit(RES_SAVE_FILE_CHANGE_MANAGEMENT, res.data.data);
    });
  },

  async [DELETE_FILE_CHANGE_MANAGEMENT](context, param) {
    await ApiService.delete(
      "project/change/management/file/" + param.id + "/delete"
    )
      .then(res => {
        context.commit(RES_DELETE_FILE_CHANGE_MANAGEMENT, res.data.data);
      })
      .catch(error => {
        context.commit(RES_DELETE_FILE_CHANGE_MANAGEMENT, error.response.code);
      });
  },

  async [GET_RESPONSE_CHANGE_MANAGEMENT](context, param) {
    return new Promise(resolve => {
      ApiService.query("project/change/management/" + param.id + "/response")
        .then(res => {
          context.commit(SET_RESPONSE_CHANGE_MANAGEMENT, res.data.data);
          resolve(res);
        })
        .catch(() => {
          showToast(
            "Failed",
            "Data Not Found, Impacted Design Not Added",
            "danger"
          );
          resolve();
        });
    });
  },

  async [UPDATE_RESPONSE_CHANGE_MANAGEMENT](context, param) {
    await ApiService.post(
      "project/change/management/" + param.id + "/response",
      { response: param.send, project_code: param.project_code }
    ).then(res => {
      context.commit(RES_UPDATE_RESPONSE_CHANGE_MANAGEMENT, res.data.data);
    });
  },
  async [ADD_RESPONSE_CHANGE_MANAGEMENT](context, param) {
    return await new Promise(resolve => {
      ApiService.post(
        "project/change/management/" + param.id + "/response/add",
        {
          response: param.send,
          project_code: param.project_code
        }
      )
        .then(({ data }) => {
          context.commit(RES_ADD_RESPONSE_CHANGE_MANAGEMENT, data.data);
          resolve(data);
        })
        .catch(() => {
          showToast("Failed", "Data Add Failed, Reponse Not Added", "danger");
          resolve();
        });
    });
  },

  async [GET_RES_CHIEF_CHANGE_MANAGEMENT](context, param) {
    return await new Promise(resolve => {
      ApiService.query(
        "project/change/management/" + param.id + "/response/chief"
      )
        .then(({ data }) => {
          context.commit(SET_RES_CHIEF_CHANGE_MANAGEMENT, data.data);
          resolve(data);
        })
        .catch(() => {
          showToast(
            "Failed",
            "Data Not Found, Impacted Design Not Added",
            "danger"
          );
          resolve();
        });
    });
  },

  async [UPDATE_RES_CHIEF_CHANGE_MANAGEMENT](context, param) {
    await ApiService.post(
      "project/change/management/" + param.id + "/response/chief",
      { response_chief: param.send, project_code: param.project_code }
    ).then(res => {
      context.commit(RES_UPDATE_RES_CHIEF_CHANGE_MANAGEMENT, res.data.data);
    });
  },

  async [ADD_RES_CHIEF_CHANGE_MANAGEMENT](context, param) {
    await ApiService.post(
      "project/change/management/" + param.id + "/response/chief/add",
      { response_chief: param.send, project_code: param.project_code }
    ).then(res => {
      context.commit(RES_ADD_RES_CHIEF_CHANGE_MANAGEMENT, res.data.data);
    });
  },

  [DELETE_MANAGEMENT](context, params) {
    return new Promise(resolve => {
      ApiService.setHeader();

      ApiService.delete(`project/change/management/${params.id}/delete`)
        .then(({ data }) => {
          resolve(data);
          context.commit(SET_DELETE_MANAGEMENT, params.id);
          showToast("Success", "Data Deleted Successfully");
        })
        .catch(() => {
          showToast("Failed", "Failed Delete Data", "danger");
        });
    });
  },

  [RESET_DESIGN_TERDAMPAK](context) {
    context.commit(SET_RESET_DESIGN_TERDAMPAK);
  },

  [RESET_CHANGE_MANAGEMENT](context, param) {
    context.commit(SET_RESET_CHANGE_MANAGEMENT, param);
  }
};

const mutations = {
  [SET_CHANGE_MANAGEMENT](state, data) {
    state.list_change = data;
  },

  [SET_PROJECT_CHANGE](state, data) {
    state.list_project_change = data;
  },

  [RES_SAVE_CHANGE](state, data) {
    state.result_change = data;
  },

  [SET_MANAGEMENT_TYPE](state, data) {
    state.list_type_management = data;
  },

  [SET_MANAGEMENT_SCOPE](state, data) {
    state.list_scope_management = data;
  },

  [SET_MANAGEMENT_RESPONSE](state, data) {
    state.list_response_management = data;
  },

  [SET_PIC_CHANGE](state, data) {
    state.list_pic_change = data;
  },

  [SET_STATUS_CHANGE](state, data) {
    state.list_status_change_management = data;
  },

  [SET_DETAIL_CHANGE](state, data) {
    state.current_id_change_management = [];
    state.current_id_change_management = data;
  },

  [SET_RES_INFO_CHANGE](state, data) {
    state.res_info_change = data;
  },

  [SET_MANAGEMENT_ITEM](state, data) {
    if (data == "400") {
      state.list_management_item = "";
      state.list_management_item = data;
    } else {
      state.list_management_item = [];
      state.list_management_item = data;
    }
  },

  [SET_DELETE_MANAGEMENT_ITEM](state, params) {
    const code = params.code;
    if (code) {
      const index = state.list_management_item?.findIndex(
        item => item.code === code
      );

      if (index >= 0) {
        state.list_management_item?.splice(index, 1);
      }
    } else {
      state.list_management_item?.splice(params.index, 1);
    }
  },

  [SET_ADD_CHANGE_MANAGEMENT_ITEM](state, params) {
    state.list_management_item.push(params);
  },

  [SET_DESIGN_TERDAMPAK](state, data) {
    state.list_design_terdampak = data;
  },

  [SET_RESET_CHANGE_MANAGEMENT](state, data) {
    if (data.state == "management-item") {
      state.list_management_item = [];
    } else if (data.state == "design-terdampak") {
      state.list_design_terdampak = [];
    } else if (data.state == "pic") {
      state.list_pic_change_management = [];
    } else if (data.state == "file") {
      state.list_file_change_management = [];
    } else if (data.state == "response") {
      state.list_response_change_management = [];
    } else if (data.state == "responseChief") {
      state.list_res_chief_change_management = [];
    }
  },

  [RES_UPDATE_DESIGN_TERDAMPAK](state, data) {
    state.res_update_design_terdampak = data;
  },

  [SET_DLD_CHANGE](state, data) {
    state.list_dld_change = data;
  },

  [RES_POST_DESIGN_TERDAMPAK](state, data) {
    if (Array.isArray(data)) {
      state.res_post_design_terdampak = [];
      state.res_post_design_terdampak = data;
    } else {
      state.res_post_design_terdampak = "";
      state.res_post_design_terdampak = data;
    }
  },

  [SET_RESET_DESIGN_TERDAMPAK](state) {
    state.list_dld_change = [];
  },

  [SET_PIC_CHANGE_MANAGEMENT](state, data) {
    if (data == 400) {
      state.list_pic_change_management = "";
      state.list_pic_change_management = data;
    } else {
      state.list_pic_change_management = [];
      state.list_pic_change_management = data;
    }
  },

  [SET_LIST_MANAGEMENT_ITEM](state, data) {
    state.list_management_item = data;
  },

  [RES_SAVE_PIC_CHANGE_MANAGEMENT](state, data) {
    state.res_save_pic_change_management = data;
  },

  [SET_FILE_CHANGE_MANAGEMENT](state, data) {
    state.list_file_change_management = data;
  },

  [SET_FILE_DIRECTORY](state, data) {
    state.file_directory = data;
  },

  [RES_SAVE_FILE_CHANGE_MANAGEMENT](state, data) {
    state.res_save_file_change_management = data;
  },

  [RES_DELETE_FILE_CHANGE_MANAGEMENT](state, data) {
    if (data == "400") {
      state.res_delete_file_change_management = "";
      state.res_delete_file_change_management = data;
    } else {
      state.res_delete_file_change_management = [];
      state.res_delete_file_change_management = data;
    }
  },

  [SET_RESPONSE_CHANGE_MANAGEMENT](state, data) {
    state.list_response_change_management = data;
  },

  [RES_UPDATE_RESPONSE_CHANGE_MANAGEMENT](state, data) {
    state.res_update_response_change_management = data;
  },

  [RES_ADD_RESPONSE_CHANGE_MANAGEMENT](state, data) {
    state.res_add_response_change_management = data;
  },

  [SET_RES_CHIEF_CHANGE_MANAGEMENT](state, data) {
    state.list_res_chief_change_management = data;
  },

  [RES_UPDATE_RES_CHIEF_CHANGE_MANAGEMENT](state, data) {
    state.res_update_res_chief_change_management = data;
  },
  [RES_ADD_RES_CHIEF_CHANGE_MANAGEMENT](state, data) {
    state.res_add_res_chief_change_management = data;
  },
  [SET_DELETE_MANAGEMENT](state, id) {
    const index = state.list_change.findIndex(change => change.id === id);

    if (index >= 0) {
      state.list_change?.splice(index, 1);
    }
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
