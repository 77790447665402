import ApiService from "../api.service";
import { showToast } from "../../helper/toast.helper";

export const GET_PIC = "getPic";
export const LOAD_PIC = "loadPic";
export const GET_LIST_PIC = "getListPic";
export const DELETE_PIC = "deletePic";
export const SEARCH_PIC = "searchPic";

export const SHOW_PIC = "showPics";
export const SAVE_PIC = "savePic";
export const EDIT_PIC = "editPic";
export const SET_PAGE = "setPage";
export const SET_TOTAL_PAGINATION = "totalPagination";

export const SET_ERROR = "setError";
export const SET_PIC = "setPic";
export const STATUS_SAVE = "statusSave";

export const SET_LOADING_PIC = "setLoadingMasterPic";
export const SET_SAVE_PIC = "setSavePic";

const state = {
  pics: {},
  listPic: [],
  statusSave: [],
  activePage: "",
  startPagination: "",
  endPagination: "",
  totalPagination: "",
  errors: {},
  loading: false
};

const getters = {
  pic(state) {
    return state.pic;
  },

  currentPics(state) {
    return state.pics?.data;
  },

  getterPic(state) {
    return state.listPic;
  },

  getterSave(state) {
    return state.statusSave;
  },

  getterPagination(state) {
    return state.activePage;
  },

  getterStartPage(state) {
    return state.startPagination;
  },

  getterEndPage(state) {
    return state.endPagination;
  },

  getterTotalPagination(state) {
    return state.totalPagination;
  }
};

const actions = {
  [LOAD_PIC](context) {
    context.commit(SET_LOADING_PIC, true);

    return new Promise(resolve => {
      ApiService.setHeader();

      ApiService.post("pic/load")
        .then(({ data }) => {
          resolve(data);

          context.commit(SET_LOADING_PIC, false);

          // if (data.data.length > 0) {
          //   data.data.forEach((data) => {
          //     showToast("Error", data, "danger");
          //   });
          // }

          showToast("Success", "PIC Loaded Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response?.data?.errors);

          context.commit(SET_LOADING_PIC, false);

          showToast("Failed", "Failed Load PIC", "danger");
        });
    });

  },

  // [LOAD_PIC](context) {
  //   ApiService.setHeader();

  //   ApiService.post("pic/load")
  //     .then(res => {
  //     context.commit(SET_PIC, res.data.code);
  //     })
  //     .catch(({ response }) => {
  //       context.commit(SET_ERROR, response.data.errors);
  //     });
  // },

  [GET_PIC](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_PIC, true);
      ApiService.query("pic/all", params)
        .then(({ data }) => {
          resolve(data);
          context.commit(SET_PIC, data);
          context.commit(SET_LOADING_PIC, false);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_LOADING_PIC, false);
        });
    });
  },

  async [GET_LIST_PIC]({ commit }, page) {
    await ApiService.query("pic").then(res => {
      var start = page[0].start;
      var end = page[0].end;
      var totalData = res.data.data.length;
      var totalPage = Math.ceil(totalData / 10);
      commit(SET_PAGE, page);
      commit(SHOW_PIC, res.data.data.slice(start, end));
      commit(SET_TOTAL_PAGINATION, totalPage);
    });
  },

  // async [SAVE_PIC](context, data) {
  //   await ApiService.post("pic", data).then(res => {
  //     context.commit(STATUS_SAVE, res.data.code);
  //   });
  // },

  [SAVE_PIC](context, body) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_PIC, true);

      ApiService.setHeader();

      ApiService.post("pic", body)
        .then(({ data }) => {
          context.commit(SET_SAVE_PIC, data?.data);

          resolve(data);

          context.commit(SET_LOADING_PIC, false);

          showToast("Success", "Data Saved Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_PIC, false);

          showToast("Failed", response.data.message, "danger");
        });
    });
  },

  async [EDIT_PIC](context, params) {
    ApiService.setHeader();
    return new Promise(resolve => {
      ApiService.setHeader();
      ApiService.put("pic", params)
        .then(({ data }) => {
          context.commit(SET_SAVE_PIC, data?.data);
          resolve(data);
          showToast("SukSuccess", "Data Updated Successfully");
        })
        .catch(({ response }) => {
          showToast("Failed", response.data.message, "danger");
          resolve(response);
        });
    });
  },

  [SEARCH_PIC](params) {
    ApiService.query("api", params).then(res => {
      console.log(res);
    });
  }
};

const mutations = {
  [SET_PIC](state, pics) {
    state.pics = pics;
  },

  [SET_LOADING_PIC](state, loading) {
    state.loading = loading;
  },

  [SET_ERROR](state, error) {
    state.errors = error;
  },

  [SHOW_PIC](state, data) {
    state.listPic = data;
  },

  [STATUS_SAVE](state, data) {
    state.statusSave = data;
  },

  [SET_PAGE](state, data) {
    var start = data[0].start;
    var end = data[0].end;

    state.activePage = data[0].page;
    state.startPagination = start;
    state.endPagination = end;
  },

  [SET_TOTAL_PAGINATION](state, data) {
    state.totalPagination = data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
