import ApiService from "../api.service";
import { showToast } from "../../helper/toast.helper";

export const GET_DELIVERABLE_DESIGN = "getDeliverableDesign";
export const GET_DL_DESIGN_STATUS = "getDeliverableDesignStatus";
export const GET_DL_DESIGN_DRAFT_PIC = "getDeliverableDesignDraftPic";
export const GET_DL_DESIGN_STATION = "getDeliverableDesignStation";

export const SET_ERROR = "setError";
export const SET_DELIVERABLE_DESIGN = "setDeliverableDesign";
export const SET_LOADING_DELIVERABLE_DESIGN = "setLoadingDeliverableDesign";
export const SET_DL_DESIGN_STATUS = "setDeliverableDesignStatus";
export const SET_DL_DESIGN_DRAFT_PIC = "setDeliverableDesignDraftPic";
export const SET_DL_DESIGN_STATION = "setDeliverableDesignStation";

const state = {
  deliverableDesigns: {},
  deliverableDesignStatus: {},
  deliverableDesignDraftPic: {},
  deliverableDesignStation: {},
  errors: {},
  loading: false,
};

const actions = {
  [GET_DELIVERABLE_DESIGN](context, params) {
    console.log("tu", params);
    return new Promise((resolve) => {
      context.commit(SET_LOADING_DELIVERABLE_DESIGN, true);

      ApiService.query(
        `report/deliverable/design/${params?.code}/project/${params?.latest}/latest`,
        params
      )
        .then((response) => {
          context.commit(SET_DELIVERABLE_DESIGN, response?.data);

          resolve(response?.data);

          context.commit(SET_LOADING_DELIVERABLE_DESIGN, false);

          showToast("Success", "Data Loaded Successfully");
        })
        .catch((response) => {
          context.commit(SET_ERROR, response?.data?.errors);

          context.commit(SET_LOADING_DELIVERABLE_DESIGN, false);

          showToast("Failed", "Failed Load Data", "danger");
        });
    });
  },
  [GET_DL_DESIGN_STATUS](context, params) {
    return new Promise((resolve) => {
      context.commit(SET_LOADING_DELIVERABLE_DESIGN, true);

      ApiService.query(`status`, params)
        .then((response) => {
          context.commit(SET_DL_DESIGN_STATUS, response?.data);

          resolve(response?.data);

          context.commit(SET_LOADING_DELIVERABLE_DESIGN, false);
        })
        .catch((response) => {
          context.commit(SET_ERROR, response?.data?.errors);

          context.commit(SET_LOADING_DELIVERABLE_DESIGN, false);
        });
    });
  },

  [GET_DL_DESIGN_DRAFT_PIC](context, params) {
    return new Promise((resolve) => {
      context.commit(SET_LOADING_DELIVERABLE_DESIGN, true);

      ApiService.query(`pic/all`, params)
        .then((response) => {
          context.commit(SET_DL_DESIGN_DRAFT_PIC, response?.data);

          resolve(response?.data);

          context.commit(SET_LOADING_DELIVERABLE_DESIGN, false);
        })
        .catch((response) => {
          context.commit(SET_ERROR, response?.data?.errors);

          context.commit(SET_LOADING_DELIVERABLE_DESIGN, false);
        });
    });
  },

  [GET_DL_DESIGN_STATION](context, params) {
    return new Promise((resolve) => {
      context.commit(SET_LOADING_DELIVERABLE_DESIGN, true);

      ApiService.query(`station/${params?.code}/project`, params)
        .then((response) => {
          context.commit(SET_DL_DESIGN_STATION, response?.data);

          resolve(response?.data);

          context.commit(SET_LOADING_DELIVERABLE_DESIGN, false);
        })
        .catch((response) => {
          context.commit(SET_ERROR, response?.data?.errors);

          context.commit(SET_LOADING_DELIVERABLE_DESIGN, false);
        });
    });
  },
};

const mutations = {
  [SET_DELIVERABLE_DESIGN](state, deliverableDesigns) {
    state.deliverableDesigns = deliverableDesigns;
  },

  [SET_LOADING_DELIVERABLE_DESIGN](state, loading) {
    state.loading = loading;
  },

  [SET_DL_DESIGN_STATUS](state, data) {
    state.deliverableDesignStatus = data;
  },

  [SET_DL_DESIGN_DRAFT_PIC](state, data) {
    state.deliverableDesignDraftPic = data;
  },

  [SET_DL_DESIGN_STATION](state, data) {
    state.deliverableDesignStation = data;
  },
};

export default {
  state,
  actions,
  mutations,
};
