import ApiService from "../api.service";
import { showToast } from "@/core/helper/toast.helper";

export const GET_CHECKLIST_DISTRIBUTION_DOC = "getChecklistDistributionDoc";
export const GET_CHECKLIST_DISTRIBUTION_DRW = "getChecklistDistributionDrw";
export const GET_CHECKLIST_DISTRIBUTION_FOR = "getChecklistDistributionFor";
export const POST_CHECKLIST_DISTRIBUTION = "postChecklistDistribution";
export const CLEAN_STATE = "cleanState";

export const SET_ERROR = "setError";
export const SET_LOADING = "setLoading";
export const SET_CHECKLIST_DISTRIBUTION_DOC = "setChecklistDistributionDoc";
export const SET_CHECKLIST_DISTRIBUTION_DRW = "setChecklistDistributionDrw";
export const SET_CHECKLIST_DISTRIBUTION_FOR = "setChecklistDistributionFor";
export const SET_RESULT_POST = "setResultPost";

const state = {
  ChecklistDistributionDoc: {
    data: [],
    code: null,
    message: null,
  },
  ChecklistDistributionDrw: {
    data: [],
    code: null,
    message: null,
  },
  ChecklistDistributionFor: {
    data: [],
    code: null,
    message: null,
  },
  loading: false,
  errors: {},
  resultPost: {
    data: [],
    code: null,
    message: null,
  },
};

const getters = {
  loadingDesign(state) {
    return state.loading;
  },

  getterChecklistDistributionDoc(state) {
    return state.ChecklistDistributionDoc?.data?.dld;
  },

  getterChecklistDistributionFor(state) {
    return state.ChecklistDistributionFor?.data?.dld;
  },

  getterChecklistDistributionDrw(state) {
    return state.ChecklistDistributionDrw?.data?.dld;
  },
};

const actions = {
  [CLEAN_STATE](context, params) {
    context.commit(SET_LOADING, true);
    if (params === "DOC") {
      context.commit(SET_CHECKLIST_DISTRIBUTION_DOC, []);
    } else if (params === "DRW") {
      context.commit(SET_CHECKLIST_DISTRIBUTION_DRW, []);
    } else if (params === "FOR") {
      context.commit(SET_CHECKLIST_DISTRIBUTION_FOR, []);
    }

    context.commit(SET_LOADING, false);
  },
  [GET_CHECKLIST_DISTRIBUTION_DOC](context, params) {
    return new Promise((resolve, reject) => {
      context.commit(SET_LOADING, true);
      ApiService.setHeader();
      ApiService.query(
        `project/${params.project_id}/DOC/dld/distribution/check`,
        params
      )
        .then(({ data }) => {
          resolve(data);
          context.commit(SET_CHECKLIST_DISTRIBUTION_DOC, data);
          context.commit(SET_LOADING, false);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_CHECKLIST_DISTRIBUTION_DRW](context, params) {
    return new Promise((resolve, reject) => {
      context.commit(SET_LOADING, true);
      ApiService.setHeader();
      ApiService.query(
        `project/${params.project_id}/DRW/dld/distribution/check`,
        params
      )
        .then(({ data }) => {
          context.commit(SET_CHECKLIST_DISTRIBUTION_DRW, data);
          resolve(data);
          context.commit(SET_LOADING, false);
        })
        .catch(({ response }) => {
          context.commit(SET_LOADING, false);
          reject(response);
          context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_LOADING, false);

        });
    });
  },
  [GET_CHECKLIST_DISTRIBUTION_FOR](context, params) {
    return new Promise((resolve, reject) => {
      context.commit(SET_LOADING, true);
      ApiService.setHeader();
      ApiService.query(
        `project/${params.project_id}/FOR/dld/distribution/check`,
        params
      )
        .then(({ data }) => {
          context.commit(SET_LOADING, false);
          context.commit(SET_CHECKLIST_DISTRIBUTION_FOR, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_LOADING, false);
          reject(response);
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  [POST_CHECKLIST_DISTRIBUTION](context, param) {
    return new Promise((resolve) => {
      ApiService.setHeader();
      context.commit(SET_LOADING, true);
      ApiService.post("project/dld/distribution/check", param)
        .then(({ data }) => {
          context.commit(SET_RESULT_POST, data);
          resolve(data);
          context.commit(SET_LOADING, false);

          showToast(
            "Success",
            "success save checklist distribution",
            "success"
          );
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_LOADING, false);

          showToast("Failed", "failed save checklist distribution", "danger");
        });
    });
  },
};

const mutations = {
  [SET_RESULT_POST](state, ChecklistDistribution) {
    state.resultPost = ChecklistDistribution;
  },

  [SET_ERROR](state, error) {
    state.errors = error;
  },

  [SET_LOADING](state, loading) {
    state.loading = loading;
  },

  [SET_CHECKLIST_DISTRIBUTION_DRW](state, ChecklistDistributionDrw) {
    state.ChecklistDistributionDrw = ChecklistDistributionDrw;
  },

  [SET_CHECKLIST_DISTRIBUTION_FOR](state, ChecklistDistributionFor) {
    state.ChecklistDistributionFor = ChecklistDistributionFor;
  },

  [SET_CHECKLIST_DISTRIBUTION_DOC](state, ChecklistDistributionDoc) {
    state.ChecklistDistributionDoc = ChecklistDistributionDoc;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
