import ApiService from "../api.service";

export const GET_MENU = "getMenu";
export const GET_MENU_ALL = "getMenuAll";

export const SET_ERROR = "setError";
export const SET_MENU = "setMenu";
export const SET_MENU_ALL = "setMenuAll";

const state = {
  menus: {
    data: [],
    code: null,
    message: null
  },
  menu_all: {
    data: [],
    code: null,
    message: null
  },
  errors: {}
};

const getters = {
  currentMenus(state) {
    return state.menus.data;
  },

  currentMenuAll(state) {
    return state.menu_all.data;
  }
};

const actions = {
  [GET_MENU](context, payload) {
    return new Promise(resolve => {
      ApiService.setHeaderWithContent();
      ApiService.query("menu", payload)
        .then(({ data }) => {
          context.commit(SET_MENU, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          //console.log(response)
        });
    });
  },

  [GET_MENU_ALL](context, payload) {
    return new Promise(resolve => {
      ApiService.setHeaderWithContent();
      ApiService.query("menu/all", payload)
        .then(({ data }) => {
          context.commit(SET_MENU_ALL, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  }
};

const mutations = {
  [SET_MENU](state, menus) {
    state.menus = menus;
    state.errors = {};
  },

  [SET_ERROR](state, error) {
    state.errors = error;
  },

  [SET_MENU_ALL](state, data) {
    state.menu_all = data;
    state.errors = {};
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
