import ApiService from "../api.service";
import { showToast } from "../../helper/toast.helper";

export const GET_DIVISION = "getDivision";
export const DELETE_DIVISION = "deleteDivision";
export const SAVE_DIVISION = "saveDivision";
export const UPDATE_DIVISION = "updateDivision";

export const SET_ERROR = "setError";
export const SET_DIVISION = "setDivision";
export const SET_DELETE_DIVISION = "setDeleteDivision";
export const SET_SAVE_DIVISION = "setSaveDivision";
export const SET_UPDATE_DIVISION = "setUpdateDivision";
export const SET_LOADING_DIVISION = "setLoadingDivision";

const state = {
  divisions: {},
  errors: {},
  loading: false
};

const actions = {
  [GET_DIVISION](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_DIVISION, true);

      ApiService.setHeader();

      ApiService.query("division", params)
        .then(({ data }) => {
          context.commit(SET_DIVISION, data);

          resolve(data);

          context.commit(SET_LOADING_DIVISION, false);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_DIVISION, false);
        });
    });
  },

  [DELETE_DIVISION](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_DIVISION, true);

      ApiService.setHeader();

      ApiService.delete(`division/${params.code}/delete`)
        .then(({ data }) => {
          context.commit(SET_DELETE_DIVISION, params.code);

          resolve(data);

          context.commit(SET_LOADING_DIVISION, false);

          showToast("Success", "Data Deleted Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_DIVISION, false);

          showToast("Failed", "Failed Delete Data", "danger");
        });
    });
  },

  [SAVE_DIVISION](context, body) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_DIVISION, true);

      ApiService.setHeader();

      ApiService.post("division", body)
        .then(({ data }) => {
          context.commit(SET_SAVE_DIVISION, data?.data);

          resolve(data);

          context.commit(SET_LOADING_DIVISION, false);

          showToast("Success", "Data Saved Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_DIVISION, false);

          showToast("Failed", "Failed Save Data", "danger");
        });
    });
  },

  [UPDATE_DIVISION](context, body) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_DIVISION, true);

      ApiService.setHeader();

      ApiService.put("division", body)
        .then(({ data }) => {
          context.commit(SET_UPDATE_DIVISION, data?.data);

          resolve(data);

          context.commit(SET_LOADING_DIVISION, false);

          showToast("Success", "Data Updated Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_DIVISION, false);

          showToast("Failed", "Failed Update Data", "danger");
        });
    });
  }
};

const mutations = {
  [SET_DIVISION](state, divisions) {
    state.divisions = divisions;
  },

  [SET_SAVE_DIVISION](state, division) {
    state.divisions.data = [division, ...state.divisions.data];
  },

  [SET_LOADING_DIVISION](state, loading) {
    state.loading = loading;
  },

  [SET_UPDATE_DIVISION](state, updateDivision) {
    const index = state.divisions?.data?.findIndex(
      division => division.code === updateDivision.code
    );

    if (index >= 0) {
      Object.assign(state.divisions?.data[index], updateDivision);
    }
  },

  [SET_DELETE_DIVISION](state, code) {
    const index = state.divisions?.data?.findIndex(
      division => division.code === code
    );

    if (index >= 0) {
      state.divisions?.data?.splice(index, 1);
    }
  },

  [SET_ERROR](state, error) {
    state.errors = error;
  }
};

export default {
  state,
  actions,
  mutations
};
