import ApiService from "../api.service";
import { showToast } from "../../helper/toast.helper";

export const GET_REPORTING_MAN_HOUR = "getReportingManHour";
export const GET_REPORTING_MAN_HOUR_ATTACHMENT =
  "getReportingManHourAttachment";

export const SET_ERROR = "setError";
export const SET_REPORTING_MAN_HOUR = "setReportingManHour";
export const SET_REPORTING_MAN_HOUR_ATTACHMENT =
  "setReportingManHourAttachment";
export const SET_LOADING_REPORTING_MAN_HOUR = "setLoadingReportingManHour";

const state = {
  manHours: {},
  manHourAttachments: {},
  errors: {},
  loading: false
};

const actions = {
  [GET_REPORTING_MAN_HOUR](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_REPORTING_MAN_HOUR, true);

      ApiService.query(`report/man/hour/A/${params?.code}/project`, params)
        .then(response => {
          context.commit(SET_REPORTING_MAN_HOUR, response?.data);

          resolve(response?.data);

          context.commit(SET_LOADING_REPORTING_MAN_HOUR, false);

          showToast("Success", "Data Loaded Successfully");
        })
        .catch(response => {
          context.commit(SET_ERROR, response?.data?.errors);

          context.commit(SET_LOADING_REPORTING_MAN_HOUR, false);

          showToast("Failed", "Failed Load Data", "danger");
        });
    });
  },

  [GET_REPORTING_MAN_HOUR_ATTACHMENT](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_REPORTING_MAN_HOUR, true);

      ApiService.query(`report/man/hour/B/${params?.code}/project`, params)
        .then(response => {
          context.commit(SET_REPORTING_MAN_HOUR_ATTACHMENT, response?.data);

          resolve(response?.data);

          context.commit(SET_LOADING_REPORTING_MAN_HOUR, false);

          showToast("Success", "Data Loaded Successfully");
        })
        .catch(response => {
          context.commit(SET_ERROR, response?.data?.errors);

          context.commit(SET_LOADING_REPORTING_MAN_HOUR, false);

          showToast("Failed", "Failed Load Data", "danger");
        });
    });
  }
};

const mutations = {
  [SET_REPORTING_MAN_HOUR](state, manHours) {
    state.manHours = manHours;
  },

  [SET_REPORTING_MAN_HOUR_ATTACHMENT](state, manHours) {
    state.manHourAttachments = manHours;
  },

  [SET_LOADING_REPORTING_MAN_HOUR](state, loading) {
    state.loading = loading;
  }
};

export default {
  state,
  actions,
  mutations
};
