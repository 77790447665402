import ApiService from "../api.service";

export const GET_COUNTER_PROJECT = "getCounterProject";
export const GET_COUNTER_DMP = "getCounterDMP";
export const GET_COUNTER_MOM = "getCounterMOM";
export const GET_COUNTER_CR = "getCounterCR";
export const GET_COUNTER_PLAN = "getCounterPlan";
export const GET_DASHBOARD_TARGET_OVERVIEW = "getDashboardTargetOverview";
export const GET_DASHBOARD_TARGET_PLAN = "getDashboardTargetPlan";
export const GET_DASHBOARD_LOG_ACTIVITY = "getDashboardLogActivity";
export const GET_DASHBOARD_DETAIL_TARGET_OVERVIEW =
  "getDashboardDetailTargetOverview";
export const GET_DASHBOARD_URGENT = "getDashboardUrgent";
export const GET_COUNTER_UPLOAD_SERVER = "getCounterUploadServer";
export const GET_COUNTER_DESIGN_UPDATE = "getCounterDesignUpdated";

export const GET_PROGRESS_TYPE = "getProgressType";
export const GET_PROGRESS_CATEGORY = "getProgressCategory";
export const GET_PROGRESS_OVERVIEW = "getProgressOverview";
export const GET_PROGRESS_OVERVIEW_USER = "getProgressOverviewUser";

export const SET_ERROR = "setError";
export const SET_COUNTER_PROJECT = "setCounterProject";
export const SET_COUNTER_PROJECT_LIST = "setCounterProjectList";
export const SET_COUNTER_DMP = "setCounterDMP";
export const SET_COUNTER_MOM = "setCounterMOM";
export const SET_COUNTER_CR = "setCounterCR";
export const SET_COUNTER_PLAN = "setCounterPlan";
export const SET_DASHBOARD_TARGET_OVERVIEW = "setTargetOverview";
export const SET_DASHBOARD_DETAIL_TARGET_OVERVIEW = "setDetailTargetOverview";
export const SET_DASHBOARD_TARGET_PLAN = "setTargetPlan";
export const SET_DASHBOARD_PROGRESS_TYPE = "setProgressType";
export const SET_DASHBOARD_PROGRESS_CATEGORY = "setProgressCategory";
export const SET_DASHBOARD_PROGRESS_OVERVIEW = "setProgressOverview";
export const SET_DASHBOARD_PROGRESS_OVERVIEW_USER = "setProgressOverviewUser";
export const SET_DASHBOARD_LOG_ACTIVITY = "setDashboardLogActivity";
export const SET_DASHBOARD_URGENT = "setDashboardUrgent";
export const SET_COUNTER_UPLOAD_SERVER = "setCounterUploadServer";
export const SET_COUNTER_DESIGN_UPDATE = "setCounterDesignUpdated";

export const SET_LOADING_COUNTER_PROJECT = "setLoadingCounterProject";
export const SET_LOADING = "setLoading";

const state = {
  CounterProject: {},
  CounterDMP: {},
  CounterMOM: {},
  CounterCR: {},
  CounterPlan: {},
  CounterUploadServer: {},
  CounterDesignUpdated: {},
  targetOverview: {
    data: [],
    code: null,
    message: null
  },
  targetPlan: {
    data: [],
    code: null,
    message: null
  },
  progressType: {
    data: [],
    code: null,
    message: null
  },
  progressCategory: {
    data: [],
    code: null,
    message: null
  },
  progressOverview: {
    data: [],
    code: null,
    message: null
  },
  progressOverviewUser: {
    data: [],
    code: null,
    message: null
  },
  dashboardLogActivity: {
    data: [],
    code: null,
    message: null
  },
  dashboardDetailTarget: {
    data: [],
    code: null,
    message: null
  },
  dashboardUrgent: {
    data: [],
    code: null,
    message: null
  },
  errors: {},
  loading: false
};

const getters = {
  currentDashboardUrgent(state) {
    return state.dashboardUrgent?.data;
  },

  currentDashboardLogActivity(state) {
    return state.dashboardLogActivity?.data;
  },

  currentCounterProject(state) {
    return state.CounterProject?.data;
  },

  currentCounterDMP(state) {
    return state.CounterDMP?.data;
  },

  currentCounterMOM(state) {
    return state.CounterMOM?.data;
  },

  currentCounterCR(state) {
    return state.CounterCR?.data;
  },

  currentCounterPlan(state) {
    return state.CounterPlan?.data;
  },

  currentCounterDesignUpdated(state) {
    return state.CounterDesignUpdated?.data;
  },

  currentTargetOverview(state) {
    return state.targetOverview?.data;
  },

  currentTargetPlan(state) {
    return state.targetPlan?.data;
  },

  currentProgressType(state) {
    return state.progressType?.data;
  },

  currentProgressCategory(state) {
    return state.progressCategory?.data;
  },

  currentProgressOverview(state) {
    return state.progressOverview?.data;
  },

  currentProgressOverviewUser(state) {
    return state.progressOverviewUser?.data;
  },

  currentDetailTargetOverview(state) {
    return state.dashboardDetailTarget?.data;
  },

  currentCounterUploadServer(state) {
    return state.CounterUploadServer?.data;
  }
};

const actions = {
  [GET_COUNTER_PROJECT](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_COUNTER_PROJECT, true);
      ApiService.query(`counter/project`, params)
        .then(({ data }) => {
          context.commit(SET_COUNTER_PROJECT, data);
          resolve(data);
          context.commit(SET_LOADING_COUNTER_PROJECT, false);
        })
        .catch(response => {
          context.commit(SET_ERROR, response?.data?.errors);
          context.commit(SET_LOADING_COUNTER_PROJECT, false);
        });
    });
  },

  [GET_COUNTER_DMP](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_COUNTER_PROJECT, true);
      ApiService.query(`counter/dmp`, params)
        .then(({ data }) => {
          context.commit(SET_COUNTER_DMP, data);
          resolve(data);
          context.commit(SET_LOADING_COUNTER_PROJECT, false);
        })
        .catch(response => {
          context.commit(SET_ERROR, response?.data?.errors);
          context.commit(SET_LOADING_COUNTER_PROJECT, false);
        });
    });
  },

  [GET_COUNTER_MOM](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_COUNTER_PROJECT, true);
      ApiService.query(`counter/mom`, params)
        .then(({ data }) => {
          context.commit(SET_COUNTER_MOM, data);
          resolve(data);
          context.commit(SET_LOADING_COUNTER_PROJECT, false);
        })
        .catch(response => {
          context.commit(SET_ERROR, response?.data?.errors);
          context.commit(SET_LOADING_COUNTER_PROJECT, false);
        });
    });
  },

  [GET_COUNTER_CR](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_COUNTER_PROJECT, true);
      ApiService.query(`counter/cr`, params)
        .then(({ data }) => {
          context.commit(SET_COUNTER_CR, data);
          resolve(data);
          context.commit(SET_LOADING_COUNTER_PROJECT, false);
        })
        .catch(response => {
          context.commit(SET_ERROR, response?.data?.errors);
          context.commit(SET_LOADING_COUNTER_PROJECT, false);
        });
    });
  },

  [GET_COUNTER_PLAN](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_COUNTER_PROJECT, true);
      ApiService.query(`counter/plan`, params)
        .then(({ data }) => {
          context.commit(SET_COUNTER_PLAN, data);
          resolve(data);
          context.commit(SET_LOADING_COUNTER_PROJECT, false);
        })
        .catch(response => {
          context.commit(SET_ERROR, response?.data?.errors);
          context.commit(SET_LOADING_COUNTER_PROJECT, false);
        });
    });
  },

  [GET_COUNTER_DESIGN_UPDATE](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_COUNTER_PROJECT, true);
      ApiService.query(`counter/design/updated`, params)
        .then(({ data }) => {
          context.commit(SET_COUNTER_DESIGN_UPDATE, data);
          resolve(data);
          context.commit(SET_LOADING_COUNTER_PROJECT, false);
        })
        .catch(response => {
          context.commit(SET_ERROR, response?.data?.errors);
          context.commit(SET_LOADING_COUNTER_PROJECT, false);
        });
    });
  },

  [GET_DASHBOARD_TARGET_OVERVIEW](context, params) {
    return new Promise((resolve, reject) => {
      context.commit(SET_LOADING, true);
      ApiService.setHeader();
      ApiService.get("target", `${params}/project`)
        .then(({ data }) => {
          context.commit(SET_DASHBOARD_TARGET_OVERVIEW, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_LOADING, false);
        });
    });
  },

  [GET_DASHBOARD_TARGET_PLAN](context, params) {
    return new Promise((resolve, reject) => {
      context.commit(SET_LOADING, true);
      ApiService.setHeader();
      ApiService.get("target", `${params}/plan`)
        .then(({ data }) => {
          context.commit(SET_DASHBOARD_TARGET_PLAN, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_LOADING, false);
        });
    });
  },

  [GET_PROGRESS_TYPE](context, params) {
    return new Promise((resolve, reject) => {
      context.commit(SET_LOADING, true);
      ApiService.setHeader();
      ApiService.get("type", `${params}/project`)
        .then(({ data }) => {
          context.commit(SET_DASHBOARD_PROGRESS_TYPE, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_LOADING, false);
        });
    });
  },

  [GET_PROGRESS_CATEGORY](context, params) {
    return new Promise((resolve, reject) => {
      context.commit(SET_LOADING, true);
      ApiService.setHeader();
      ApiService.get("category", `${params}/project`)
        .then(({ data }) => {
          context.commit(SET_DASHBOARD_PROGRESS_CATEGORY, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_LOADING, false);
        });
    });
  },

  [GET_PROGRESS_OVERVIEW](context, params) {
    return new Promise((resolve, reject) => {
      context.commit(SET_LOADING, true);
      ApiService.setHeader();
      ApiService.get("progress", `${params}/project`)
        .then(({ data }) => {
          context.commit(SET_DASHBOARD_PROGRESS_OVERVIEW, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_LOADING, false);
        });
    });
  },

  [GET_PROGRESS_OVERVIEW_USER](context, params) {
    return new Promise((resolve, reject) => {
      context.commit(SET_LOADING, true);
      ApiService.setHeader();
      ApiService.get("progress", `${params}/project/user`)
        .then(({ data }) => {
          context.commit(SET_DASHBOARD_PROGRESS_OVERVIEW_USER, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_LOADING, false);
        });
    });
  },

  [GET_DASHBOARD_URGENT](context, params) {
    return new Promise((resolve, reject) => {
      context.commit(SET_LOADING, true);
      ApiService.setHeader();
      ApiService.get(
        "target",
        `${params.code_project}/project/${params.pic_code}/pic`
      )
        .then(({ data }) => {
          context.commit(SET_DASHBOARD_URGENT, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_LOADING, false);
        });
    });
  },

  [GET_DASHBOARD_LOG_ACTIVITY](context, params) {
    return new Promise((resolve, reject) => {
      context.commit(SET_LOADING, true);
      ApiService.setHeader();
      ApiService.get("logs", `${params}/list`)
        .then(({ data }) => {
          context.commit(SET_DASHBOARD_LOG_ACTIVITY, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_LOADING, false);
        });
    });
  },

  [GET_DASHBOARD_DETAIL_TARGET_OVERVIEW](context, params) {
    return new Promise((resolve, reject) => {
      context.commit(SET_LOADING, true);
      ApiService.setHeader();
      ApiService.query(`target/overview/detail`, params)
        .then(({ data }) => {
          context.commit(SET_LOADING, false);
          context.commit(SET_DASHBOARD_DETAIL_TARGET_OVERVIEW, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_LOADING, false);
          reject(response);
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  [GET_COUNTER_UPLOAD_SERVER](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_COUNTER_PROJECT, true);
      ApiService.query(`counter/server/uploaded`, params)
        .then(({ data }) => {
          context.commit(SET_COUNTER_UPLOAD_SERVER, data);
          resolve(data);
          context.commit(SET_LOADING_COUNTER_PROJECT, false);
        })
        .catch(response => {
          context.commit(SET_ERROR, response?.data?.errors);
          context.commit(SET_LOADING_COUNTER_PROJECT, false);
        });
    });
  }
};

const mutations = {
  [SET_DASHBOARD_URGENT](state, data) {
    state.dashboardUrgent = data;
  },

  [SET_COUNTER_PROJECT](state, data) {
    state.CounterProject = data;
  },

  [SET_COUNTER_DMP](state, data) {
    state.CounterDMP = data;
  },

  [SET_COUNTER_MOM](state, data) {
    state.CounterMOM = data;
  },

  [SET_COUNTER_CR](state, data) {
    state.CounterCR = data;
  },

  [SET_COUNTER_PLAN](state, data) {
    state.CounterPlan = data;
  },

  [SET_COUNTER_DESIGN_UPDATE](state, data) {
    state.CounterDesignUpdated = data;
  },

  [SET_LOADING_COUNTER_PROJECT](state, loading) {
    state.loading = loading;
  },

  [SET_DASHBOARD_TARGET_OVERVIEW](state, data) {
    state.targetOverview = data;
  },

  [SET_LOADING](state, loading) {
    state.loading = loading;
  },

  [SET_DASHBOARD_TARGET_PLAN](state, data) {
    state.targetPlan = data;
  },

  [SET_DASHBOARD_PROGRESS_TYPE](state, data) {
    state.progressType = data;
  },

  [SET_DASHBOARD_PROGRESS_CATEGORY](state, data) {
    state.progressCategory = data;
  },

  [SET_DASHBOARD_PROGRESS_OVERVIEW](state, data) {
    state.progressOverview = data;
  },

  [SET_DASHBOARD_PROGRESS_OVERVIEW_USER](state, data) {
    state.progressOverviewUser = data;
  },

  [SET_DASHBOARD_LOG_ACTIVITY](state, data) {
    state.dashboardLogActivity = data;
  },

  [SET_DASHBOARD_DETAIL_TARGET_OVERVIEW](state, data) {
    state.dashboardDetailTarget = data;
  },

  [SET_COUNTER_UPLOAD_SERVER](state, data) {
    state.CounterUploadServer = data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
