import ApiService from "../api.service";
import { showToast } from "../../helper/toast.helper";

export const CLEAN_STATE = "cleanState";
export const GET_USERS = "getUsers";
export const POST_USER = "postUsers";
export const PUT_USER = "putUsers";
export const DELETE_USER = "deleteUsers";
export const GET_USER_DETAIL_BY_ID = "getUserDetail";
export const POST_CHANGE_PASSWORD = "postChangePassword";
export const POST_UPLOAD_AVATAR = "postUploadAvatar";

export const SET_ERROR = "setError";
export const SET_LOADING = "setLoading";
export const SET_USERS = "setUsers";
export const SET_POST_USER = "setPostUser";
export const SET_PUT_USER = "setPutUser";
export const SET_DELETE_USER = "setDeleteUser";
export const SET_USER_DETAIL = "setUserDetail";
export const SET_POST_CHANGE_PASSWORD = "setPostChangePassword";

const state = {
  users: {
    data: [],
    code: null,
    message: null
  },
  userDetail: {
    data: null,
    code: null,
    message: null
  },
  loading: false,
  errors: {}
};

const getters = {
  currentUserPersonal(state){
    return state.userDetail?.data?.pic;
  },
  currentUserDetail(state) {
    return state.userDetail?.data;
  },
  currentUsers(state) {
    return state.users?.data;
  },

  currentUserLoading(state) {
    return state.loading;
  }
};

const actions = {
  [GET_USERS](context, params) {
    return new Promise((resolve, reject) => {
      context.commit(SET_LOADING, true);
      ApiService.setHeader();
      ApiService.query("user", params)
        .then(({ data }) => {
          resolve(data);
          context.commit(SET_USERS, data);
          context.commit(SET_LOADING, false);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_LOADING, false);
        });
    });
  },

  [POST_USER](context, body) {
    return new Promise(resolve => {
      context.commit(SET_LOADING, true);
      ApiService.setHeader();
      ApiService.post("user", body)
        .then(({ data }) => {
          context.commit(SET_POST_USER, data?.data);
          resolve(data);
          context.commit(SET_LOADING, false);
          showToast("Success", "Data Saved Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_LOADING, false);
          showToast("Failed", "Failed Save Data", "danger");
        });
    });
  },

  [POST_CHANGE_PASSWORD](context, body) {
    return new Promise(resolve => {
      context.commit(SET_LOADING, true);
      ApiService.setHeader();
      ApiService.post("user/change/password", body)
        .then(({ data }) => {
          context.commit(SET_POST_CHANGE_PASSWORD, data?.data);
          resolve(data);
          context.commit(SET_LOADING, false);
          showToast("Success", "Password Changed Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_LOADING, false);
          showToast("Failed", "Failed Change Password", "danger");
        });
    });
  },

  [PUT_USER](context, body) {
    return new Promise(resolve => {
      context.commit(SET_LOADING, true);
      ApiService.setHeader();
      ApiService.put("user", body)
        .then(({ data }) => {
          context.commit(SET_PUT_USER, data?.data);
          resolve(data);
          context.commit(SET_LOADING, false);
          showToast("Success", "Data Updated Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_LOADING, false);
          showToast("Failed", "Failed Update Data", "danger");
        });
    });
  },

  [DELETE_USER](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING, true);
      ApiService.setHeader();
      ApiService.delete(`user/${params.id}/delete`)
        .then(({ data }) => {
          context.commit(SET_DELETE_USER, params.id);
          resolve(data);
          context.commit(SET_LOADING, false);
          showToast("Success", "Data Deleted Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_LOADING, false);
          showToast("Failed", "Failed Delete Data", "danger");
        });
    });
  },

  [GET_USER_DETAIL_BY_ID](context, params) {
    return new Promise(resolve => {
      ApiService.setHeader();
      ApiService.get("user", `${params}/detail`)
        .then(({ data }) => {
          context.commit(SET_USER_DETAIL, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  [POST_UPLOAD_AVATAR](context, files) {
    return new Promise((resolve, reject) => {
      ApiService.setHeaderMultiPlatform();
      ApiService.post("pic/avatar", files)
        .then(({ data }) => {
          context.commit(SET_POST_USER, data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },

  [SET_LOADING](state, loading) {
    state.loading = loading;
  },

  [SET_USERS](state, data) {
    state.users = data;
  },

  [SET_USER_DETAIL](state, data) {
    state.userDetail = data;
  },

  [SET_POST_USER](state, data) {
    state.users.data = [data, ...state.users.data];
  },

  [SET_POST_CHANGE_PASSWORD](state, data) {
    state.users.data = [data, ...state.users.data];
  },

  [SET_PUT_USER](state, updateData) {
    const index = state.users?.data?.findIndex(
      user => user.id === updateData.id
    );

    if (index >= 0) {
      Object.assign(state.users?.data[index], updateData);
    }
  },
  [SET_DELETE_USER](state, id) {
    const index = state.users?.data?.findIndex(user => user.id === id);

    if (index >= 0) {
      state.users?.data?.splice(index, 1);
    }
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
