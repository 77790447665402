import { app } from "../../main";
import Swal from "sweetalert2";

export function showToast(
  title = "",
  body = "",
  type = "success",
  customProperties = {}
) {
  app.$bvToast.toast(body, {
    title: title,
    variant: type,
    solid: true,
    ...customProperties
  });
}

export function deleteModal(deleteFunction) {
  Swal.fire({
    title: "Are you sure?",
    text: "You will not be able to recover this data",
    icon: "warning",
    showCancelButton: true,
    cancelButtonText: "Batal",
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Ya",
    allowOutsideClick: false
  }).then(result => {
    if (result.isConfirmed) {
      deleteFunction();
    }
  });
}
