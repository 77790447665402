import ApiService from "../api.service";
import { showToast } from "../../helper/toast.helper";

export const GET_POSITION = "getPosition";
export const DELETE_POSITION = "deletePosition";
export const SAVE_POSITION = "savePosition";
export const UPDATE_POSITION = "updatePosition";

export const SET_ERROR = "setError";
export const SET_POSITION = "setPosition";
export const SET_DELETE_POSITION = "setDeletePosition";
export const SET_SAVE_POSITION = "setSavePosition";
export const SET_UPDATE_POSITION = "setUpdatePosition";
export const SET_LOADING_POSITION = "setLoadingPosition";

const state = {
  positions: {},
  errors: {},
  loading: false
};

const actions = {
  [GET_POSITION](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_POSITION, true);

      ApiService.query("position", params)
        .then(({ data }) => {
          context.commit(SET_POSITION, data);

          resolve(data);

          context.commit(SET_LOADING_POSITION, false);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_POSITION, false);
        });
    });
  },

  [DELETE_POSITION](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_POSITION, true);

      ApiService.setHeader();

      ApiService.delete(`position/${params.code}/delete`)
        .then(({ data }) => {
          context.commit(SET_DELETE_POSITION, params.code);

          resolve(data);

          context.commit(SET_LOADING_POSITION, false);

          showToast("Success", "Data Deleted Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_POSITION, false);

          showToast("Failed", "Failed Delete Data", "danger");
        });
    });
  },

  [SAVE_POSITION](context, body) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_POSITION, true);

      ApiService.setHeader();

      ApiService.post("position", body)
        .then(({ data }) => {
          context.commit(SET_SAVE_POSITION, data?.data);

          resolve(data);

          context.commit(SET_LOADING_POSITION, false);

          showToast("Success", "Data Saved Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_POSITION, false);

          showToast("Failed", "Failed Save Data", "danger");
        });
    });
  },

  [UPDATE_POSITION](context, body) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_POSITION, true);

      ApiService.setHeader();

      ApiService.put("position", body)
        .then(({ data }) => {
          context.commit(SET_UPDATE_POSITION, data?.data);

          resolve(data);

          context.commit(SET_LOADING_POSITION, false);

          showToast("Success", "Data Updated Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_POSITION, false);

          showToast("Failed", "Failed Update Data", "danger");
        });
    });
  }
};

const mutations = {
  [SET_POSITION](state, positions) {
    state.positions = positions;
  },

  [SET_ERROR](state, error) {
    state.errors = error;
  },

  [SET_SAVE_POSITION](state, position) {
    state.positions.data = [position, ...state.positions.data];
  },

  [SET_LOADING_POSITION](state, loading) {
    state.loading = loading;
  },

  [SET_UPDATE_POSITION](state, updateStation) {
    const index = state.positions?.data?.findIndex(
      position => position.code === updateStation.code
    );

    if (index >= 0) {
      Object.assign(state.positions?.data[index], updateStation);
    }
  },

  [SET_DELETE_POSITION](state, code) {
    const index = state.positions?.data?.findIndex(
      position => position.code === code
    );

    if (index >= 0) {
      state.positions?.data?.splice(index, 1);
    }
  }
};

export default {
  state,
  actions,
  mutations
};
