import ApiService from "../api.service";

export const GET_REPORTING_CHANGE_MANAGEMENT =
  "getReportingChangeManagementReporting";
export const GET_CHANGE_MANAGEMENT = "getChangeManagementReporting";
export const GET_CHANGE_MANAGEMENT_DETAIL =
  "getChangeManagementReportingDetail";
export const SET_CHANGE_MANAGEMENT_DETAIL =
  "setChangeManagementReportingDetail";
export const SET_REPORTING_CHANGE_MANAGEMENT =
  "setReportingChangeManagementReporting";
export const SET_CHANGE_MANAGEMENT = "setChangeManagementReporting";
export const SET_REPORTING_CHANGE_MANAGEMENT_ERROR =
  "setReportingChangeManagementReportingError";
export const SET_LOADING_REPORTING_CHANGE_MANAGEMENT =
  "setLoadingReportingChangeManagementReporting";

const state = {
  loading: false,
  errors: {},
  reportingChangeManagements: {},
  changeManagements: {},
  changeManagementDetail: {}
};

const actions = {
  [GET_CHANGE_MANAGEMENT](context, params) {
    return new Promise((resolve, reject) => {
      context.commit(SET_LOADING_REPORTING_CHANGE_MANAGEMENT, true);

      ApiService.setHeader();

      ApiService.query(
        `project/${params.params.job_desk}/change/management`,
        params
      )
        .then(({ data }) => {
          resolve(data);

          context.commit(SET_CHANGE_MANAGEMENT, data);

          context.commit(SET_LOADING_REPORTING_CHANGE_MANAGEMENT, false);
        })
        .catch(({ response }) => {
          reject(response);

          context.commit(
            SET_REPORTING_CHANGE_MANAGEMENT_ERROR,
            response.data.errors
          );

          context.commit(SET_LOADING_REPORTING_CHANGE_MANAGEMENT, false);
        });
    });
  },

  [GET_CHANGE_MANAGEMENT_DETAIL](context, params) {
    return new Promise((resolve, reject) => {
      context.commit(SET_LOADING_REPORTING_CHANGE_MANAGEMENT, true);

      ApiService.setHeader();

      ApiService.query(
        `report/change/management/${params.params.changeManagementId}/detail`,
        params
      )
        .then(({ data }) => {
          resolve(data);

          context.commit(SET_CHANGE_MANAGEMENT_DETAIL, data);

          context.commit(SET_LOADING_REPORTING_CHANGE_MANAGEMENT, false);
        })
        .catch(({ response }) => {
          reject(response);

          context.commit(
            SET_REPORTING_CHANGE_MANAGEMENT_ERROR,
            response.data.errors
          );

          context.commit(SET_LOADING_REPORTING_CHANGE_MANAGEMENT, false);
        });
    });
  }
};

const mutations = {
  [SET_CHANGE_MANAGEMENT](state, data) {
    state.changeManagements = data;
  },

  [SET_REPORTING_CHANGE_MANAGEMENT](state, data) {
    state.reportingChangeManagements = data;
  },

  [SET_CHANGE_MANAGEMENT_DETAIL](state, data) {
    state.changeManagementDetail = data;
  },

  [SET_LOADING_REPORTING_CHANGE_MANAGEMENT](state, loading) {
    state.loading = loading;
  }
};

export default {
  state,
  actions,
  mutations
};
