import ApiService from "../api.service";
import { showToast } from "../../helper/toast.helper";

export const GET_REPORT_DOCUMENT_SITE_PROJECT = "getReportDocumentSiteProject";

export const SET_ERROR = "setError";
export const SET_REPORT_DOCUMENT_SITE_PROJECT = "setReportDocumentSiteProject";
export const SET_LOADING_REPORT_DOCUMENT_SITE_PROJECT =
  "setLoadingReportDocumentSiteProject";

const state = {
  reportDocumentSiteProjects: {},
  errors: {},
  loading: false
};

const actions = {
  [GET_REPORT_DOCUMENT_SITE_PROJECT](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_REPORT_DOCUMENT_SITE_PROJECT, true);

      ApiService.query(`report/document/site/${params?.code}/project`, params)
        .then(response => {
          context.commit(SET_REPORT_DOCUMENT_SITE_PROJECT, response?.data);

          resolve(response?.data);

          context.commit(SET_LOADING_REPORT_DOCUMENT_SITE_PROJECT, false);

          showToast("Success", "Data Loaded Successfully");
        })
        .catch(response => {
          context.commit(SET_ERROR, response?.data?.errors);

          context.commit(SET_LOADING_REPORT_DOCUMENT_SITE_PROJECT, false);

          showToast("Failed", "Failed Load Data", "danger");
        });
    });
  }
};

const mutations = {
  [SET_REPORT_DOCUMENT_SITE_PROJECT](state, documentSiteProjects) {
    state.reportDocumentSiteProjects = documentSiteProjects;
  },

  [SET_LOADING_REPORT_DOCUMENT_SITE_PROJECT](state, loading) {
    state.loading = loading;
  }
};

export default {
  state,
  actions,
  mutations
};
