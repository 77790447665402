import ApiService from "../api.service";
import { showToast } from "../../helper/toast.helper";

export const CLEAN_STATE = "cleanState";
export const GET_DOCUMENT_SITE_PROJECT = "getDocumentSiteProject";
export const POST_DOCUMENT_SITE_PROJECT = "postDocumentSiteProject";
export const POST_UPLOAD_DOCUMENT_SITE_PROJECT =
  "postUploadDocumentSiteProject";
export const DEL_DOCUMENT_SITE_PROJECT = "delDocumentSiteProject";

export const SET_ERROR = "setError";
export const SET_LOADING = "setLoading";
export const SET_DOCUMENT_SITE_PROJECT = "setDocumentSiteProject";
export const SET_DELETE_DOCUMENT_SITE_PROJECT = "setDeleteDocumentSiteProject";
export const SET_RESULT_POST = "setResultPostDSP";

const state = {
  documentSiteProject: {
    data: [],
    code: null,
    message: null
  },
  loading: false,
  errors: {},
  resultPostDsp: {
    data: [],
    code: null,
    message: null
  }
};

const getters = {
  currentDocumentSiteProjects(state) {
    return state.documentSiteProject?.data;
  },
  currentResultDsp(state) {
    return state.resultPostDsp?.data;
  }
};

const actions = {
  [GET_DOCUMENT_SITE_PROJECT](context, params) {
    return new Promise((resolve, reject) => {
      context.commit(SET_LOADING, true);
      ApiService.setHeader();
      ApiService.query(
        `project/document/site/${params.project_code}/project`,
        params
      )
        .then(({ data }) => {
          resolve(data);
          context.commit(SET_DOCUMENT_SITE_PROJECT, data);
          context.commit(SET_LOADING, false);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_LOADING, false);
        });
    });
  },

  [POST_DOCUMENT_SITE_PROJECT](context, param) {
    return new Promise(resolve => {
      ApiService.setHeader();
      ApiService.post("project/document/site", param)
        .then(({ data }) => {
          context.commit(SET_RESULT_POST, data);
          resolve(data);
          context.commit(SET_LOADING, false);
          showToast("Success", "Data Saved Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_LOADING, false);
          showToast("Failed", "Failed Save Data");
        });
    });
  },

  [POST_UPLOAD_DOCUMENT_SITE_PROJECT](context, files) {
    return new Promise((resolve, reject) => {
      context.commit(SET_LOADING, true);
      ApiService.setHeaderMultiPlatform();
      ApiService.post("project/document/site/upload", files)
        .then(({ data }) => {
          context.commit(SET_RESULT_POST, data);
          resolve(data);
          context.commit(SET_LOADING, false);
          showToast("Success", "Document Uploaded Successfully");
        })
        .catch(({ response }) => {
          reject(response);
          context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_LOADING, false);
          showToast("Failed", "Failed Upload Document");
        });
    });
  },
  [DEL_DOCUMENT_SITE_PROJECT](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING, true);
      ApiService.setHeader();
      ApiService.delete(`project/document/site/${params}/delete`)
        .then(({ data }) => {
          context.commit(SET_DELETE_DOCUMENT_SITE_PROJECT, params);
          resolve(data);
          context.commit(SET_LOADING, false);
          showToast("Success", "Data Deleted Successfully");

        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_LOADING, false);
          showToast("Failed", "Failed Delete Data");
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },

  [SET_LOADING](state, loading) {
    state.loading = loading;
  },

  [SET_DOCUMENT_SITE_PROJECT](state, data) {
    state.documentSiteProject = data;
  },

  [SET_RESULT_POST](state, data) {
    state.resultPostDsp = data;
  },

  [SET_DELETE_DOCUMENT_SITE_PROJECT](state, code) {
    const index = state.documentSiteProject?.data?.findIndex(
      documentSiteProject => documentSiteProject.id === code
    );

    if (index >= 0) {
      state.documentSiteProject?.data?.splice(index, 1);
    }
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
