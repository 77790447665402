import ApiService from "../api.service";
// import { showToast } from "../../helper/toast.helper";

export const GET_FILE_FTP = "getFileFtp";

export const SET_ERROR = "setError";
export const SET_LOADING_FILE_FTP = "setLoadingSiteDocumentType";
export const SET_FILE_FTP = "setFileFtp";

const state = {
  ftps: {},
  errors: {},
  loading: false
};

const actions = {
  [GET_FILE_FTP](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_FILE_FTP, true);

      ApiService.query('ftp/folder', params)
        .then(({ data }) => {
          context.commit(SET_FILE_FTP, data);

          resolve(data);
          context.commit(SET_LOADING_FILE_FTP, false);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_FILE_FTP, false);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },

  [SET_LOADING_FILE_FTP](state, loading) {
    state.loading = loading;
  },

  [SET_FILE_FTP](state, ftps) {
    state.ftps = ftps;
  }
};

export default {
  state,
  actions,
  mutations
};
