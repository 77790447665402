import { showToast } from "../../helper/toast.helper";
import ApiService from "../api.service";

export const GET_PROJECT_DETAIL = "getProjectDetail";
export const GET_ALL_PROJECT_DETAIL = "getAllProjectDetail";
export const GET_PROJECT_DETAIL_BY_ID = "getProjectDetailByID";
export const POST_PROJECT_DETAIL = "postProjectDetail";
export const DELETE_PROJECT_STATION = "deleteProjectStation";
export const DELETE_PROJECT_PIC = "deleteProjectPIC";
export const DELETE_PROJECT_COMPANY = "deleteProjectCompany";
export const FILTER_PROJECT_DETAIL = "filterProjectDetail";
export const GET_PROJECT_ALL = "getProjectAll";
export const POST_CLOSE_PROJECT = "postCloseProject";
export const POST_LOAD_PROJECT = "postLoadProject";

export const SET_ERROR = "setError";
export const SET_PROJECT_DETAILS = "setProjectDetails";
export const SET_PROJECT_DETAIL = "setProjectDetail";
export const SET_DATA_REMOVED = "setDataRemoved";
export const SET_PROJECT_ALL = "setProjectAll";
export const SET_LOADING = "setLoading";

const initialState = {
  projectDetails: [],
  projectDetail: {
    project: {},
    project_station: [],
    project_company: [],
    project_docon: []
  },
  project_station: [],
  project_company: [],
  project_docon: [],
  project_data_removed: [],
  project_all: {},
  errors: {},
  loading: false
};

export const state = { ...initialState };

const getters = {
  currentLoadingProject(state) {
    return state.loading;
  },

  currentProjectAll(state) {
    return state.project_all.data;
  },

  currentProjectDetails(state) {
    return state.projectDetails.data;
  },
  currentProjectDetail(state) {
    return state.projectDetail;
  },
  projectStation(state) {
    return state.project_station;
  },
  projectCompany(state) {
    return state.project_company;
  },
  projectDocon(state) {
    return state.project_docon;
  },
  projectNicknameOptions(state) {
    var result = [{ value: null, text: "Choose Nickname" }];

    if (state.projectDetails !== undefined) {
      state.projectDetails?.data?.map(project => {
        result.push({
          value: project.nickname,
          text: project.nickname
        });
      });
    }

    return result;
  }
};

const actions = {
  [FILTER_PROJECT_DETAIL](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING, true);
      ApiService.setHeader();
      ApiService.query(`project/${params.jobdesk}/jobdesk`, params)
        .then(({ data }) => {
          context.commit(SET_PROJECT_DETAILS, data);
          resolve(data);
          context.commit(SET_LOADING, false);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_LOADING, false);
        });
    });
  },

  [GET_PROJECT_DETAIL](context, params) {
    return new Promise(resolve => {
      ApiService.setHeader();

      const jobDesk = typeof params === "object" ? params?.jobdesk : params;
      const jobDeskParam =
        params.params != undefined
          ? params.params.map((d, index) => `jobdesk[${index}]=${d}`).join("&")
          : "";
      ApiService.get("project", `${jobDesk}/jobdesk?${jobDeskParam}`)
        .then(({ data }) => {
          context.commit(SET_PROJECT_DETAILS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [GET_ALL_PROJECT_DETAIL](context, params) {
    return new Promise(resolve => {
      ApiService.setHeader();

      const jobDesk = typeof params === "object" ? params?.jobdesk : params;
      const jobDeskParam =
        params.params != undefined
          ? params.params.map((d, index) => `jobdesk[${index}]=${d}`).join("&")
          : "";
      ApiService.get("project", `${jobDesk}/jobdesk/project?${jobDeskParam}`)
        .then(({ data }) => {
          context.commit(SET_PROJECT_DETAILS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  [GET_PROJECT_DETAIL_BY_ID](context, params) {
    return new Promise(resolve => {
      ApiService.setHeader();
      ApiService.get("project", `${params}/detail`)
        .then(({ data }) => {
          context.commit(SET_PROJECT_DETAIL, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  [POST_PROJECT_DETAIL](context, param) {
    return new Promise(resolve => {
      ApiService.setHeader();
      ApiService.post("project/save", param)
        .then(({ data }) => {
          context.commit(SET_PROJECT_DETAIL, data);
          resolve(data);

          showToast("Success", "Data Saved Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          showToast("Failed", "Failed Save Data", "danger");
        });
    });
  },
  [DELETE_PROJECT_STATION](context, params) {
    return new Promise(resolve => {
      ApiService.setHeader();
      ApiService.delete("project/station", { data: params })
        .then(({ data }) => {
          context.commit(SET_DATA_REMOVED, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [DELETE_PROJECT_PIC](context, params) {
    return new Promise(resolve => {
      ApiService.setHeader();
      ApiService.delete("project/pic", { data: params })
        .then(({ data }) => {
          context.commit(SET_DATA_REMOVED, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  [DELETE_PROJECT_COMPANY](context, params) {
    return new Promise(resolve => {
      ApiService.setHeader();
      ApiService.delete("project/company", { data: params })
        .then(({ data }) => {
          context.commit(SET_DATA_REMOVED, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  [POST_CLOSE_PROJECT](context, param) {
    return new Promise(resolve => {
      ApiService.setHeader();
      ApiService.post(`project/${param.project_id}/close`, param)
        .then(({ data }) => {
          //context.commit(SET_PROJECT_DETAIL, data);
          resolve(data);
          showToast("Success", "Project Closed Successfully");
        })
        .catch(() => {
          showToast("Gagal", "Failed to Close Project", "danger");
        });
    });
  },
  [GET_PROJECT_ALL](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING, true);
      ApiService.setHeader();
      ApiService.query(`project`, params)
        .then(({ data }) => {
          context.commit(SET_PROJECT_ALL, {
            ...data,
            data: data?.data?.filter(item => {
              if (params?.params?.contract_num) {
                return item.contract_no
                  ?.toUpperCase()
                  ?.includes(params.params.contract_num.toUpperCase());
              }

              return true;
            })
          });
          resolve(data);
          context.commit(SET_LOADING, false);
          showToast("Success", "Project Data Loaded Successfully", "success");
        })
        .catch(() => {
          showToast("Failed", "Failed Load Project Data", "danger");

          context.commit(SET_LOADING, false);
        });
    });
  },

  [POST_LOAD_PROJECT](context, param) {
    return new Promise(resolve => {
      ApiService.setHeader();
      ApiService.post(`project/load`, param)
        .then(({ data }) => {
          context.commit(SET_PROJECT_ALL, data);
          resolve(data);
          showToast("Success", "Project Data Loaded Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          showToast(
            "Failed",
            "Failed Load Project Data : " + response.data.message,
            "danger"
          );
        });
    });
  }
};

const mutations = {
  [SET_PROJECT_DETAIL](state, projectDetail) {
    state.projectDetail = projectDetail.data;
    state.project_station = projectDetail.data.project_station.map(station => {
      return {
        id: station.id,
        code: station.station_code,
        name: ""
      };
    });
    state.project_company = projectDetail.data.project_company.map(company => {
      return {
        id: company.id,
        code: company.company_code,
        name: ""
      };
    });
    state.project_docon = projectDetail.data.project_docon.map(pic => {
      return {
        id: pic.id,
        code: pic.pic_code,
        name: pic.name,
        jobdesk: pic.jobdesk
      };
    });

    state.errors = {};
  },

  [SET_PROJECT_DETAILS](state, projectDetails) {
    const uniqueIds = [];
    var uniqueDatas = projectDetails.data.filter(element => {
      const isDuplicate = uniqueIds.includes(element.code);
      if (!isDuplicate) {
        uniqueIds.push(element.code);
        return true;
      }
      return false;
    });
    projectDetails.data = uniqueDatas;
    state.projectDetails = projectDetails;
    state.errors = {};
  },

  [SET_ERROR](state, error) {
    state.errors = error;
  },

  [SET_DATA_REMOVED](state, data) {
    state.project_data_removed = data;
  },

  [SET_LOADING](state, loading) {
    state.loading = loading;
  },

  [SET_PROJECT_ALL](state, data) {
    state.project_all = data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
