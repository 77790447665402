// action types
import ApiService from "../api.service";
import { showToast } from "../../helper/toast.helper";

export const GET_AS_BUILT_DRAWING = "getAsBuiltDrawing";
export const UPDATE_AS_BUILT_DRAWING = "updateAsBuiltDrawing";

// mutation types
export const SET_AS_BUILT_DRAWING = "setAsBuiltDrawing";
export const SET_UPDATE_AS_BUILT_DRAWING = "setUpdateAsBuiltDrawing";
export const SET_LOADING_AS_BUILT_DRAWING = "setLoadingAsBuiltDrawing";
export const SET_ERROR_AS_BUILT_DRAWING = "setErrorAsBuiltDrawing";

export default {
  state: {
    loading: false,
    errors: {},
    asBuiltDrawing: {}
  },
  getters: {
    //
  },
  actions: {
    /**
     * Get
     * @param context
     * @param params
     */
    [GET_AS_BUILT_DRAWING](context, params) {
      return new Promise((resolve, reject) => {
        context.commit(SET_LOADING_AS_BUILT_DRAWING, true);

        ApiService.setHeader();

        ApiService.query(
          `project/${params.params.project_code}/monitoring/asbuilt/drawing`,
          params
        )
          .then(({ data }) => {
            resolve(data);

            context.commit(SET_AS_BUILT_DRAWING, data);

            context.commit(SET_LOADING_AS_BUILT_DRAWING, false);
          })
          .catch(({ response }) => {
            reject(response);

            context.commit(SET_ERROR_AS_BUILT_DRAWING, response.data.errors);

            context.commit(SET_LOADING_AS_BUILT_DRAWING, false);
          });
      });
    },

    /**
     * Update
     * @param context
     * @param params
     */
    [UPDATE_AS_BUILT_DRAWING](context, body) {
      return new Promise((resolve, reject) => {
        context.commit(SET_LOADING_AS_BUILT_DRAWING, true);

        ApiService.setHeader();

        ApiService.post(`project/monitoring/asbuilt/drawing`, body)
          .then(({ data }) => {
            resolve(data);

            context.commit(SET_UPDATE_AS_BUILT_DRAWING, body);

            context.commit(SET_LOADING_AS_BUILT_DRAWING, false);

            showToast("Success", "Data Updated Successfully");
          })
          .catch(({ response }) => {
            reject(response);

            context.commit(SET_ERROR_AS_BUILT_DRAWING, response.data.errors);

            context.commit(SET_LOADING_AS_BUILT_DRAWING, false);

            showToast("Failed", "Failed Update Data", "danger");
          });
      });
    }
  },
  mutations: {
    [SET_AS_BUILT_DRAWING](state, data) {
      state.asBuiltDrawing = data;
    },

    [SET_LOADING_AS_BUILT_DRAWING](state, loading) {
      state.loading = loading;
    },

    [SET_UPDATE_AS_BUILT_DRAWING](state, body) {
      body.dld.map(data => {
        const index = state.asBuiltDrawing?.data.findIndex(
          asBuiltDrawing => asBuiltDrawing?.id === data.id
        );

        if (index >= 0) {
          Object.assign(state.asBuiltDrawing?.data[index], {
            ...data
          });
        }
      });
    }
  }
};
