import ApiService from "../api.service";
import { showToast } from "../../helper/toast.helper";
// import { showToast } from "../../helper/toast.helper";

export const GET_DL_DESIGN = "getDLDesign";
export const POST_DL_DESIGN = "postDLDesign";
export const UPDATE_DL_DESIGN = "updateDLDesign";
export const DELETE_DL_DESIGN = "deleteDLDesign";

// actions
export const SHOW_PROJECT = "showProject";
export const GET_STATION = "getStationPerProject";
export const GET_SCOPE = "getScope";
export const GET_SCOPE_BY_PROJECT = "getScopeByProject";
export const GET_STATUS = "getStatus";
export const GET_DESIGN = "getDesign";
export const SEARCH_DESIGN = "searchDesign";
export const SAVE_DESIGN = "saveDesign";
export const CURRENT_DESIGN_CODE = "currentDesignCode";
export const IMPORT_DESIGN = "importDesign";
export const SHOW_PROJECT_ADMIN = "showProjectAdmin";
export const SHOW_PROJECT_DESIGN = "showProjectDesign";
export const SHOW_PROJECT_KOOR = "showProjectKoor";
export const SHOW_PROJECT_PLANNER = "showProjectPlanner";
export const SHOW_PROJECT_INTERFACE = "showProjectInterface";
export const SHOW_PROJECT_DRAFTER = "showProjectDrafter";
export const SHOW_PROJECT_ENGINER = "showProjectEnginer";
export const SHOW_PROJECT_MANAGER = "showProjectManager";

// mutations
export const SET_PROJECT = "setProject";
export const SET_STATION = "setStation";
export const SET_SCOPE = "setScope";
export const SET_DESIGN = "setDesign";
export const SET_SEARCH = "setSearch";
export const STATUS_SAVE = "statusSave";
export const SET_TYPE_DESIGN = "setTypeDesign";
export const SET_MASTER_DESIGN = "setMasterDesign";
export const SET_CURRENT_CODE = "setCurrentDesignCode";
export const SET_PROJECT_ADMIN = "setProjectAdmin";
export const SET_PROJECT_DESIGN = "setProjectDesign";
export const SET_PROJECT_KOOR = "setProjectKoor";
export const SET_PROJECT_PLANNER = "setProjectPlanner";
export const SET_PROJECT_INTERFACE = "setProjectInterface";
export const SET_PROJECT_MANAGER = "setProjectManager";
export const SET_PROJECT_ENGINER = "setProjectEnginer";
export const SET_PROJECT_DRAFTER = "setProjectDrafter";

export const GET_DETAIL_DL_DESIGN_LIST = "getDetailDLDesignList";

export const SET_ERROR = "setError";
export const SET_LOADING = "setLoading";
export const SET_DL_DESIGN = "setDLDesaign";
export const SET_DETAIL_DL_DESIGN_LIST = "setDetailDLDesignList";
export const SET_UPDATED_DL_DESIGN = "setUpdatedDLDesign";
export const SET_RESULT_IMPORT = "setResultImport";

export const OBSOLETE_DL_DESIGN = "obsoleteDlDesign";

export const REMOVE_DETAIL_DL_DESIGN_LIST = "removeDetailDLDesignList";

const state = {
  dldesign: [],
  detailDlDesignList: {
    data: {
      dld: [],
      project: {}
    },
    code: null,
    message: null
  },
  loading: false,
  listProject: [],
  listProjectAdmin: [],
  listProjectDesign: [],
  listProjectKoor: [],
  listProjectPlanner: [],
  listProjectInterface: [],
  listProjectManager: [],
  listProjectDrafter: [],
  listProjectEnginer: [],
  listDesign: [],
  listStation: [],
  listScope: [],
  currentDesignCode: "",
  typeDesign: "",
  resultSave: "",
  errors: {},
  resultImport: {}
};

const getters = {
  currentLoadingDesign(state) {
    return state.loading;
  },
  currentdldesign(state) {
    return state.design?.data;
  },

  getterProject(state) {
    return state.listProject;
  },

  getterProjectAdmin(state) {
    return state.listProjectAdmin;
  },

  getterProjectDesign(state) {
    return state.listProjectDesign;
  },

  getterProjectKoor(state) {
    return state.listProjectKoor;
  },

  getterProjectPlanner(state) {
    return state.listProjectPlanner;
  },

  getterProjectInterface(state) {
    return state.listProjectInterface;
  },

  getterProjectDrafter(state) {
    return state.listProjectDrafter;
  },

  getterProjectEnginer(state) {
    return state.listProjectEnginer;
  },

  getterProjectManager(state) {
    return state.listProjectManager;
  },

  getterStation(state) {
    return state.listStation?.stations?.length > 0
      ? [
          ...state.listStation?.stations,
          {
            name: "Choose Station",
            code: ""
          }
        ]
      : [
          {
            name: "Choose Station",
            code: ""
          }
        ];
  },

  getterScope(state) {
    return state.listScope?.length > 0
      ? [
          ...state.listScope,
          {
            name: "Choose Scope",
            code: ""
          }
        ]
      : [
          {
            name: "Choose Scope",
            code: ""
          }
        ];
  },

  getterCategory(state) {
    return state.listDesign?.length > 0
      ? [
          ...state.listDesign.category,
          {
            name: "Choose Design",
            code: ""
          }
        ]
      : [
          {
            name: "Choose Design",
            code: ""
          }
        ];
  },

  getterDesign(state) {
    return state.listDesign;
  },

  getterCurrentCode(state) {
    return state.currentDesignCode;
  },

  getterMasterDesign(state) {
    return state.masterSearch;
  },

  getterResultSave(state) {
    return state.resultSave;
  },

  getterTypeDesign(state) {
    return state.typeDesign;
  }
};

const actions = {
  [GET_DL_DESIGN](context, params) {
    return new Promise(resolve => {
      ApiService.get(
        "project",
        `${params.id_project}/${params.type_design}/ddl`
      )
        .then(({ data }) => {
          context.commit(SET_DL_DESIGN, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  [POST_DL_DESIGN](context, param) {
    return new Promise(resolve => {
      ApiService.setHeader();
      ApiService.post("project/dld/man/hour", param)
        .then(({ data }) => {
          context.commit(SET_DL_DESIGN, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  [GET_DETAIL_DL_DESIGN_LIST](context, params) {
    return new Promise((resolve, reject) => {
      context.commit(SET_LOADING, true);

      ApiService.setHeader();

      ApiService.query(
        `project/${params.project_id}/${params.type_design}/dld`,
        params
      )
        .then(({ data }) => {
          resolve(data);

          context.commit(SET_DETAIL_DL_DESIGN_LIST, data);

          context.commit(SET_LOADING, false);

          showToast("Success", "Data Loaded Successfully");
        })
        .catch(({ response }) => {
          reject(response);
          
          context.commit(SET_DETAIL_DL_DESIGN_LIST, response);

          context.commit(SET_ERROR, response?.data?.errors);

          context.commit(SET_LOADING, false);

          showToast("Failed", "Failed Load Data", "danger");
        });
    });
  },

  [DELETE_DL_DESIGN](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING, true);

      ApiService.setHeader();

      ApiService.delete("project/dld", params)
        .then(({ data }) => {
          resolve(data);

          context.commit(SET_LOADING, false);

          context.commit(REMOVE_DETAIL_DL_DESIGN_LIST, params);

          showToast("Success", "Data Deleted Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_LOADING, false);

          context.commit(SET_ERROR, response.data.message);

          showToast("Failed", "Failed Delete Data", "danger");
        });
    });
  },

  [UPDATE_DL_DESIGN](context, body) {
    return new Promise(resolve => {
      context.commit(SET_LOADING, true);

      ApiService.setHeader();

      ApiService.post("project/dld/detail", body)
        .then(data => {
          resolve(data);

          context.commit(SET_LOADING, false);

          context.commit(SET_UPDATED_DL_DESIGN, body);

          showToast("Success", "Data Updated Successfully");
        })
        .catch(response => {
          context.commit(SET_LOADING, false);
          context.commit(SET_ERROR, response);

          var ResponseData = JSON.parse(response.request.response);
          if(ResponseData != null){
            if(ResponseData.message != undefined)
              showToast("Failed", ResponseData.message, "danger");  
          }else{
            showToast("Failed", "Failed Update Data", "danger");
          }
        });
    });
  },

  [OBSOLETE_DL_DESIGN](context, body) {
    return new Promise(resolve => {
      //context.commit(SET_LOADING, true);

      ApiService.setHeader();

      ApiService.post("project/dld/obselete", body)
        .then(({ data }) => {
          resolve(data);
          console.log(data);
          //context.commit(SET_LOADING, false);

          // context
          //   .dispatch(GET_DETAIL_DL_DESIGN_LIST, {
          //     project_id: body.project_id,
          //     type_design: body.type
          //   })
          //   .then(() => {});

          showToast("Success", "Design Reloaded Successfully");
        })
        .catch(({ response }) => {
          //context.commit(SET_LOADING, false);

          context.commit(SET_ERROR, response);

          showToast("Failed", "Failed Reloaded Design", "danger");
        });
    });
  },

  [SHOW_PROJECT]({ commit }) {
    ApiService.query("project/design/jobdesk").then(res => {
      commit(SET_PROJECT, res.data.data);
    });
  },

  [SHOW_PROJECT_ADMIN](context, params) {
    ApiService.query("project/admin/jobdesk", params).then(res => {
      context.commit(SET_PROJECT_ADMIN, res.data.data);
    });
  },

  [SHOW_PROJECT_DESIGN](context, params) {
    ApiService.query("project/design/jobdesk", params).then(res => {
      context.commit(SET_PROJECT_DESIGN, res.data.data);
    });
  },

  [SHOW_PROJECT_KOOR](context, params) {
    ApiService.query("project/koor/jobdesk", params).then(res => {
      context.commit(SET_PROJECT_KOOR, res.data.data);
    });
  },

  [SHOW_PROJECT_PLANNER](context, params) {
    ApiService.query("project/planner/jobdesk", params).then(res => {
      context.commit(SET_PROJECT_PLANNER, res.data.data);
    });
  },

  [SHOW_PROJECT_INTERFACE](context, params) {
    ApiService.query("project/interface/jobdesk", params).then(res => {
      context.commit(SET_PROJECT_INTERFACE, res.data.data);
    });
  },

  [SHOW_PROJECT_MANAGER](context, params) {
    ApiService.query("project/manager/jobdesk", params).then(res => {
      context.commit(SET_PROJECT_MANAGER, res.data.data);
    });
  },

  [SHOW_PROJECT_ENGINER](context, params) {
    ApiService.query("project/enginer/jobdesk", params).then(res => {
      context.commit(SET_PROJECT_ENGINER, res.data.data);
    });
  },

  [SHOW_PROJECT_DRAFTER](context, params) {
    ApiService.query("project/drafter/jobdesk", params).then(res => {
      context.commit(SET_PROJECT_DRAFTER, res.data.data);
    });
  },

  [GET_STATION]({ commit }, data) {
    ApiService.query("station/" + data.id + "/project").then(res => {
      commit(SET_STATION, res.data.data);
    });
  },

  [GET_SCOPE]({ commit }) {
    ApiService.query("scope").then(res => {
      commit(SET_SCOPE, res.data.data);
    });
  },
  [GET_SCOPE_BY_PROJECT]({ commit }, data) {
    ApiService.query("scope/byproject?project_code=" + data.id).then(res => {
      commit(SET_SCOPE, res.data.data);
    });
  },

  [CURRENT_DESIGN_CODE]({ commit }, data) {
    commit(SET_CURRENT_CODE, data);
  },

  async [GET_DESIGN]({ commit }, parameter) {
    var send = {
      params: {
        id_project: parameter.code
      }
    };
    var url = "design/" + parameter.type + "/type";
    ApiService.setHeader();
    commit(SET_LOADING, true);
    await ApiService.query(url, send)
      .then(res => {
        commit(SET_LOADING, false);
        commit(SET_DESIGN, res.data.data);
        commit(SET_MASTER_DESIGN, res.data.data);
        commit(SET_TYPE_DESIGN, parameter.type);
      })
      .catch(({ response }) => {
        commit(SET_LOADING, false);

        commit(SET_ERROR, response);

        showToast("Failed", "Failed Load Design", "danger");
      });
  },

  [SEARCH_DESIGN]({ commit }, data) {
    commit(SET_SEARCH, data);
  },

  async [SAVE_DESIGN]({ commit }, data) {
    await ApiService.post("project/dld", data).then(() => {
      commit(STATUS_SAVE, { message: "success" });
    });
  },

  [IMPORT_DESIGN](context, param) {
    return new Promise(resolve => {
      ApiService.setHeader();
      context.commit(SET_LOADING, true);
      ApiService.post("project/dld", param)
        .then(({ data }) => {
          context.commit(SET_RESULT_IMPORT, data);
          resolve(data);
          context.commit(SET_LOADING, false);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_LOADING, false);
        });
    });
  }
};

const mutations = {
  [SET_DL_DESIGN](state, dldesign) {
    state.dldesign = dldesign;
  },

  [SET_ERROR](state, error) {
    state.errors = error;
  },

  [SET_DETAIL_DL_DESIGN_LIST](state, detailDlDesignList) {
    state.detailDlDesignList = detailDlDesignList;
  },

  [SET_LOADING](state, loading) {
    state.loading = loading;
  },

  [REMOVE_DETAIL_DL_DESIGN_LIST](state, params) {
    params.data.designs.map(id => {
      const index = state.detailDlDesignList.data?.dld.findIndex(
        dlDesign => dlDesign?.id === id
      );

      if (index >= 0) {
        state.detailDlDesignList.data?.dld.splice(index, 1);
      }
    });
  },

  [SET_UPDATED_DL_DESIGN](state, body) {
    body.dld.map(data => {
      const index = state.detailDlDesignList.data?.dld.findIndex(
        dlDesign => dlDesign?.id === data.id
      );

      if (index >= 0) {
        Object.assign(state.detailDlDesignList.data?.dld[index], {
          title: data.title,
          revision_version: data.revision_version,
          version: data.version,
          date_receive: data.date_receive,
          delay: data.delay,
          doc_room: data.doc_room,
          status: data.last_status.name,
          server: data.server,
          monitoring_asbuilt_status: data.monitoring_asbuilt_status,
          return_item: data.return_item,
          outstanding: {
            name: data.outstanding.drafter,
            code: data.outstanding.code
          }
        });
      }
    });
  },

  [SET_PROJECT](state, data) {
    state.listProject = data;
  },

  [SET_PROJECT_DESIGN](state, data) {
    state.listProjectDesign = data;
  },

  [SET_PROJECT_ADMIN](state, data) {
    state.listProjectAdmin = data;
  },

  [SET_PROJECT_KOOR](state, data) {
    state.listProjectKoor = data;
  },

  [SET_PROJECT_PLANNER](state, data) {
    state.listProjectPlanner = data;
  },

  [SET_PROJECT_INTERFACE](state, data) {
    state.listProjectInterface = data;
  },

  [SET_PROJECT_MANAGER](state, data) {
    state.listProjectManager = data;
  },

  [SET_PROJECT_DRAFTER](state, data) {
    state.listProjectDrafter = data;
  },

  [SET_PROJECT_ENGINER](state, data) {
    state.listProjectEnginer = data;
  },

  [SET_STATION](state, data) {
    state.listStation = data;
  },

  [SET_SCOPE](state, data) {
    state.listScope = data;
  },

  [SET_CURRENT_CODE](state, data) {
    state.currentDesignCode = data.code;
  },

  [SET_DESIGN](state, data) {
    state.listDesign = data;
  },

  [SET_TYPE_DESIGN](state, data) {
    state.typeDesign = data;
  },

  [SET_SEARCH](state, data) {
    state.listDesign = data;
  },

  [SET_MASTER_DESIGN](state, data) {
    state.masterSearch = data;
  },

  [STATUS_SAVE](state, data) {
    state.resultSave = data;
  },

  [SET_RESULT_IMPORT](state, data) {
    state.resultImport = data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
