import ApiService from "../api.service";
import { showToast } from "../../helper/toast.helper";

export const GET_DEPARTMENT = "getDepartment";
export const DELETE_DEPARTMENT = "deleteDepartment";
export const SAVE_DEPARTMENT = "saveDepartment";
export const UPDATE_DEPARTMENT = "updateDepartment";

export const SET_ERROR = "setError";
export const SET_DEPARTMENT = "setDepartment";
export const SET_DELETE_DEPARTMENT = "setDeleteDepartment";
export const SET_SAVE_DEPARTMENT = "setSaveDepartment";
export const SET_UPDATE_DEPARTMENT = "setUpdateDepartment";
export const SET_LOADING_DEPARTMENT = "setLoadingDepartment";

const state = {
  departments: {},
  errors: {},
  loading: false
};

const actions = {
  [GET_DEPARTMENT](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_DEPARTMENT, true);

      ApiService.query("department", params)
        .then(({ data }) => {
          context.commit(SET_DEPARTMENT, data);

          resolve(data);

          context.commit(SET_LOADING_DEPARTMENT, false);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_DEPARTMENT, false);
        });
    });
  },

  [DELETE_DEPARTMENT](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_DEPARTMENT, true);

      ApiService.setHeader();

      ApiService.delete(`department/${params.code}/delete`)
        .then(({ data }) => {
          context.commit(SET_DELETE_DEPARTMENT, params.code);

          resolve(data);

          context.commit(SET_LOADING_DEPARTMENT, false);

          showToast("Success", "Data Deleted Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_DEPARTMENT, false);

          showToast("Failed", "Failed Delete Data", "danger");
        });
    });
  },

  [SAVE_DEPARTMENT](context, body) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_DEPARTMENT, true);

      ApiService.setHeader();

      ApiService.post("department", body)
        .then(({ data }) => {
          context.commit(SET_SAVE_DEPARTMENT, data?.data);

          resolve(data);

          context.commit(SET_LOADING_DEPARTMENT, false);

          showToast("Success", "Data Saved Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_DEPARTMENT, false);

          showToast("Failed", "Failed Save Data", "danger");
        });
    });
  },

  [UPDATE_DEPARTMENT](context, body) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_DEPARTMENT, true);

      ApiService.setHeader();

      ApiService.put("department", body)
        .then(({ data }) => {
          context.commit(SET_UPDATE_DEPARTMENT, data?.data);

          resolve(data);

          context.commit(SET_LOADING_DEPARTMENT, false);

          showToast("Success", "Data Updated Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_DEPARTMENT, false);

          showToast("Failed", "Failed Update Data", "danger");
        });
    });
  }
};

const mutations = {
  [SET_DEPARTMENT](state, departments) {
    state.departments = departments;
  },

  [SET_ERROR](state, error) {
    state.errors = error;
  },

  [SET_SAVE_DEPARTMENT](state, department) {
    state.departments.data = [department, ...state.departments.data];
  },

  [SET_LOADING_DEPARTMENT](state, loading) {
    state.loading = loading;
  },

  [SET_UPDATE_DEPARTMENT](state, updateStation) {
    const index = state.departments?.data?.findIndex(
      department => department.code === updateStation.code
    );

    if (index >= 0) {
      Object.assign(state.departments?.data[index], updateStation);
    }
  },

  [SET_DELETE_DEPARTMENT](state, code) {
    const index = state.departments?.data?.findIndex(
      department => department.code === code
    );

    if (index >= 0) {
      state.departments?.data?.splice(index, 1);
    }
  }
};

export default {
  state,
  actions,
  mutations
};
