import ApiService from "../api.service";
import { showToast } from "../../helper/toast.helper";

export const GET_SUBSYSTEM_OF_WORK = "getSubSystemOfWork";
export const DELETE_SUBSYSTEM_OF_WORK = "deleteSubSystemOfWork";
export const SAVE_SUBSYSTEM_OF_WORK = "saveSubSystemOfWork";
export const UPDATE_SUBSYSTEM_OF_WORK = "updateSubSystemOfWork";

export const SET_ERROR = "setError";
export const SET_SUBSYSTEM_OF_WORK = "setSubSystemOfWork";
export const SET_DELETE_SUBSYSTEM_OF_WORK = "setDeleteSubSystemOfWork";
export const SET_SAVE_SUBSYSTEM_OF_WORK = "setSaveSubSystemOfWork";
export const SET_UPDATE_SUBSYSTEM_OF_WORK = "setUpdateSubSystemOfWork";
export const SET_LOADING_SUBSYSTEM_OF_WORK = "setLoadingSubSystemOfWork";

const state = {
  subSystemOfWork: {},
  errors: {},
  loading: false
};

const actions = {
  [GET_SUBSYSTEM_OF_WORK](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_SUBSYSTEM_OF_WORK, true);

      ApiService.setHeader();

      ApiService.query("scope/work", params)
        .then(({ data }) => {
          context.commit(SET_SUBSYSTEM_OF_WORK, data);

          resolve(data);

          context.commit(SET_LOADING_SUBSYSTEM_OF_WORK, false);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_SUBSYSTEM_OF_WORK, false);
        });
    });
  },

  [DELETE_SUBSYSTEM_OF_WORK](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_SUBSYSTEM_OF_WORK, true);

      ApiService.setHeader();

      ApiService.delete(`scope/work/${params.code}/delete`)
        .then(({ data }) => {
          context.commit(SET_DELETE_SUBSYSTEM_OF_WORK, params.code);

          resolve(data);

          context.commit(SET_LOADING_SUBSYSTEM_OF_WORK, false);

          showToast("Success", "Data Deleted Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_SUBSYSTEM_OF_WORK, false);

          showToast("Failed", "Failed Delete Data", "danger");
        });
    });
  },

  [SAVE_SUBSYSTEM_OF_WORK](context, body) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_SUBSYSTEM_OF_WORK, true);

      ApiService.setHeader();

      ApiService.post("scope/work", body)
        .then(({ data }) => {
          context.commit(SET_SAVE_SUBSYSTEM_OF_WORK, data?.data);

          resolve(data);

          context.commit(SET_LOADING_SUBSYSTEM_OF_WORK, false);

          showToast("Success", "Data Saved Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_SUBSYSTEM_OF_WORK, false);

          showToast("Failed", "Failed Save Data", "danger");
        });
    });
  },

  [UPDATE_SUBSYSTEM_OF_WORK](context, body) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_SUBSYSTEM_OF_WORK, true);

      ApiService.setHeader();

      ApiService.put("scope/work", body)
        .then(({ data }) => {
          context.commit(SET_UPDATE_SUBSYSTEM_OF_WORK, data?.data);

          resolve(data);

          context.commit(SET_LOADING_SUBSYSTEM_OF_WORK, false);

          showToast("Success", "Data Updated Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_SUBSYSTEM_OF_WORK, false);

          showToast("Failed", "Failed Update Data", "danger");
        });
    });
  }
};

const mutations = {
  [SET_SUBSYSTEM_OF_WORK](state, subSystemOfWork) {
    state.subSystemOfWork = subSystemOfWork;
  },

  [SET_SAVE_SUBSYSTEM_OF_WORK](state, subSystemOfWork) {
    state.subSystemOfWork.data = [
      subSystemOfWork,
      ...state.subSystemOfWork.data
    ];
  },

  [SET_LOADING_SUBSYSTEM_OF_WORK](state, loading) {
    state.loading = loading;
  },

  [SET_UPDATE_SUBSYSTEM_OF_WORK](state, updateSubSystemOfWork) {
    const index = state.subSystemOfWork?.data?.findIndex(
      subSystemOfWork => subSystemOfWork.code === updateSubSystemOfWork.code
    );

    if (index >= 0) {
      Object.assign(state.subSystemOfWork?.data[index], updateSubSystemOfWork);
    }
  },

  [SET_DELETE_SUBSYSTEM_OF_WORK](state, code) {
    const index = state.subSystemOfWork?.data?.findIndex(
      subSystemOfWork => subSystemOfWork.code === code
    );

    if (index >= 0) {
      state.subSystemOfWork?.data?.splice(index, 1);
    }
  },

  [SET_ERROR](state, error) {
    state.errors = error;
  }
};

export default {
  state,
  actions,
  mutations
};
