import ApiService from "../api.service";
import { showToast } from "../../helper/toast.helper";

export const GET_REPORT_BILLING_DESIGN = "GET_REPORT_BILLING_DESIGN";
export const GET_REPORT_BILLING_DISTRIBUTION =
  "GET_REPORT_BILLING_DISTRIBUTION";
export const GET_REPORT_BILLING_CHANGE_MANAGEMENT =
  "GET_REPORT_BILLING_CHANGE_MANAGEMENT";
export const GET_REPORT_BILLING_MINUTE_OF_MEETING =
  "GET_REPORT_BILLING_MINUTE_OF_MEETING";
export const GET_REPORT_BILLING_LETTER_IN_OUT =
  "GET_REPORT_BILLING_LETTER_IN_OUT";
export const GET_REPORT_BILLING_MAN_HOUR = "GET_REPORT_BILLING_MAN_HOUR";
export const GET_REPORT_BILLING_MAN_HOUR_LAMPIRAN_B =
  "GET_REPORT_BILLING_MAN_HOUR_LAMPIRAN_B";
export const GET_REPORT_BILLING_BOM_DESIGN = "GET_REPORT_BILLING_BOM_DESIGN";
export const GET_REPORT_BILLING_SHOP_DRAWING_INTERNAL =
  "GET_REPORT_BILLING_SHOP_DRAWING_INTERNAL";
export const GET_REPORT_BILLING_SHOP_DRAWING_EXTERNAL =
  "GET_REPORT_BILLING_SHOP_DRAWING_EXTERNAL";
export const GET_REPORT_BILLING_ASBUILT_DRAWING =
  "GET_REPORT_BILLING_ASBUILT_DRAWING";
export const GET_REPORT_BILLING_KELENGKAPAN_DOKUMEN_PROYEK =
  "GET_REPORT_BILLING_KELENGKAPAN_DOKUMEN_PROYEK";
export const GET_REPORT_BILLING_TRANSMITTAL = "GET_REPORT_BILLING_TRANSMITTAL";
export const GET_REPORT_BILLING_TRACKING_DESIGN =
  "GET_REPORT_BILLING_TRACKING_DESIGN";
export const GET_REPORT_BILLING_TRACKING_DESIGN_BILLING =
  "GET_REPORT_BILLING_TRACKING_DESIGN_BILLING";

export const SET_REPORT_BILLING_LOADING = "SET_REPORT_BILLING_LOADING";
export const SET_REPORT_BILLING_DESIGN = "SET_REPORT_BILLING_DESIGN";
export const SET_REPORT_BILLING_DISTRIBUTION =
  "SET_REPORT_BILLING_DISTRIBUTION";
export const SET_REPORT_BILLING_CHANGE_MANAGEMENT =
  "SET_REPORT_BILLING_CHANGE_MANAGEMENT";
export const SET_REPORT_BILLING_MINUTE_OF_MEETING =
  "SET_REPORT_BILLING_MINUTE_OF_MEETING";
export const SET_REPORT_BILLING_LETTER_IN_OUT =
  "SET_REPORT_BILLING_LETTER_IN_OUT";
export const SET_REPORT_BILLING_MAN_HOUR = "SET_REPORT_BILLING_MAN_HOUR";
export const SET_REPORT_BILLING_MAN_HOUR_LAMPIRAN_B =
  "SET_REPORT_BILLING_MAN_HOUR_LAMPIRAN_B";
export const SET_REPORT_BILLING_BOM_DESIGN = "SET_REPORT_BILLING_BOM_DESIGN";
export const SET_REPORT_BILLING_SHOP_DRAWING_INTERNAL =
  "SET_REPORT_BILLING_SHOP_DRAWING_INTERNAL";
export const SET_REPORT_BILLING_SHOP_DRAWING_EXTERNAL =
  "SET_REPORT_BILLING_SHOP_DRAWING_EXTERNAL";
export const SET_REPORT_BILLING_ASBUILT_DRAWING =
  "SET_REPORT_BILLING_ASBUILT_DRAWING";
export const SET_REPORT_BILLING_KELENGKAPAN_DOKUMEN_PROYEK =
  "SET_REPORT_BILLING_KELENGKAPAN_DOKUMEN_PROYEK";
export const SET_REPORT_BILLING_TRANSMITTAL = "SET_REPORT_BILLING_TRANSMITTAL";
export const SET_REPORT_BILLING_TRACKING_DESIGN =
  "SET_REPORT_BILLING_TRACKING_DESIGN";
export const SET_REPORT_BILLING_TRACKING_DESIGN_BILLING =
  "SET_REPORT_BILLING_TRACKING_DESIGN_BILLING";
const state = {
  designs: [],
  distributions: [],
  changeManagements: [],
  minuteOfMeetings: [],
  letterInOuts: [],
  manHour: [],
  manHourLampiranB: [],
  bomDesign: [],
  shopDrawingInternal: [],
  shopDrawingExternal: [],
  asbuiltDrawing: [],
  kelengkapanDokumenProyek: [],
  billingAndTracking: [],
  billingAndTrackingReport: [],
  transmittal: [],
  loading: false,
};

const actions = {
  [GET_REPORT_BILLING_TRACKING_DESIGN](context, params) {
    return new Promise((resolve) => {
      context.commit(SET_REPORT_BILLING_LOADING, true);

      ApiService.query(
        `report/deliverable/design/${params?.code}/project/1/latest`
      )
        .then((response) => {
          context.commit(SET_REPORT_BILLING_TRACKING_DESIGN, response?.data);

          resolve(response?.data);

          context.commit(SET_REPORT_BILLING_LOADING, false);

          showToast("Success", "Data Loaded Successfully");
        })
        .catch(() => {
          context.commit(SET_REPORT_BILLING_LOADING, false);

          showToast("Failed", "Failed Load Data", "danger");
        });
    });
  },

  [GET_REPORT_BILLING_TRACKING_DESIGN_BILLING](context, params) {
    return new Promise((resolve) => {
      context.commit(SET_REPORT_BILLING_LOADING, true);

      ApiService.query(
        `report/deliverable/design/${params?.code}/project/1/latest`
      )
        .then((response) => {
          context.commit(
            SET_REPORT_BILLING_TRACKING_DESIGN_BILLING,
            response?.data
          );

          resolve(response?.data);

          context.commit(SET_REPORT_BILLING_LOADING, false);

          showToast("Success", "Data Loaded Successfully");
        })
        .catch(() => {
          context.commit(SET_REPORT_BILLING_LOADING, false);

          showToast("Failed", "Failed Load Data", "danger");
        });
    });
  },

  [GET_REPORT_BILLING_DESIGN](context, params) {
    return new Promise((resolve) => {
      context.commit(SET_REPORT_BILLING_LOADING, true);

      ApiService.query(
        `project/billing-tracking/${params?.code}/project/design`
      )
        .then((response) => {
          context.commit(SET_REPORT_BILLING_DESIGN, response?.data);

          resolve(response?.data);

          context.commit(SET_REPORT_BILLING_LOADING, false);

          showToast("Success", "Data Loaded Successfully");
        })
        .catch(() => {
          context.commit(SET_REPORT_BILLING_LOADING, false);

          showToast("Failed", "Failed Load Data", "danger");
        });
    });
  },

  [GET_REPORT_BILLING_DISTRIBUTION](context, params) {
    return new Promise((resolve) => {
      context.commit(SET_REPORT_BILLING_LOADING, true);

      ApiService.query(
        `project/billing-tracking/${params?.code}/project/distribution/design`
      )
        .then((response) => {
          context.commit(SET_REPORT_BILLING_DISTRIBUTION, response?.data);

          resolve(response?.data);

          context.commit(SET_REPORT_BILLING_LOADING, false);

          showToast("Success", "Data Loaded Successfully");
        })
        .catch(() => {
          context.commit(SET_REPORT_BILLING_LOADING, false);

          showToast("Failed", "Failed Load Data", "danger");
        });
    });
  },

  [GET_REPORT_BILLING_CHANGE_MANAGEMENT](context, params) {
    return new Promise((resolve) => {
      context.commit(SET_REPORT_BILLING_LOADING, true);

      ApiService.query("project/design/change/management", {
        params: params,
      })
        .then((response) => {
          context.commit(SET_REPORT_BILLING_CHANGE_MANAGEMENT, response?.data);

          resolve(response?.data);

          context.commit(SET_REPORT_BILLING_LOADING, false);

          showToast("Success", "Data Loaded Successfully");
        })
        .catch(() => {
          context.commit(SET_REPORT_BILLING_LOADING, false);

          showToast("Failed", "Failed Load Data", "danger");
        });
    });
  },

  [GET_REPORT_BILLING_MINUTE_OF_MEETING](context, params) {
    return new Promise((resolve) => {
      context.commit(SET_REPORT_BILLING_LOADING, true);

      ApiService.query(`project/billing-tracking/${params?.code}/project/mom`)
        .then((response) => {
          context.commit(SET_REPORT_BILLING_MINUTE_OF_MEETING, response?.data);

          resolve(response?.data);

          context.commit(SET_REPORT_BILLING_LOADING, false);

          showToast("Success", "Data Loaded Successfully");
        })
        .catch(() => {
          context.commit(SET_REPORT_BILLING_LOADING, false);

          showToast("Failed", "Failed Load Data", "danger");
        });
    });
  },

  [GET_REPORT_BILLING_LETTER_IN_OUT](context, params) {
    return new Promise((resolve) => {
      context.commit(SET_REPORT_BILLING_LOADING, true);

      ApiService.query(
        `project/billing-tracking/${params?.code}/project/letter`
      )
        .then((response) => {
          context.commit(SET_REPORT_BILLING_LETTER_IN_OUT, response?.data);

          resolve(response?.data);

          context.commit(SET_REPORT_BILLING_LOADING, false);

          showToast("Success", "Data Loaded Successfully");
        })
        .catch(() => {
          context.commit(SET_REPORT_BILLING_LOADING, false);

          showToast("Failed", "Failed Load Data", "danger");
        });
    });
  },

  [GET_REPORT_BILLING_MAN_HOUR](context, params) {
    return new Promise((resolve) => {
      context.commit(SET_REPORT_BILLING_LOADING, true);

      ApiService.query(`report/man/hour/A/${params?.code}/project`)
        .then((response) => {
          context.commit(SET_REPORT_BILLING_MAN_HOUR, response?.data);

          resolve(response?.data);

          context.commit(SET_REPORT_BILLING_LOADING, false);

          showToast("Success", "Data Loaded Successfully");
        })
        .catch(() => {
          context.commit(SET_REPORT_BILLING_LOADING, false);

          showToast("Failed", "Failed Load Data", "danger");
        });
    });
  },

  [GET_REPORT_BILLING_MAN_HOUR_LAMPIRAN_B](context, params) {
    return new Promise((resolve) => {
      context.commit(SET_REPORT_BILLING_LOADING, true);

      ApiService.query(`report/man/hour/B/${params?.code}/project`)
        .then((response) => {
          context.commit(
            SET_REPORT_BILLING_MAN_HOUR_LAMPIRAN_B,
            response?.data
          );

          resolve(response?.data);

          context.commit(SET_REPORT_BILLING_LOADING, false);

          showToast("Success", "Data Loaded Successfully");
        })
        .catch(() => {
          context.commit(SET_REPORT_BILLING_LOADING, false);

          showToast("Failed", "Failed Load Data", "danger");
        });
    });
  },

  [GET_REPORT_BILLING_BOM_DESIGN](context, params) {
    return new Promise((resolve) => {
      context.commit(SET_REPORT_BILLING_LOADING, true);

      ApiService.query(`report/bom/${params?.code}/ALL/project`)
        .then((response) => {
          context.commit(SET_REPORT_BILLING_BOM_DESIGN, response?.data);

          resolve(response?.data);

          context.commit(SET_REPORT_BILLING_LOADING, false);

          showToast("Success", "Data Loaded Successfully");
        })
        .catch(() => {
          context.commit(SET_REPORT_BILLING_LOADING, false);

          showToast("Failed", "Failed Load Data", "danger");
        });
    });
  },

  [GET_REPORT_BILLING_SHOP_DRAWING_INTERNAL](context, params) {
    return new Promise((resolve) => {
      context.commit(SET_REPORT_BILLING_LOADING, true);

      ApiService.query(
        `report/distribution/logistic/${params?.code}/project?distribution=all`
      )
        .then((response) => {
          context.commit(
            SET_REPORT_BILLING_SHOP_DRAWING_INTERNAL,
            response?.data
          );

          resolve(response?.data);

          context.commit(SET_REPORT_BILLING_LOADING, false);

          showToast("Success", "Data Loaded Successfully");
        })
        .catch(() => {
          context.commit(SET_REPORT_BILLING_LOADING, false);

          showToast("Failed", "Failed Load Data", "danger");
        });
    });
  },

  [GET_REPORT_BILLING_SHOP_DRAWING_EXTERNAL](context, params) {
    return new Promise((resolve) => {
      context.commit(SET_REPORT_BILLING_LOADING, true);

      ApiService.query(
        `report/distribution/sde/${params?.code}/project?distribution=all`
      )
        .then((response) => {
          context.commit(
            SET_REPORT_BILLING_SHOP_DRAWING_EXTERNAL,
            response?.data
          );

          resolve(response?.data);

          context.commit(SET_REPORT_BILLING_LOADING, false);

          showToast("Success", "Data Loaded Successfully");
        })
        .catch(() => {
          context.commit(SET_REPORT_BILLING_LOADING, false);

          showToast("Failed", "Failed Load Data", "danger");
        });
    });
  },

  [GET_REPORT_BILLING_ASBUILT_DRAWING](context, params) {
    return new Promise((resolve) => {
      context.commit(SET_REPORT_BILLING_LOADING, true);

      ApiService.query(`report/distribution/asd/${params?.code}/project`)
        .then((response) => {
          context.commit(SET_REPORT_BILLING_ASBUILT_DRAWING, response?.data);

          resolve(response?.data);

          context.commit(SET_REPORT_BILLING_LOADING, false);

          showToast("Success", "Data Loaded Successfully");
        })
        .catch(() => {
          context.commit(SET_REPORT_BILLING_LOADING, false);

          showToast("Failed", "Failed Load Data", "danger");
        });
    });
  },

  [GET_REPORT_BILLING_KELENGKAPAN_DOKUMEN_PROYEK](context, params) {
    return new Promise((resolve) => {
      context.commit(SET_REPORT_BILLING_LOADING, true);

      ApiService.query(`report/document/site/${params?.code}/project`)
        .then((response) => {
          context.commit(
            SET_REPORT_BILLING_KELENGKAPAN_DOKUMEN_PROYEK,
            response?.data
          );

          resolve(response?.data);

          context.commit(SET_REPORT_BILLING_LOADING, false);

          showToast("Success", "Data Loaded Successfully");
        })
        .catch(() => {
          context.commit(SET_REPORT_BILLING_LOADING, false);

          showToast("Failed", "Failed Load Data", "danger");
        });
    });
  },

  [GET_REPORT_BILLING_TRANSMITTAL](context, params) {
    return new Promise((resolve) => {
      context.commit(SET_REPORT_BILLING_LOADING, true);

      ApiService.query(`project/transmittal?project_code=${params?.code}`)
        .then((response) => {
          context.commit(SET_REPORT_BILLING_TRANSMITTAL, response?.data);

          resolve(response?.data);

          context.commit(SET_REPORT_BILLING_LOADING, false);

          showToast("Success", "Data Loaded Successfully");
        })
        .catch(() => {
          context.commit(SET_REPORT_BILLING_LOADING, false);

          showToast("Failed", "Failed Load Data", "danger");
        });
    });
  },
};

const mutations = {
  [SET_REPORT_BILLING_LOADING](state, data) {
    state.loading = data;
  },

  [SET_REPORT_BILLING_TRACKING_DESIGN](state, data) {
    state.billingAndTracking = [];
    state.billingAndTracking = data.data?.map((item) => {
      return {
        ...item,
        numbering: item.numbering,
        title: item.title,
        station: item.station?.name,
        stationCode: item.station?.code,
        scope: item.scope?.name,
        type: item.type?.name,
        date_receive: item.date_receive,
        last_status: item.last_status?.code,
        revision_version: item.revision_version,
        version: item.version,
        planning_date: item.planning_date,
        delay: item.delay,
        monitoring_logistic_status: item.monitoring_logistic_status
          ? "Yes"
          : "No",
        monitoring_shd_external_status: item.monitoring_shd_external_status
          ? "Yes"
          : "No",
        monitoring_asbuilt_status: item.monitoring_asbuilt_status
          ? "Yes"
          : "No",
        monitoring_change_management_status: item.monitoring_change_management_status
          ? "Yes"
          : "No",
      };
    });
  },

  [SET_REPORT_BILLING_TRACKING_DESIGN_BILLING](state, data) {
    state.billingAndTrackingReport = [];
    state.billingAndTrackingReport = data.data?.map((item) => {
      return {
        ...item,
        numbering: item.numbering,
        title: item.title,
        station: item.station?.name,
        stationCode: item.station?.code,
        scope: item.scope?.name,
        type: item.type?.name,
        date_receive: item.date_receive,
        last_status: item.last_status?.code,
        revision_version: item.revision_version,
        version: item.version,
        planning_date: item.planning_date,
        delay: item.delay,
        monitoring_logistic_status: item.monitoring_logistic_status
          ? "Yes"
          : "No",
        monitoring_shd_external_status: item.monitoring_shd_external_status
          ? "Yes"
          : "No",
        monitoring_asbuilt_status: item.monitoring_asbuilt_status
          ? "Yes"
          : "No",
        monitoring_change_management_status: item.monitoring_change_management_status
          ? "Yes"
          : "No",
      };
    });
  },

  [SET_REPORT_BILLING_DESIGN](state, data) {
    state.designs = [];
    state.designs = data.data?.map((item) => {
      return {
        ...item,
        station: item.station?.name,
        type_document: item.type?.name,
        scope: item.scope?.name,
        category: item.category?.name,
        designer: item.designer_pic?.name,
        drafter: item.drafter_pic?.name,
        checker: item.checker_pic?.name,
        last_status: item.last_status?.name,
        last_revision: item.revision_version,
        last_date: item.last_date,
        planning_date: item.planning_date,
        delay: item.delay,
        monitoring_logistic_status: item.monitoring_logistic_status,
        monitoring_shd_external_status: item.monitoring_shd_external_status,
        keterangan: "",
      };
    });
  },

  [SET_REPORT_BILLING_DISTRIBUTION](state, data) {
    data.data = data.data.filter((x) => {
      if (x.monitoring_logistic_status) {
        return x.date_dist_logistic != null;
      } else if (x.monitoring_shd_external_status) {
        return x.eksternal_distribution_date != null;
      }
      return false;
    });
    state.distributions = [];
    state.distributions = data.data?.map((item) => {
      return {
        ...item,
        numbering: item.numbering,
        title: item.title,
        stasiun: item.station?.name,
        last_status: item.last_status?.name,
        last_revision: item.rev,
        last_date: item.last_date,
        date: "-",
        drawing: "-",
        transmittal: "-",
        in_floor: "-",
        needs: "-",
      };
    });
  },

  [SET_REPORT_BILLING_CHANGE_MANAGEMENT](state, data) {
    state.changeManagements = [];
    state.changeManagements = data.data?.map((item) => {
      return {
        ...item,
        numbering: item.numbering,
        type: item.type?.name,
        day: item.date,
        issue: item.issue,
        attachment: item.attachment_detail ? "YES" : "NO",
        soft_file: item.soft_file ? "YES" : "NO",
        hard_file: item.hard_file ? "YES" : "NO",
        feedback_stc: item.feedback ? "YES" : "NO",
        feedback_rte: item.feedback ? "YES" : "NO",
        feedback_tester: item.feedback ? "YES" : "NO",
      };
    });
  },

  [SET_REPORT_BILLING_MINUTE_OF_MEETING](state, data) {
    state.minuteOfMeetings = [];
    state.minuteOfMeetings = data.data?.map((item) => {
      return {
        ...item,
        mom_number: item.code,
        peserta: "-",
        tanggal: item.date,
        tempat: item.place,
        subject_rapat: item.subject,
        soft_file: item.mom_file,
        hard_file: item.mom_file,
        status: item.status,
      };
    });
  },

  [SET_REPORT_BILLING_LETTER_IN_OUT](state, data) {
    state.letterInOuts = [];
    state.letterInOuts = data.data?.map((item) => {
      return {
        ...item,
        type: item.letter_type,
        tanggal: item.date,
        nomort_surat: item.code,
        deskripsi: "-",
        pic: item.pic?.name,
        dari: item.from,
        dikirim_ke: item.to,
        remark: item.follow_up,
      };
    });
  },

  [SET_REPORT_BILLING_MAN_HOUR](state, data) {
    state.manHour = [];
    state.manHour = data.data;
  },

  [SET_REPORT_BILLING_MAN_HOUR_LAMPIRAN_B](state, data) {
    state.manHourLampiranB = [];
    state.manHourLampiranB = data.data;
  },

  [SET_REPORT_BILLING_BOM_DESIGN](state, data) {
    state.bomDesign = [];
    state.bomDesign = data.data?.map((item) => {
      return {
        ...item,
        scope: item.scope == null ? "-" : item.scope.name,
        type: item.type == null ? "-" : item.type.name,
        station: item.station == null ? "-" : item.station.name,
        outstanding: item.outstanding == null ? "-" : item.outstanding.name,
      }
    });
  },

  [SET_REPORT_BILLING_SHOP_DRAWING_INTERNAL](state, data) {
    var no = 1;
    state.shopDrawingInternal = [];
    state.shopDrawingInternal = data.data?.map((item) => {
      return {
        ...item,
        no: no++,
        title: item.title,
        stasiun: item.station == null ? "-" : item.station.name,
        scope: item.scope == null ? "-" : item.scope.name,
        type: item.type == null ? "-" : item.type.name,
        date_receive: item.date_receive == null ? "-" : item.date_receive,
        last_status_code:
          item.last_status_code == null ? "-" : item.last_status_code,
        revision_version:
          item.revision_version == null ? "-" : item.revision_version,
        version: item.version == null ? "-" : item.version,
        receiver: item.receiver == null ? "-" : item.receiver,
        transmittal_date:
          item.transmittal_date == null ? "-" : item.transmittal_date,
        logistic_receiver: item.logistic_receiver == null ? "-" : item.logistic_receiver.name,
      };
    });
  },

  [SET_REPORT_BILLING_SHOP_DRAWING_EXTERNAL](state, data) {
    var no = 1;
    state.shopDrawingExternal = [];
    state.shopDrawingExternal = data.data?.map((item) => {
      return {
        ...item,
        no: no++,
        numbering: item.numbering,
        title: item.title,
        type: item.type == null ? "-" : item.type.name,
        status:
          item.last_status == null
            ? "-"
            : item.last_status.code,
        stasiun: item.station == null ? "-" : item.station.name,
        scope: item.scope == null ? "-" : item.scope.name,
        revisi: item.revision_version == null ? "-" : item.revision_version,
        deadline: item.deadline == null ? "-" : item.deadline,
        drafter_pic: item.drafter_pic == null ? "-" : item.drafter_pic.name,
        received_date: item.date_receive == null ? "-" : item.date_receive,
        transmittal_number:
          item.transmittal_number == null ? "-" : item.transmittal_number,
        keterangan: item.shd_comment == null ? "-" : item.shd_comment,
        date_receive: item.date_receive == null ? "-" : item.date_receive,
        eksternal_receiver: item.eksternal_receiver == null ? "-" : item.eksternal_receiver.name
      };
    });
  },

  [SET_REPORT_BILLING_ASBUILT_DRAWING](state, data) {
    var no = 1;
    state.asbuiltDrawing = [];
    state.asbuiltDrawing = data.data?.map((item) => {
      return {
        ...item,
        no: no++,
        numbering: item.numbering,
        title: item.title,
        external_numbering: item.external_numbering,
        asbuilt_drawing_numbering: item.asbuilt_drawing_numbering,
        type: item.type == null ? "-" : item.type.name,
        status: item.status == null ? "-" : item.status.code,
        stasiun: item.station == null ? "-" : item.station.name,
        scope: item.scope.name,
        revisi: item.revision_version == null ? "-" : item.revision_version,
        received_date: item.date_receive == null ? "-" : item.date_receive,
        date_receive: item.date_receive == null ? "-" : item.date_receive,
        version: item.version,
        asbuilt_distribution_date:
          item.asbuilt_distribution_date == null
            ? "-"
            : item.asbuilt_distribution_date,
      };
    });
  },

  [SET_REPORT_BILLING_KELENGKAPAN_DOKUMEN_PROYEK](state, data) {
    var no = 1;
    state.kelengkapanDokumenProyek = [];
    state.kelengkapanDokumenProyek = data.data?.map((item) => {
      return {
        ...item,
        no: no++,
        numbering: item.numbering,
        category: item.site_document_type.category.name,
        item: item.site_document_type.item,
        complete: item.complete,
        description: item.description == null ? "-" : item.description,
        note: item.note == null ? "-" : item.note,
        date: item.site_document_date == null ? "-" : item.site_document_date,
        storage: item.storage == null ? "-" : item.storage,
      };
    });
  },

  [SET_REPORT_BILLING_TRANSMITTAL](state, data) {
    state.transmittal = [];
    state.transmittal = data.data;
    state.transmittal = data.data?.map((item) => {
      return {
        ...item,
        code: item.code,
        name: item.name,
        nickname: item.nickname,
        item: item,
      };
    });
  },
};

export default {
  state,
  actions,
  mutations,
};
