import ApiService from "../api.service";
export const GET_PLANING_DATE_DOC = "getPlaningDateDoc";
export const GET_PLANING_DATE_DRW = "getPlaningDateDrw";
export const GET_PLANING_DATE_FOR = "getPlaningDateFor";
export const POST_PLANING_DATE = "postPlaningDate";
export const CLEAN_STATE = "cleanState";

export const SET_ERROR = "setError";
export const SET_LOADING = "setLoading";
export const SET_PLANING_DATE_DOC = "setPlaningDateDoc";
export const SET_PLANING_DATE_DRW = "setPlaningDateDrw";
export const SET_PLANING_DATE_FOR = "setPlaningDateFor";
export const SET_RESULT_POST = "setResultPost";

const state = {
  PlaningDateDoc: {
    data: [],
    code: null,
    message: null
  },
  PlaningDateDrw: {
    data: [],
    code: null,
    message: null
  },
  PlaningDateFor: {
    data: [],
    code: null,
    message: null
  },
  loading: false,
  errors: {},
  resultPost: {
    data: [],
    code: null,
    message: null
  }
};

const getters = {
  loadingDesign(state) {
    return state.loading;
  },

  getterPlaningDateDoc(state) {
    return state.PlaningDateDoc?.data?.dld;
  },

  getterPlaningDateFor(state) {
    return state.PlaningDateFor?.data?.dld;
  },

  getterPlaningDateDrw(state) {
    return state.PlaningDateDrw?.data?.dld;
  }
};

const actions = {
  [CLEAN_STATE](context, params) {
    context.commit(SET_LOADING, true);
    if (params === "DOC") {
      context.commit(SET_PLANING_DATE_DOC, []);
    } else if (params === "DRW") {
      context.commit(SET_PLANING_DATE_DRW, []);
    } else if (params === "FOR") {
      context.commit(SET_PLANING_DATE_FOR, []);
    }

    context.commit(SET_LOADING, false);
  },
  [GET_PLANING_DATE_DOC](context, params) {
    return new Promise((resolve, reject) => {
      context.commit(SET_LOADING, true);
      ApiService.setHeader();
      ApiService.query(`project/${params.project_id}/DOC/dld`, params)
        .then(({ data }) => {
          resolve(data);
          context.commit(SET_PLANING_DATE_DOC, data);
          context.commit(SET_LOADING, false);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_PLANING_DATE_DRW](context, params) {
    return new Promise((resolve, reject) => {
      context.commit(SET_LOADING, true);
      ApiService.setHeader();
      ApiService.query(`project/${params.project_id}/DRW/dld`, params)
        .then(({ data }) => {
          context.commit(SET_PLANING_DATE_DRW, data);
          resolve(data);
          context.commit(SET_LOADING, false);
        })
        .catch(({ response }) => {
          context.commit(SET_LOADING, false);
          reject(response);
          context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_PLANING_DATE_FOR](context, params) {
    return new Promise((resolve, reject) => {
      context.commit(SET_LOADING, true);
      ApiService.setHeader();
      ApiService.query(`project/${params.project_id}/FOR/dld`, params)
        .then(({ data }) => {
          context.commit(SET_LOADING, false);
          context.commit(SET_PLANING_DATE_FOR, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_LOADING, false);
          reject(response);
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  [POST_PLANING_DATE](context, param) {
    return new Promise(resolve => {
      ApiService.setHeader();
      ApiService.post("project/dld/planing/date", param)
        .then(({ data }) => {
          context.commit(SET_RESULT_POST, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  }
};

const mutations = {
  [SET_RESULT_POST](state, PlaningDate) {
    state.resultPost = PlaningDate;
  },

  [SET_ERROR](state, error) {
    state.errors = error;
  },

  [SET_LOADING](state, loading) {
    state.loading = loading;
  },

  [SET_PLANING_DATE_DRW](state, PlaningDateDrw) {
    state.PlaningDateDrw = PlaningDateDrw;
  },

  [SET_PLANING_DATE_FOR](state, PlaningDateFor) {
    state.PlaningDateFor = PlaningDateFor;
  },

  [SET_PLANING_DATE_DOC](state, PlaningDateDoc) {
    state.PlaningDateDoc = PlaningDateDoc;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
