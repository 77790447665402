import ApiService from "../api.service";
import { showToast } from "../../helper/toast.helper";

export const GET_CHANGE_MANAGEMENT_TYPE = "getChangeManagementType";
export const DELETE_CHANGE_MANAGEMENT_TYPE = "deleteChangeManagementType";
export const SAVE_CHANGE_MANAGEMENT_TYPE = "saveChangeManagementType";
export const UPDATE_CHANGE_MANAGEMENT_TYPE = "updateChangeManagementType";

export const SET_ERROR = "setError";
export const SET_CHANGE_MANAGEMENT_TYPE = "setChangeManagementType";
export const SET_DELETE_CHANGE_MANAGEMENT_TYPE =
  "setDeleteChangeManagementType";
export const SET_SAVE_CHANGE_MANAGEMENT_TYPE = "setSaveChangeManagementType";
export const SET_UPDATE_CHANGE_MANAGEMENT_TYPE =
  "setUpdateChangeManagementType";
export const SET_LOADING_CHANGE_MANAGEMENT_TYPE =
  "setLoadingChangeManagementType";

const state = {
  changeManagementTypes: {},
  errors: {},
  loading: false
};

const getters = {
  currentChangeManagementType(state) {
    return state.changeManagementTypes.data;
  }
};

const actions = {
  [GET_CHANGE_MANAGEMENT_TYPE](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_CHANGE_MANAGEMENT_TYPE, true);

      ApiService.query("change/management/type", params)
        .then(({ data }) => {
          context.commit(SET_CHANGE_MANAGEMENT_TYPE, data);

          resolve(data);

          context.commit(SET_LOADING_CHANGE_MANAGEMENT_TYPE, false);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_CHANGE_MANAGEMENT_TYPE, false);
        });
    });
  },

  [DELETE_CHANGE_MANAGEMENT_TYPE](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_CHANGE_MANAGEMENT_TYPE, true);

      ApiService.setHeader();

      ApiService.delete(`change/management/type/${params.code}/delete`)
        .then(({ data }) => {
          context.commit(SET_DELETE_CHANGE_MANAGEMENT_TYPE, params.code);

          resolve(data);

          context.commit(SET_LOADING_CHANGE_MANAGEMENT_TYPE, false);

          showToast("Success", "Data Deleted Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_CHANGE_MANAGEMENT_TYPE, false);

          showToast("Failed", "Failed Delete Data", "danger");
        });
    });
  },

  [SAVE_CHANGE_MANAGEMENT_TYPE](context, body) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_CHANGE_MANAGEMENT_TYPE, true);

      ApiService.setHeader();

      ApiService.post("change/management/type", body)
        .then(({ data }) => {
          context.commit(SET_SAVE_CHANGE_MANAGEMENT_TYPE, data?.data);

          resolve(data);

          context.commit(SET_LOADING_CHANGE_MANAGEMENT_TYPE, false);

          showToast("Success", "Data Saved Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_CHANGE_MANAGEMENT_TYPE, false);

          showToast("Failed", "Failed Save Data", "danger");
        });
    });
  },

  [UPDATE_CHANGE_MANAGEMENT_TYPE](context, body) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_CHANGE_MANAGEMENT_TYPE, true);

      ApiService.setHeader();

      ApiService.put("change/management/type", body)
        .then(({ data }) => {
          context.commit(SET_UPDATE_CHANGE_MANAGEMENT_TYPE, data?.data);

          resolve(data);

          context.commit(SET_LOADING_CHANGE_MANAGEMENT_TYPE, false);

          showToast("Success", "Data Updated Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_CHANGE_MANAGEMENT_TYPE, false);

          showToast("Failed", "Failed Update Data", "danger");
        });
    });
  }
};

const mutations = {
  [SET_CHANGE_MANAGEMENT_TYPE](state, changeManagementTypes) {
    state.changeManagementTypes = changeManagementTypes;
  },

  [SET_ERROR](state, error) {
    state.errors = error;
  },

  [SET_SAVE_CHANGE_MANAGEMENT_TYPE](state, changeManagementType) {
    state.changeManagementTypes.data = [
      changeManagementType,
      ...state.changeManagementTypes.data
    ];
  },

  [SET_LOADING_CHANGE_MANAGEMENT_TYPE](state, loading) {
    state.loading = loading;
  },

  [SET_UPDATE_CHANGE_MANAGEMENT_TYPE](state, updateStation) {
    const index = state.changeManagementTypes?.data?.findIndex(
      changeManagementType => changeManagementType.code === updateStation.code
    );

    if (index >= 0) {
      Object.assign(state.changeManagementTypes?.data[index], updateStation);
    }
  },

  [SET_DELETE_CHANGE_MANAGEMENT_TYPE](state, code) {
    const index = state.changeManagementTypes?.data?.findIndex(
      changeManagementType => changeManagementType.code === code
    );

    if (index >= 0) {
      state.changeManagementTypes?.data?.splice(index, 1);
    }
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
