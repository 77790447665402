import ApiService from "../api.service";
import { showToast } from "../../helper/toast.helper";

export const GET_REPORT_BOM_DESIGN = "getReportBomDesign";

export const SET_ERROR = "setError";
export const SET_REPORT_BOM_DESIGN = "setReportBomDesign";
export const SET_LOADING_REPORT_BOM_DESIGN = "setLoadingReportBomDesign";

const state = {
  reportBomDesign: {},
  errors: {},
  loading: false
};

const getters = {
  currentReportBomDesign(state) {
    return state.reportBomDesign?.data;
  }
};

const actions = {
  [GET_REPORT_BOM_DESIGN](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_REPORT_BOM_DESIGN, true);
      ApiService.get(
        "report/bom",
        `${params.code_project}/${params.type_document}/project?latest=` +
        params.latest
      )
        .then(({ data }) => {
          context.commit(SET_REPORT_BOM_DESIGN, data);
          resolve(data);
          context.commit(SET_LOADING_REPORT_BOM_DESIGN, false);
          showToast("Success", "Data Loaded Successfully");
        })
        .catch(response => {
          context.commit(SET_ERROR, response?.data?.errors);
          context.commit(SET_LOADING_REPORT_BOM_DESIGN, false);
          showToast("Failed", "Failed Load Data", "danger");
        });
    });
  }
};

const mutations = {
  [SET_REPORT_BOM_DESIGN](state, BomDesign) {
    state.reportBomDesign = BomDesign;
  },

  [SET_LOADING_REPORT_BOM_DESIGN](state, loading) {
    state.loading = loading;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
