import ApiService from "../api.service";
import { showToast } from "../../helper/toast.helper";

export const GET_DESIGN_REFERENCE_TYPE = "getDesignReferenceType";
export const DELETE_DESIGN_REFERENCE_TYPE = "deleteDesignReferenceType";
export const SAVE_DESIGN_REFERENCE_TYPE = "saveDesignReferenceType";
export const UPDATE_DESIGN_REFERENCE_TYPE = "updateDesignReferenceType";

export const SET_ERROR = "setError";
export const SET_DESIGN_REFERENCE_TYPE = "setDesignReferenceType";
export const SET_DELETE_DESIGN_REFERENCE_TYPE = "setDeleteDesignReferenceType";
export const SET_SAVE_DESIGN_REFERENCE_TYPE = "setSaveDesignReferenceType";
export const SET_UPDATE_DESIGN_REFERENCE_TYPE = "setUpdateDesignReferenceType";
export const SET_LOADING_DESIGN_REFERENCE_TYPE =
  "setLoadingDesignReferenceType";

const state = {
  designReferenceTypes: {},
  errors: {},
  loading: false
};

const actions = {
  [GET_DESIGN_REFERENCE_TYPE](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_DESIGN_REFERENCE_TYPE, true);

      ApiService.query("type/design/reference", params)
        .then(({ data }) => {
          context.commit(SET_DESIGN_REFERENCE_TYPE, data);

          resolve(data);

          context.commit(SET_LOADING_DESIGN_REFERENCE_TYPE, false);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_DESIGN_REFERENCE_TYPE, false);
        });
    });
  },

  [DELETE_DESIGN_REFERENCE_TYPE](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_DESIGN_REFERENCE_TYPE, true);

      ApiService.setHeader();

      ApiService.delete(`type/design/reference/${params.code}/delete`)
        .then(({ data }) => {
          context.commit(SET_DELETE_DESIGN_REFERENCE_TYPE, params.code);

          resolve(data);

          context.commit(SET_LOADING_DESIGN_REFERENCE_TYPE, false);

          showToast("Success", "Data Deleted Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_DESIGN_REFERENCE_TYPE, false);

          showToast("Failed", "Failed Delete Data", "danger");
        });
    });
  },

  [SAVE_DESIGN_REFERENCE_TYPE](context, body) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_DESIGN_REFERENCE_TYPE, true);

      ApiService.setHeader();

      ApiService.post("type/design/reference", body)
        .then(({ data }) => {
          context.commit(SET_SAVE_DESIGN_REFERENCE_TYPE, data?.data);

          resolve(data);

          context.commit(SET_LOADING_DESIGN_REFERENCE_TYPE, false);

          showToast("Success", "Data Saved Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_DESIGN_REFERENCE_TYPE, false);

          showToast("Failed", "Failed Save Data", "danger");
        });
    });
  },

  [UPDATE_DESIGN_REFERENCE_TYPE](context, body) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_DESIGN_REFERENCE_TYPE, true);

      ApiService.setHeader();

      ApiService.put("type/design/reference", body)
        .then(({ data }) => {
          context.commit(SET_UPDATE_DESIGN_REFERENCE_TYPE, data?.data);

          resolve(data);

          context.commit(SET_LOADING_DESIGN_REFERENCE_TYPE, false);

          showToast("Success", "Data Updated Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_DESIGN_REFERENCE_TYPE, false);

          showToast("Failed", "Failed Update Data", "danger");
        });
    });
  }
};

const mutations = {
  [SET_DESIGN_REFERENCE_TYPE](state, designReferenceTypes) {
    state.designReferenceTypes = designReferenceTypes;
  },

  [SET_ERROR](state, error) {
    state.errors = error;
  },

  [SET_SAVE_DESIGN_REFERENCE_TYPE](state, designReferenceType) {
    state.designReferenceTypes.data = [
      designReferenceType,
      ...state.designReferenceTypes.data
    ];
  },

  [SET_LOADING_DESIGN_REFERENCE_TYPE](state, loading) {
    state.loading = loading;
  },

  [SET_UPDATE_DESIGN_REFERENCE_TYPE](state, updateStation) {
    const index = state.designReferenceTypes?.data?.findIndex(
      designReferenceType => designReferenceType.code === updateStation.code
    );

    if (index >= 0) {
      Object.assign(state.designReferenceTypes?.data[index], updateStation);
    }
  },

  [SET_DELETE_DESIGN_REFERENCE_TYPE](state, code) {
    const index = state.designReferenceTypes?.data?.findIndex(
      designReferenceType => designReferenceType.code === code
    );

    if (index >= 0) {
      state.designReferenceTypes?.data?.splice(index, 1);
    }
  }
};

export default {
  state,
  actions,
  mutations
};
