import ApiService from "../api.service";
import { showToast } from "../../helper/toast.helper";

export const GET_REPORT_SHOP_DRAWING_EXT = "getReportShopDrawingExt";

export const SET_ERROR = "setError";
export const SET_REPORT_SHOP_DRAWING_EXT = "setReportShopDrawingExt";
export const SET_LOADING_REPORT_SHOP_DRAWING_EXT =
  "setLoadingReportShopDrawingExt";

const state = {
  reportShopDrawingExt: {},
  errors: {},
  loading: false
};

const getters = {
  currentReportShopDrawingExt(state) {
    return state.reportShopDrawingExt?.data;
  }
};

const actions = {
  [GET_REPORT_SHOP_DRAWING_EXT](context, params) {
    return new Promise(resolve => {
      ApiService.setHeader();
      context.commit(SET_LOADING_REPORT_SHOP_DRAWING_EXT, true);
      ApiService.query(
        `report/distribution/sde/${params.project_id}/project?latest=` +
          params.latest,
        params
      )
        .then(({ data }) => {
          context.commit(SET_REPORT_SHOP_DRAWING_EXT, data);
          resolve(data);
          context.commit(SET_LOADING_REPORT_SHOP_DRAWING_EXT, false);
          showToast("Success", "Data Loaded Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response?.data?.errors);
          context.commit(SET_LOADING_REPORT_SHOP_DRAWING_EXT, false);
          showToast("Failed", "Failed Load Data", "danger");
        });
    });
  }
};

const mutations = {
  [SET_REPORT_SHOP_DRAWING_EXT](state, data) {
    state.reportShopDrawingExt = data;
  },

  [SET_LOADING_REPORT_SHOP_DRAWING_EXT](state, loading) {
    state.loading = loading;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
