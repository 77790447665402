// action types
import ApiService from "../api.service";
import { showToast } from "../../helper/toast.helper";

export const GET_DESIGN_MANAGEMENT_PLAN = "getDesignManagementPlan";
export const GET_ONE_DESIGN_MANAGEMENT_PLAN_BY_CODE =
  "getOneDesignManagementPlanByCode";
export const UPDATE_DESIGN_MANAGEMENT_PLAN = "updateDesignManagementPlan";
export const UPDATE_DRAFT_DESIGN_MANAGEMENT_PLAN =
  "updateDraftDesignManagementPlan";
export const UPDATE_REVISION_DESIGN_MANAGEMENT_PLAN =
  "updateRevisionDesignManagementPlan";
export const UPLOAD_REVISION_DESIGN_MANAGEMENT_PLAN =
  "uploadRevisionDesignManagementPlan";
// mutation types
export const SET_DESIGN_MANAGEMENT_PLAN = "setDesignManagementPlan";
export const SET_CURRENT_EDIT_DESIGN_MANAGEMENT_PLAN =
  "setCurrentDesignManagementPlan";
export const SET_LOADING_DESIGN_MANAGEMENT_PLAN =
  "setLoadingDesignManagementPlan";
export const SET_ERROR_DESIGN_MANAGEMENT_PLAN = "setErrorDesignManagementPlan";

const state = {
  loading: false,
  errors: {},
  designManagementPlan: {},
  currentDesignManagementPlan: null
};

const actions = {
  /**
   * Add breadcrumb
   * @param context
   * @param params
   */
  [GET_DESIGN_MANAGEMENT_PLAN](context, params) {
    return new Promise((resolve, reject) => {
      context.commit(SET_LOADING_DESIGN_MANAGEMENT_PLAN, true);

      ApiService.setHeader();

      ApiService.query(
        `project/${params.params.job_desk}/design/management/plan`,
        params
      )
        .then(({ data }) => {
          resolve(data);

          context.commit(SET_DESIGN_MANAGEMENT_PLAN, data);

          context.commit(SET_LOADING_DESIGN_MANAGEMENT_PLAN, false);

          showToast("Success", "Data Loaded Successfully");
        })
        .catch(({ response }) => {
          reject(response);

          context.commit(
            SET_ERROR_DESIGN_MANAGEMENT_PLAN,
            response.data.errors
          );

          context.commit(SET_LOADING_DESIGN_MANAGEMENT_PLAN, false);

          showToast("Failed", "Failed Load Data", "danger");
        });
    });
  },

  [UPDATE_DESIGN_MANAGEMENT_PLAN](context, params) {
    return new Promise((resolve, reject) => {
      context.commit(SET_LOADING_DESIGN_MANAGEMENT_PLAN, true);

      ApiService.setHeader();

      ApiService.post("project/design/management/plan/fix", params)
        .then(({ data }) => {
          resolve(data);

          context.commit(SET_LOADING_DESIGN_MANAGEMENT_PLAN, false);

          showToast("Success", "Data Loaded Successfully");
        })
        .catch(({ response }) => {
          reject(response);

          context.commit(
            SET_ERROR_DESIGN_MANAGEMENT_PLAN,
            response.data.errors
          );

          context.commit(SET_LOADING_DESIGN_MANAGEMENT_PLAN, false);

          showToast("Failed", "Failed Load Data", "danger");
        });
    });
  },

  [UPDATE_DRAFT_DESIGN_MANAGEMENT_PLAN](context, params) {
    return new Promise((resolve, reject) => {
      context.commit(SET_LOADING_DESIGN_MANAGEMENT_PLAN, true);

      ApiService.setHeader();

      ApiService.post("project/design/management/plan/draft", params)
        .then(({ data }) => {
          resolve(data);

          context.commit(SET_LOADING_DESIGN_MANAGEMENT_PLAN, false);

          showToast("Success", "Data Loaded Successfully");
        })
        .catch(({ response }) => {
          reject(response);

          context.commit(
            SET_ERROR_DESIGN_MANAGEMENT_PLAN,
            response.data.errors
          );

          context.commit(SET_LOADING_DESIGN_MANAGEMENT_PLAN, false);

          showToast("Failed", "Failed Load Data", "danger");
        });
    });
  },

  [UPDATE_REVISION_DESIGN_MANAGEMENT_PLAN](context, params) {
    return new Promise((resolve, reject) => {
      context.commit(SET_LOADING_DESIGN_MANAGEMENT_PLAN, true);

      ApiService.setHeader();

      ApiService.post("project/design/management/plan/revision", params)
        .then(({ data }) => {
          resolve(data);

          context.commit(SET_LOADING_DESIGN_MANAGEMENT_PLAN, false);

          showToast("Success", "Data Loaded Successfully");
        })
        .catch(({ response }) => {
          reject(response);

          context.commit(
            SET_ERROR_DESIGN_MANAGEMENT_PLAN,
            response.data.errors
          );

          context.commit(SET_LOADING_DESIGN_MANAGEMENT_PLAN, false);

          showToast("Failed", "Failed Load Data", "danger");
        });
    });
  },
  [UPLOAD_REVISION_DESIGN_MANAGEMENT_PLAN](context, files) {
    return new Promise((resolve, reject) => {
      ApiService.setHeaderMultiPlatform();
      ApiService.post("project/design/management/upload_server", files)
        .then(({ data }) => {
          resolve(data);
          showToast("Success", "Document Uploaded Successfully");
        })
        .catch(({ response }) => {
          reject(response);
          showToast("Failed", "Failed Upload Document");
        });
    });
  },

  [GET_ONE_DESIGN_MANAGEMENT_PLAN_BY_CODE](context, params) {
    // context.commit(SET_LOADING_DESIGN_MANAGEMENT_PLAN, true);
    //
    // context.commit(SET_CURRENT_EDIT_DESIGN_MANAGEMENT_PLAN, params?.code);

    return new Promise((resolve, reject) => {
      context.commit(SET_LOADING_DESIGN_MANAGEMENT_PLAN, true);

      ApiService.setHeader();

      ApiService.query(
        `project/${params.params.code}/design/management/plan/detail`,
        params
      )
        .then(({ data }) => {
          resolve(data);

          context.commit(SET_CURRENT_EDIT_DESIGN_MANAGEMENT_PLAN, data);

          context.commit(SET_LOADING_DESIGN_MANAGEMENT_PLAN, false);

          showToast("Success", "Load Data Successfully");
        })
        .catch(response => {
          reject(response);

          context.commit(SET_LOADING_DESIGN_MANAGEMENT_PLAN, false);
        });
    });
  }
};

const mutations = {
  [SET_DESIGN_MANAGEMENT_PLAN](state, data) {
    const uniqueIds = [];
    var uniqueDatas = data.data.filter(element => {
      const isDuplicate = uniqueIds.includes(element.code);
      if (!isDuplicate) {
        uniqueIds.push(element.code);
        return true;
      }
      return false;
    });
    data.data = uniqueDatas;
    state.designManagementPlan = data;
  },

  [SET_LOADING_DESIGN_MANAGEMENT_PLAN](state, loading) {
    state.loading = loading;
  },

  [SET_CURRENT_EDIT_DESIGN_MANAGEMENT_PLAN](state, data) {
    state.currentDesignManagementPlan = data;

    // state.currentDesignManagementPlan = state.designManagementPlan?.data?.find(
    //   design => design?.code === code
    // );

    // state.loading = false;
  }
};

export default {
  state,
  actions,
  mutations
};
