import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import profile from "./profile.module";
import position from "./position.module";
import menu from "./menu.module";
import projectDetails from "./project.module";
import stations from "./station.module";
import pic from "./pic.module";
import design from "./dl.design.module";
import company from "./company.module";
import scope from "./scope.module";
import outstanding from "./outstanding.module";
import designManagementPlan from "./design-management-plan.module";
import asBuiltDrawing from "./asbuilt-drawing.module";
import status from "./status.module";
import distribution from "./distribution.module";
import monitoringLogistic from "./monitoring-logistic.module";
import monitoringShopDrawing from "./monitor-shop-drawing.module";
import dokumenPenunjang from "./document-penunjang.module";
import manhour from "./manhour.module";
import planingDate from "./planing.date.module";
import ChangeManagement from "./change-management.module";
import transmittal from "./transmittal.module";
import subSystemOfWork from "./subsystem-of-work.module";
import division from "./division.module";
import department from "./department.module";
import siteDocumentCategory from "./site-document-category.module";
import designReferenceType from "./design-reference-type.module";
import scopeCategory from "./scope-category.module";
import changeManagementType from "./change-management-type.module";
import outstandingCategory from "./outstanding-category.module";
import scopeOfDesign from "./scope-of-design.module";
import siteDocumentType from "./site-document-type.module";
import masterDesign from "./design.module";
import meeting from "./meeting.module";
import documentSiteProject from "./document-site-project.module";
import deliverableDesign from "./reporting-deliverable-design.module";
import reportingDistributionLogistic from "./reporting-distribution-logistic.module";
import reportDocumentSiteProject from "./report-document-site-project.module";
import reportLettering from "./report-lettering.module";
import reportShopDrawing from "./report-shop-drawing-ext.module";
import lettering from "./lettering.module";
import dashboardMain from "./dashboard-main.module";
import siteDocument from "./site-document.module";
import user from "./user.module";
import role from "./role.module";
import category from "./category.module";
import reportingChangeManagement from "./reporting-change-management.module";
import reportingManHour from "./reporting-man-hour.module";
import reportAsBuiltDrawing from "./report-asbuilt-drawing.module";
import ftp from "./ftp.module";
import reportBomDesign from "./reporting-bom-design.module";
import dashboardProject from "./dashboard-project.module";
import reportTransmittal from "./report-transmittal.module";
import reportBillingTracking from "./report-billing-tracking.module";
import logs from "./logs.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    profile,
    position,
    menu,
    projectDetails,
    stations,
    pic,
    company,
    scope,
    design,
    outstanding,
    designManagementPlan,
    asBuiltDrawing,
    status,
    distribution,
    monitoringLogistic,
    monitoringShopDrawing,
    dokumenPenunjang,
    transmittal,
    manhour,
    planingDate,
    ChangeManagement,
    subSystemOfWork,
    division,
    department,
    siteDocumentCategory,
    designReferenceType,
    scopeCategory,
    changeManagementType,
    outstandingCategory,
    scopeOfDesign,
    siteDocumentType,
    masterDesign,
    meeting,
    deliverableDesign,
    reportingDistributionLogistic,
    documentSiteProject,
    reportDocumentSiteProject,
    reportLettering,
    reportShopDrawing,
    lettering,
    dashboardMain,
    siteDocument,
    user,
    role,
    reportingChangeManagement,
    reportAsBuiltDrawing,
    document,
    dashboardProject,
    reportingManHour,
    reportBomDesign,
    ftp,
    reportTransmittal,
    reportBillingTracking,
    category,
    logs
  }
});
