import ApiService from "../api.service";
import { showToast } from "../../helper/toast.helper";

export const GET_CHECKLIST_PENUNJANG = "getChecklistPenunjang";
export const GET_SUMMARY_CHECKLIST_PENUNJANG = "getSummaryChecklistPenunjang";
export const POST_DOCUMENT_FORM = "postDocumentForm";
export const UPDATE_DOCUMENT_FORM = "updateDocumentForm";
export const SET_CHECKLIST_PENUNJANG = "setChecklistPenunjang";
export const SET_SUMMARY_CHECKLIST_PENUNJANG = "setSummaryChecklistPenunjang";
export const SET_MASTER_PENUNJANG = "setMasterPenunjang";
export const SET_LOADING_UPLOAD = "setLoadingUpload";
export const SET_LOADING = "setLoadingGetChecklistPenunjang";

const state = {
  list_penunjang: [],
  master_penunjang: [],
  detail_document: {},
  start_page: "",
  end_page: "",
  active_page: "",
  total_page: "",
  loadingUpload: false,
  loading: false
};

const getters = {
  getterListPenunjang(state) {
    return state.list_penunjang;
  },

  getterMasterPenunjang(state) {
    return state.master_penunjang;
  },

  getterStartPage(state) {
    return state.start_page;
  },

  getterEndPage(state) {
    return state.end_page;
  },

  getterActivePage(state) {
    return state.active_page;
  },

  getterTotalPage(state) {
    return state.total_page;
  }
};

const actions = {
  [GET_CHECKLIST_PENUNJANG](context, param) {
    context.commit(SET_LOADING, true);

    const url =
      "project/" + param.jobdesk + "/document/support?nickname&status";

    ApiService.setHeader();

    ApiService.query(url, {
      params: {
        status: param?.status,
        nickname: param?.nickname
      }
    }).then(res => {
      context.commit(SET_CHECKLIST_PENUNJANG, res.data.data);

      context.commit(SET_MASTER_PENUNJANG, res.data.data);

      context.commit(SET_LOADING, false);
    });
  },

  [GET_SUMMARY_CHECKLIST_PENUNJANG](context, param) {
    context.commit(SET_LOADING, true);

    const url = "project/dld/sum/manhour/plandate";

    ApiService.setHeader();

    ApiService.query(url, {
      params: {
        project_code: param?.nickname
      }
    }).then(res => {
      context.commit(SET_SUMMARY_CHECKLIST_PENUNJANG, res.data.data);

      context.commit(SET_LOADING, false);
    });
  },

  [POST_DOCUMENT_FORM](context, form) {
    ApiService.setHeader();

    return ApiService.query("ftp/folder/upload", {
      params: {
        ...form
      }
    });
  },

  [UPDATE_DOCUMENT_FORM](context, form) {
    context.commit(SET_LOADING_UPLOAD, true);

    return new Promise(resolve => {
      ApiService.setHeader();

      ApiService.post("project/support/doc/upload", form)
        .then(({ data }) => {
          resolve(data);

          context.commit(SET_LOADING_UPLOAD, false);

          const url = "project/design/document/support?nickname&status";

          showToast("Success", "Document Uploaded Successfully.");

          ApiService.get(url).then(res => {
            context.commit(SET_CHECKLIST_PENUNJANG, res.data.data);

            context.commit(SET_MASTER_PENUNJANG, res.data.data);
          });
        })
        .catch(() => {
          showToast("Failed", "Failed Update Project", "danger");
        });
    });
  }
};

const mutations = {
  [SET_CHECKLIST_PENUNJANG](state, data) {
    state.list_penunjang = data;
  },

  [SET_SUMMARY_CHECKLIST_PENUNJANG](state, data) {
    state.detail_document = data;
  },

  [SET_MASTER_PENUNJANG](state, data) {
    state.master_penunjang = data;
  },

  [SET_LOADING_UPLOAD](state, data) {
    state.loadingUpload = data;
  },

  [SET_LOADING](state, data) {
    state.loading = data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
