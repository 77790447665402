import ApiService from "../api.service";

export const GET_LOGS_ACTIVITY = "getLogActivity";
export const SET_LOG_ACTIVITY = "setLogActivity";

export const GET_LAST_LOG_SEEN_ACTIVITY = "getLastLogSeenActivity";
export const SET_LAST_LOG_SEEN_ACTIVITY = "setLastLogSeenActivity";

export const ADD_NEW_LOG_ACTIVITY = "addNewLogActivity";
export const SET_ADD_NEW_LOG_ACTIVITY = "setAddNewLogActivity";

export const UPDATE_LAST_LOG_SEEN_ACTIVITY = "updateLastLogSeenActivity";

export const UPDATE_LOG_ACTIVITY = "UpdateLogActivity";
export const SET_UPDATE_LOG_ACTIVITY = "setUpdateLogActivity";

export const UPDATE_LOG_ACTIVITY_READ_ALL = "UpdateLogActivityReadAll";
export const UPDATE_LOG_ACTIVITY_READ_CHECKED = "UpdateLogActivityReadChecked";
const state = {
  logsActivity: {
    data: [],
    code: null,
    message: null
  },
  lastLogseen: 0,
  errors: {},
  loading: false
};

const getters = {
  currentLogActivity(state) {
    return state.logsActivity?.data;
  },
  lastLogSeen(state) {
    return state.lastLogseen?.data;
  }
};

const actions = {
  async [GET_LOGS_ACTIVITY](context, params) {
    return await new Promise(resolve => {
      ApiService.query("notifications/list?take=" + params).then(({ data }) => {
        context.commit(SET_LOG_ACTIVITY, data);
        resolve(data);
      });
    });
  },
  async [GET_LAST_LOG_SEEN_ACTIVITY](context) {
    return await new Promise(resolve => {
      ApiService.query("notifications/counter").then(({ data }) => {
        context.commit(SET_LAST_LOG_SEEN_ACTIVITY, data);
        resolve(data);
      });
    });
  },
  async [UPDATE_LAST_LOG_SEEN_ACTIVITY](context, params) {
    return await new Promise(resolve => {
      ApiService.setHeader();
      ApiService.post(
        "notifications/update_notification_opened?id=" + params
      ).then(({ data }) => {
        context.commit(SET_LAST_LOG_SEEN_ACTIVITY, data);
        resolve(data);
      });
    });
  },
  async [UPDATE_LOG_ACTIVITY_READ_ALL]() {
    return await new Promise(resolve => {
      ApiService.setHeader();
      ApiService.post("notifications/update_all_notification_opened").then(
        ({ data }) => {
          resolve(data);
        }
      );
    });
  },
  async [UPDATE_LOG_ACTIVITY_READ_CHECKED](context, params) {
    return await new Promise(resolve => {
      ApiService.setHeader();
      ApiService.post("notifications/update_checked_notification_opened", {
        data: params
      }).then(({ data }) => {
        resolve(data);
      });
    });
  },
  [ADD_NEW_LOG_ACTIVITY](context, params) {
    context.commit(SET_ADD_NEW_LOG_ACTIVITY, params);
  },
  [UPDATE_LOG_ACTIVITY](context, params) {
    context.commit(SET_UPDATE_LOG_ACTIVITY, params);
  }
};

const mutations = {
  [SET_LOG_ACTIVITY](state, data) {
    state.logsActivity = data;
  },
  [SET_LAST_LOG_SEEN_ACTIVITY](state, data) {
    state.lastLogseen = data;
  },
  [SET_UPDATE_LOG_ACTIVITY](state, data) {
    var findIndex = state.logsActivity[data.logName].findIndex(
      x => x.id == data.id
    );
    state.logsActivity[data.logName][findIndex] = data.data;
  },
  [SET_ADD_NEW_LOG_ACTIVITY](state, data) {
    if (data != null) {
      var getType = data["notif_type"];
      var dataType = "";
      switch (getType) {
        case "prj":
          dataType = "Project";
          break;
        case "dld":
          dataType = "Deliverable Design";
          break;
        case "dmp":
          dataType = "Design Management Plan";
          break;
        case "mnt":
          dataType = "Monitoring";
          break;
        case "dsp":
          dataType = "Document Site Project";
          break;
        default:
          dataType = "Lettering";
          break;
      }
      state.logsActivity.data[dataType].unshift(data);
      state.lastLogseen.data = state.lastLogseen.data + 1;
    }
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
