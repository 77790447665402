import ApiService from "../api.service";
import { showToast } from "../../helper/toast.helper";


export const GET_MAN_HOUR_DOC = "getManHourDoc";
export const GET_MAN_HOUR_DRW = "getManHourDrw";
export const GET_MAN_HOUR_FOR = "getManHourFor";
export const POST_MAN_HOUR = "postManHour";
export const CLEAN_STATE = "cleanState";
export const GET_MAN_HOUR_DOC_LOCAL = "getManHourDocLocal";

export const SET_ERROR = "setError";
export const SET_LOADING = "setLoading";
export const SET_MAN_HOUR_DOC = "setManHourDoc";
export const SET_MAN_HOUR_DRW = "setManHourDrw";
export const SET_MAN_HOUR_FOR = "setManHourFor";
export const SET_RESULT_POST = "setResultPost";
export const SET_MAN_HOUR_DOC_LOCAL = "setManHourDocLocal";

const state = {
  manhourDoc: {
    data: [],
    code: null,
    message: null
  },
  manhourDrw: {
    data: [],
    code: null,
    message: null
  },
  manhourFor: {
    data: [],
    code: null,
    message: null
  },
  loading: false,
  errors: {},
  resultPost: {
    data: [],
    code: null,
    message: null
  }
};

const getters = {
  loadingDesign(state) {
    return state.loading;
  },

  getterManHourDoc(state) {
    return state.manhourDoc?.data?.dld;
  },

  getterManHourFor(state) {
    return state.manhourFor?.data?.dld;
  },

  getterManHourDrw(state) {
    return state.manhourDrw?.data?.dld;
  },

  currentLoadingManHour(state) {
    return state.loading;
  }
};

const actions = {
  [CLEAN_STATE](context, params) {
    context.commit(SET_LOADING, true);
    if (params === "DOC") {
      context.commit(SET_MAN_HOUR_DOC, []);
    } else if (params === "DRW") {
      context.commit(SET_MAN_HOUR_DRW, []);
    } else if (params === "FOR") {
      context.commit(SET_MAN_HOUR_FOR, []);
    }

    context.commit(SET_LOADING, false);
  },
  [GET_MAN_HOUR_DOC](context, params) {
    return new Promise((resolve, reject) => {
      context.commit(SET_LOADING, true);
      ApiService.setHeader();
      ApiService.query(`project/${params.project_id}/DOC/dld`, params)
        .then(({ data }) => {
          resolve(data);
          context.commit(SET_MAN_HOUR_DOC, data);
          context.commit(SET_LOADING, false);
        })
        .catch(({ response }) => {
          reject(response);
          context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_MAN_HOUR_DRW](context, params) {
    return new Promise((resolve, reject) => {
      context.commit(SET_LOADING, true);
      ApiService.setHeader();
      ApiService.query(`project/${params.project_id}/DRW/dld`, params)
        .then(({ data }) => {
          context.commit(SET_MAN_HOUR_DRW, data);
          resolve(data);
          context.commit(SET_LOADING, false);
        })
        .catch(({ response }) => {
          context.commit(SET_LOADING, false);
          reject(response);
          context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_MAN_HOUR_FOR](context, params) {
    return new Promise((resolve, reject) => {
      context.commit(SET_LOADING, true);
      ApiService.setHeader();
      ApiService.query(`project/${params.project_id}/FOR/dld`, params)
        .then(({ data }) => {
          context.commit(SET_LOADING, false);
          context.commit(SET_MAN_HOUR_FOR, data);
          resolve(data);
        })
        .catch((response ) => {
          context.commit(SET_LOADING, false);
          reject(response);
        });
    });
  },

  [POST_MAN_HOUR](context, param) {
    return new Promise(resolve => {
      ApiService.setHeader();
      ApiService.post("project/dld/man/hour", param)
        .then(({ data }) => {
          context.commit(SET_RESULT_POST, data);
          resolve(data);
          showToast("Success", "Data Uploaded Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          
          showToast("Failed", "Failed Upload Data", "danger");
        });
    });
  },

  [GET_MAN_HOUR_DOC_LOCAL](context, params) {
    context.commit(SET_MAN_HOUR_DOC_LOCAL, params);
  }
};

const mutations = {
  [SET_RESULT_POST](state, manhour) {
    state.resultPost = manhour;
  },

  [SET_ERROR](state, error) {
    state.errors = error;
  },

  [SET_LOADING](state, loading) {
    state.loading = loading;
  },

  [SET_MAN_HOUR_DRW](state, manhourDrw) {
    state.manhourDrw = manhourDrw;
  },

  [SET_MAN_HOUR_FOR](state, manhourFor) {
    state.manhourFor = manhourFor;
  },

  [SET_MAN_HOUR_DOC](state, manhourDoc) {
    state.manhourDoc = manhourDoc;
  },

  [SET_MAN_HOUR_DOC_LOCAL](state) {
    let filter = state.manHourDoc?.data?.dld?.map(item => {
      return item;
    });

    state.manhourDoc.data = filter;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
