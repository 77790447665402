import ApiService from "../api.service";

export const GET_DASHBOARD_DCP = "getDashboardDCP";
export const GET_DASHBOARD_GENERAL_INFO = "getDashboardGeneralInfo";
export const GET_DASHBOARD_DETAIL_INFO = "getDashboardDetailInfo";
export const GET_DASHBOARD_SUBMISSION_ALL = "getDashboardSubmissionAll";
export const GET_DASHBOARD_DOCUMENT_PROGRESS = "getDashboardDocumentProgress";

export const SET_ERROR = "setError";
export const SET_DASHBOARD_DCP = "setDashboardDCP";
export const SET_DASHBOARD_GENERAL_INFO = "setDashboardGeneralInfo";
export const SET_DASHBOARD_DETAIL_INFO = "setDashboardDetailInfo";
export const SET_DASHBOARD_SUBMISSION_ALL = "setDashboardSubmissionAll";
export const SET_DASHBOARD_DOCUMENT_PROGRESS = "setDashboardDocumentProgress";
export const SET_LOADING = "setLoading";

const state = {
  dashboardDcp: {
    data: [],
    code: null,
    message: null
  },
  dashboardGeneralInfo: {
    data: [],
    code: null,
    message: null
  },
  dashboardDetailInfo: {
    data: [],
    code: null,
    message: null
  },
  dashboardSubmissionAll: {
    data: [],
    code: null,
    message: null
  },
  dashboardDocumentProgress: {
    data: [],
    code: null,
    message: null
  },
  errors: {},
  loading: false
};

const getters = {
  currentDashboardDcp(state) {
    return state.dashboardDcp?.data;
  },
  currentDashboardGeneralInfo(state) {
    return state.dashboardGeneralInfo?.data;
  },
  currentDashboardDetailInfo(state) {
    return state.dashboardDetailInfo?.data;
  },
  currentDashboardSubmissionAll(state) {
    return state.dashboardSubmissionAll?.data;
  },
  currentDashboardDocumentProgress(state) {
    return state.dashboardDocumentProgress?.data;
  },
  currentLoadingDashboardProject(state) {
    return state.loading;
  }
};

const actions = {
  [GET_DASHBOARD_DCP](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING, true);
      ApiService.get("dashboard", `${params}/cdp`)
        .then(({ data }) => {
          context.commit(SET_DASHBOARD_DCP, data);
          resolve(data);
          context.commit(SET_LOADING, false);
        })
        .catch(response => {
          context.commit(SET_ERROR, response?.data?.errors);
          context.commit(SET_LOADING, false);
        });
    });
  },

  [GET_DASHBOARD_GENERAL_INFO](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING, true);
      ApiService.get("dashboard", `${params}/gen`)
        .then(({ data }) => {
          context.commit(SET_DASHBOARD_GENERAL_INFO, data);
          resolve(data);
          context.commit(SET_LOADING, false);
        })
        .catch(response => {
          context.commit(SET_ERROR, response?.data?.errors);
          context.commit(SET_LOADING, false);
        });
    });
  },

  [GET_DASHBOARD_DETAIL_INFO](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING, true);
      ApiService.get("dashboard", `${params}/info`)
        .then(({ data }) => {
          context.commit(SET_DASHBOARD_DETAIL_INFO, data);
          resolve(data);
          context.commit(SET_LOADING, false);
        })
        .catch(response => {
          context.commit(SET_ERROR, response?.data?.errors);
          context.commit(SET_LOADING, false);
        });
    });
  },

  [GET_DASHBOARD_SUBMISSION_ALL](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING, true);
      ApiService.get("dashboard", `${params}/all`)
        .then(({ data }) => {
          context.commit(SET_DASHBOARD_SUBMISSION_ALL, data);
          resolve(data);
          context.commit(SET_LOADING, false);
        })
        .catch(response => {
          context.commit(SET_ERROR, response?.data?.errors);
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_DASHBOARD_DOCUMENT_PROGRESS](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING, true);
      ApiService.get("dashboard", `${params}/document/progress`)
        .then(({ data }) => {
          context.commit(SET_DASHBOARD_DOCUMENT_PROGRESS, data);
          resolve(data);
          context.commit(SET_LOADING, false);
        })
        .catch(response => {
          context.commit(SET_ERROR, response?.data?.errors);
          context.commit(SET_LOADING, false);
        });
    });
  }
};

const mutations = {
  [SET_DASHBOARD_DCP](state, data) {
    state.dashboardDcp = data;
  },

  [SET_LOADING](state, loading) {
    state.loading = loading;
  },

  [SET_DASHBOARD_GENERAL_INFO](state, data) {
    state.dashboardGeneralInfo = data;
  },

  [SET_DASHBOARD_DETAIL_INFO](state, data) {
    state.dashboardDetailInfo = data;
  },

  [SET_DASHBOARD_SUBMISSION_ALL](state, data) {
    state.dashboardSubmissionAll = data;
  },
  [SET_DASHBOARD_DOCUMENT_PROGRESS](state, data) {
    state.dashboardDocumentProgress = data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
