import ApiService from "../api.service";
import { showToast } from "../../helper/toast.helper";

export const GET_SITE_DOCUMENT_CATEGORY = "getSiteDocumentCategory";
export const DELETE_SITE_DOCUMENT_CATEGORY = "deleteSiteDocumentCategory";
export const SAVE_SITE_DOCUMENT_CATEGORY = "saveSiteDocumentCategory";
export const UPDATE_SITE_DOCUMENT_CATEGORY = "updateSiteDocumentCategory";

export const SET_ERROR = "setError";
export const SET_SITE_DOCUMENT_CATEGORY = "setSiteDocumentCategory";
export const SET_DELETE_SITE_DOCUMENT_CATEGORY =
  "setDeleteSiteDocumentCategory";
export const SET_SAVE_SITE_DOCUMENT_CATEGORY = "setSaveSiteDocumentCategory";
export const SET_UPDATE_SITE_DOCUMENT_CATEGORY =
  "setUpdateSiteDocumentCategory";
export const SET_LOADING_SITE_DOCUMENT_CATEGORY =
  "setLoadingSiteDocumentCategory";

const state = {
  siteDocumentCategories: {},
  errors: {},
  loading: false
};

const actions = {
  [GET_SITE_DOCUMENT_CATEGORY](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_SITE_DOCUMENT_CATEGORY, true);

      ApiService.query("site/document/category", params)
        .then(({ data }) => {
          context.commit(SET_SITE_DOCUMENT_CATEGORY, data);

          resolve(data);

          context.commit(SET_LOADING_SITE_DOCUMENT_CATEGORY, false);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_SITE_DOCUMENT_CATEGORY, false);
        });
    });
  },

  [DELETE_SITE_DOCUMENT_CATEGORY](context, params) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_SITE_DOCUMENT_CATEGORY, true);

      ApiService.setHeader();

      ApiService.delete(`site/document/category/${params.code}/delete`)
        .then(({ data }) => {
          context.commit(SET_DELETE_SITE_DOCUMENT_CATEGORY, params.code);

          resolve(data);

          context.commit(SET_LOADING_SITE_DOCUMENT_CATEGORY, false);

          showToast("Success", "Data Deleted Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_SITE_DOCUMENT_CATEGORY, false);

          showToast("Failed", "Failed Delete Data", "danger");
        });
    });
  },

  [SAVE_SITE_DOCUMENT_CATEGORY](context, body) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_SITE_DOCUMENT_CATEGORY, true);

      ApiService.setHeader();

      ApiService.post("site/document/category", body)
        .then(({ data }) => {
          context.commit(SET_SAVE_SITE_DOCUMENT_CATEGORY, data?.data);

          resolve(data);

          context.commit(SET_LOADING_SITE_DOCUMENT_CATEGORY, false);

          showToast("Success", "Data Saved Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_SITE_DOCUMENT_CATEGORY, false);

          showToast("Failed", "Failed Saved Data", "danger");
        });
    });
  },

  [UPDATE_SITE_DOCUMENT_CATEGORY](context, body) {
    return new Promise(resolve => {
      context.commit(SET_LOADING_SITE_DOCUMENT_CATEGORY, true);

      ApiService.setHeader();

      ApiService.put("site/document/category", body)
        .then(({ data }) => {
          context.commit(SET_UPDATE_SITE_DOCUMENT_CATEGORY, data?.data);

          resolve(data);

          context.commit(SET_LOADING_SITE_DOCUMENT_CATEGORY, false);

          showToast("Success", "Data Updated Successfully");
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);

          context.commit(SET_LOADING_SITE_DOCUMENT_CATEGORY, false);

          showToast("Failed", "Failed Update Data", "danger");
        });
    });
  }
};

const mutations = {
  [SET_SITE_DOCUMENT_CATEGORY](state, siteDocumentCategories) {
    state.siteDocumentCategories = siteDocumentCategories;
  },

  [SET_ERROR](state, error) {
    state.errors = error;
  },

  [SET_SAVE_SITE_DOCUMENT_CATEGORY](state, siteDocumentCategory) {
    state.siteDocumentCategories.data = [
      siteDocumentCategory,
      ...state.siteDocumentCategories.data
    ];
  },

  [SET_LOADING_SITE_DOCUMENT_CATEGORY](state, loading) {
    state.loading = loading;
  },

  [SET_UPDATE_SITE_DOCUMENT_CATEGORY](state, updateStation) {
    const index = state.siteDocumentCategories?.data?.findIndex(
      siteDocumentCategory => siteDocumentCategory.code === updateStation.code
    );

    if (index >= 0) {
      Object.assign(state.siteDocumentCategories?.data[index], updateStation);
    }
  },

  [SET_DELETE_SITE_DOCUMENT_CATEGORY](state, code) {
    const index = state.siteDocumentCategories?.data?.findIndex(
      siteDocumentCategory => siteDocumentCategory.code === code
    );

    if (index >= 0) {
      state.siteDocumentCategories?.data?.splice(index, 1);
    }
  }
};

export default {
  state,
  actions,
  mutations
};
